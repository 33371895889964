import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ChakraProvider,
} from "@chakra-ui/react";
import "../../styles/Popup.css";
import { useState} from "react";
import { capitalizeFirstLetter } from "../../utils/capitalize";

const ErrorPopup = (props) => {
  const [close, setClose] = useState(props.trigger);
  const handleClose = () => {
    setClose(false);
  };

  return close ? (
    <div className="popup">
      <div className="popup-inner">
        <button
          className="close-btn"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </button>
        <div className="errorList">
          <h1 className="text-black">{props.title}</h1>
          <ChakraProvider>
<TableContainer>
<Table variant='striped'>
  <Thead>
    <Tr>
      <Th>ID</Th>
      <Th>Messages</Th>
    </Tr>
  </Thead>
  <Tbody>
  {props.ErrorMsg.map((errorItems, index) => {
            return (
 
    <Tr key={index}>
      <Td className='text-red-600'> {errorItems.id}</Td>
      <Td className='text-red-600'>{capitalizeFirstLetter(errorItems.message)}</Td>
       
    </Tr>
            );
          })}
          </Tbody>
</Table>
</TableContainer>
</ChakraProvider>      
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default ErrorPopup;
