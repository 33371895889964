import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const documentsApi = createApi({
    reducerPath: "documentsApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().clientAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (builder) => ({
        getDocuments: builder.query({
            query: () => {
                return {
                    url: `/document/all`,
                    method: "GET",
                };
            },
        }),

    }),
});

export const { useGetDocumentsQuery } = documentsApi;