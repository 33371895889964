import { createSlice } from '@reduxjs/toolkit';
import { getAuthToken, removeAuthToken } from "../../storage/localStorage";

const initialState = {
  authToken: null,
}

export const clientAuthSlice = createSlice({
  name: 'clientAuth',
  initialState,
  reducers: {
    setClientToken: (state) => {
      const token = getAuthToken() || null;
      state.authToken = token;
    },
    removeClientToken: (state) => {
      removeAuthToken();
      state.authToken = null;
      // state.user = null;
    },
  },
})

export const { setClientToken, removeClientToken } = clientAuthSlice.actions;

export default clientAuthSlice.reducer;