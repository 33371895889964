import { useState } from "react";
import { successToast, failureToast } from "../../Toast";
import { useTranslation } from "react-i18next";

function TableBodyInput({
    value,
    minStock,
    id,
    changeItemQty,
    showRemoveItemModal,
    handleItemToRemove,
}) {
    const { t } = useTranslation();
    const [prevValue, setPrevValue] = useState('');
    const preventNegativeValues = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
    return (
        <input
            type="number"
            value={value}
            onKeyDown={preventNegativeValues}
            step={minStock}
            className={`w-14 text-center ${
                value % minStock !== 0 || value === ""
                  ? "border-solid border-2 border-red-500 focus:border-solid focus:border-red-500  "
                  : ""
              } !mb-0`}
            onFocus={(e) => setPrevValue(e?.target?.value)}
            onChange={(e) => {
                if (e?.target?.value === "") {
                    changeItemQty(id, e?.target?.value);
                    setPrevValue(e?.target?.value);
                }
                else if (e?.target?.value <= 0) {
                    showRemoveItemModal();
                    handleItemToRemove(id);
                    changeItemQty(id, prevValue);
                }
                else if (e?.target?.value % minStock !== 0) {
                    failureToast(`${t("addOrdersPage.toastNotifications.failureAddingQuantity")}`);
                    changeItemQty(id, e?.target?.value);
                    setPrevValue(e?.target?.value);
                }
                else if (e?.target?.value) {
                    changeItemQty(id, e?.target?.value);
                    setPrevValue(e?.target?.value);
                }
            }}
        />
    );
}

export default TableBodyInput;