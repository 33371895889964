import { useField } from 'formik';
import PropTypes from 'prop-types';

function Select({
    name,
    fieldErrMessage = 'field-err-message',
    className = "",
    ...props
}) {
    const [field, meta] = useField(name);
    return (
        <div>
            <select
                {...field}
                {...props}
                className={className}
            />
            {meta.touched && meta.error ? (
                <div className={fieldErrMessage}>{meta.error}</div>
            ) : null}
        </div>
    );
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    fieldErrMessage: PropTypes.string,
    className: PropTypes.string,
};

export default Select;
