import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().clientAuth.authToken;
      if (token) {
        headers.set("Authorization", `Basic ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => {
        return {
          url: `/dashboard`,
          method: "GET",
        };
      },
    }),

  }),

  refetchOnMountOrArgChange: true,
});

export const { useGetDashboardQuery } = dashboardApi;