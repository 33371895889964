import * as Yup from 'yup';

export const saveContactForOrderSchema1 = Yup.object().shape({
    phoneNumber: Yup.string().required("Required"),
    date: Yup.string().required("Required"),
    time: Yup.string().required("Required"),
});

export const saveContactForOrderSchema2 = Yup.object().shape({
    description: Yup.string().required("Required"),
    preferredFeedback: Yup.string().required("Required"),
    orderId: Yup.string(),
    title: Yup.string().required("Required"),
});