export const getQueryStringForTable = (
    pageIndex,
    pageSize,
    sortBy,
    filters
) => {
    let sortString = '';
    if (sortBy.length) {
        sortString = '&sortBy=' + sortBy[0].id;
        sortString += sortBy[0].desc ? ':desc&' : ':asc&';
    } else {
        sortString = '&sortBy=createdAt:desc&';
    }

    let searchString = '';
    if (filters && filters.length) {
        searchString +=
            filters
                .map((v) => {
                    return `${v.id}=${v.value}`;
                })
                .join('&');
    }

    return `limit=${pageSize}&page=${pageIndex + 1}${sortString}${searchString}&`;
};

export const getOrdersQueryForTable = (
    filters,
    sortBy,
    pageIndex
) => {
    let searchString = '';
    if (filters && filters.length) {
        searchString += filters.map((item) => {
            return item?.id === 'search' ? `q=${item?.value}` : `${item?.id}=${item?.value}`;
        })?.join("&");
    } else {
        searchString = '';
    }

    let sortString = '';
    let descString = '';
    if (sortBy.length) {
        sortString = 'sortBy=' + sortBy[0].id;
        descString = sortBy[0].desc ? 'true' : 'false';
    } else {
        sortString = '';
        // By default we will sort in descending.
        descString = 'true';
    }

    return `?desc=${descString}&n=15&p=${pageIndex +1}${searchString.length ? "&" + searchString : ""}${sortString.length ? "&" + sortString : ""}`;
};

export const getPriceQueryForTable = (
    filters
) => {
    if (filters && filters.length) {
        return filters[0]?.value;
    }

    return "";
};
