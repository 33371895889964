import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  changeQtyOrderCartItems,
  removeSelectedCartItem,
} from "../../../../../features/orderCart/orderCartSlice";
import Modal from "../../../../../components/Modal";
import { modalTypes } from "../../../../../components/Modal/ModalTypes";
import Table from "../../../../../components/Table";
import TableBodyInput from "../../../../../components/Input/TableBodyInput";

function Step3({ payload, changeStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.orderCart.cartItems);
  const [tableData, setTableData] = useState([]);
  const [totalStatement, setTotalStatement] = useState({
    ean: null,
    quantity: {
      className: "font-bold pt-4",
      value: `${t("addOrdersPage.step3.total")}`,
    },
    price: {
      className: "pt-4",
      value: "13.385,00 € (netto)",
    },
  });

  const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState("");
  const [yesToRemove, setYesToRemove] = useState(false);

  /* For Managing Cart State */
  const handleQty = (id, value) => {
    dispatch(changeQtyOrderCartItems({ id, value }));
  };

  const showRemoveItemModal = () => setOpenRemoveItemModal(true);

  useEffect(() => {
    if (itemToRemove && yesToRemove) {
      dispatch(removeSelectedCartItem(itemToRemove));
    }
  }, [itemToRemove, yesToRemove]);

  const handleItemToRemove = (id) => {
    setItemToRemove(id);
  };

  const handleYesToRemove = (isYes) => {
    setYesToRemove(isYes);
  };
  /* For Managing Cart State */

  const columns = useMemo(
    () => [
      {
        id: "ean",
        Header: `${t("addOrdersPage.step3.ean")}`,
        accessor: "ean",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "minStock",
        Header: `${t("addOrdersPage.step3.minStock")}`,
        accessor: "minStock",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "quantity",
        Header: `${t("addOrdersPage.step3.quantity")}`,
        accessor: "quantity",
        Cell: ({ cell: { value }, row }) => (
          <TableBodyInput
            value={value}
            minStock={row?.original?.minStock}
            id={row?.original?.ean}
            changeItemQty={handleQty}
            showRemoveItemModal={showRemoveItemModal}
            handleItemToRemove={handleItemToRemove}
          />
        ),
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "price",
        Header: `${t("addOrdersPage.step1.price")}`,
        accessor: "price",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
    ],
    [cartItems?.length, t]
  );

  useEffect(() => {
    setTableData(
      cartItems?.map((item, index) => {
        return {
          id: index,
          ean: item?.ean,
          quantity: item?.quantity,
          minStock: item?.minStock,
          price: `${item?.price} (${t("addOrdersPage.step3.net")})`,
        };
      })
    );
  }, [cartItems, t]);

  useEffect(() => {
    let total = 0;
    cartItems?.forEach((item) => {
      // total += parseInt(item?.quantity) * parseInt(item?.price?.split(" ")[0]);
      total +=
        parseInt(item?.quantity) * parseFloat(item?.price?.split(" ")[0]);
    });

    if(Number.isNaN(total)){
      total = 0
    }

    setTotalStatement({
      ...totalStatement,
      quantity: {
        className: "font-bold pt-4",
        value: `${t("addOrdersPage.step3.total")}`,
      },
      price: {
        className: "pt-4",
        value: `${total.toFixed(2)} € (${t("addOrdersPage.step3.net")})`,
      },
    });
  }, [cartItems, t]);

  useEffect(() => {
    if (!cartItems?.length) {
      changeStep(1);
    }
  }, [cartItems]);

  return (
    <div className="px-8 py-4 rest-of-six-col-xs">
      {openRemoveItemModal ? (
        <Modal
          modalTitle={`${t("modal.removePrompt")}`}
          onCancelClick={() => {
            setOpenRemoveItemModal(false);
          }}
          modalWidth="w-[40%]"
          modalHeight="h-[25%]"
          modalType={modalTypes?.type5?.name}
          handleItemToRemove={handleItemToRemove}
          handleYesToRemove={handleYesToRemove}
          hasMoreBtn={false}
          noOutsideClickClose={true}
        />
      ) : null}
      <>
        <div className="pb-5">
          <Table
            columns={columns}
            data={tableData}
            defaultPageSize={10}
            hasPagination={false}
            hasSorting={false}
            isBorderlessTable={true}
            noTableData={true}
            sumTotal={totalStatement}
            tableName="Order Item/s"
          />
        </div>
        <hr />
        <div className="pt-5">
          <div className="row-xs">
            <div className="three-col-xs">
              <p className="font-bold text-center">
                {t("addOrdersPage.step3.deliveryAddress")}
              </p>
            </div>
            <div className="rest-of-three-col-xs pl-8">
              <p className="mb-0">
                {payload?.deliveryAddress?.companyName || "-"}
              </p>
              <p className="mb-0">
                {payload?.deliveryAddress?.addressSupplement || "-"}
              </p>
              {/* <p className="mb-0">{`${payload?.deliveryAddress?.salutation || "-"} ${payload?.deliveryAddress?.firstName || "-"} ${payload?.deliveryAddress?.name || "-"}`}</p> */}
              <p className="mb-0">{`${
                payload?.deliveryAddress?.firstName || "-"
              } ${payload?.deliveryAddress?.lastName || "-"}`}</p>
              <p className="mb-0">{`${
                payload?.deliveryAddress?.streetName || "-"
              } ${payload?.deliveryAddress?.houseNumber || "-"}`}</p>
              <p className="mb-0">{`${
                payload?.deliveryAddress?.countryCode || "-"
              } ${payload?.deliveryAddress?.zipCode || "-"} ${
                payload?.deliveryAddress?.city || "-"
              }`}</p>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Step3;
