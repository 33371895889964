import * as yup from 'yup';

export const numberValidation = () => {
    return yup
        .string()
        .matches(/^[0-9]+$/, 'Only numbers are allowed for this field.')
        .required(`Required.`);
    // .nullable();
};

export const numberValidationNotReq = () => {
    return yup
        .string()
        .matches(/^[0-9]+$/, 'Only numbers are allowed for this field.');
};

// Matches your + at the start, then any digit, dash, space, dot, or brackets.
export const phoneNumberValidation = () => {
    return yup
        .string()
        .matches(/^[\+\d]?(?:[\d-.\s()]*)$/, 'Only numbers are allowed for this field.')
        .required(`Required.`);
};

// Matches your + at the start, then any digit, dash, space, dot, or brackets.
export const phoneNumberValidationNotReq = () => {
    return yup
        .string()
        .matches(/^[\+\d]?(?:[\d-.\s()]*)$/, 'Only numbers are allowed for this field.');
};