import * as Yup from 'yup';

export const adminAddUserSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    name: Yup.string().required("Required"),
    password: Yup
        .string("Password is required.")
        // .required("Password is required.")
        .matches(
            /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!@$#%^&*]).*$/,
            "Password must be minimum of eight characters, with at least one uppercase letter, one lowercase letter, one number and one special character."
        ),
    priceListGroup: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    stockName: Yup.string().required("Required"),
});