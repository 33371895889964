export const dashboardPageEn = {
    orders: "Orders (total)",
    priceList: "Price List",
    currentInventory: "Current Inventory",
    currentPriceList: "Current Price List",
    priceSearch: "Start the next inventory/price query right here",
    currentSpecialOrders: "Current special offers",
    toastNotifications: {
        failure: "Failed loading dashboard.",
    },
};

export const dashboardPageDe = {
    orders: "Bestellungen (gesamt)",
    priceList: "Preisliste",
    currentInventory: "Aktuelle Bestandsliste",
    currentPriceList: "Aktuelle Preisliste",
    priceSearch: "Starten Sie die nächste Bestands-/Preisabfrage direkt hier",
    currentSpecialOrders: "Aktuelle Sonderangebote",
    toastNotifications: {
        failure: "Fehler beim Laden des Dashboards.",
    },
};

