import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./styles/globals.css";
import { store } from "./store";
import './lang/i18n';
import App from "./App";
import Toast from "./components/Toast";

// This is the new way according to React 18.
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
        <Toast />
      </BrowserRouter>
    </Provider>
  </StrictMode>
);