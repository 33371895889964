import { configureStore } from "@reduxjs/toolkit";
/** Global State or Features **/
import clientAuthReducer from "../features/auth/clientAuthSlice";
import clientUserReducer from "../features/client/clientUserSlice";
import adminAuthReducer from "../features/auth/adminAuthSlice";
import adminUserReducer from "../features/admin/adminUserSlice";
import orderCartReducer from "../features/orderCart/orderCartSlice";
import orderEntryReducer from "../features/orderEntry/orderEntrySlice";
/** Services **/
import { authApi } from "../services/auth";
import { dashboardApi } from "../services/dashboard";
import { accountDetailsApi } from "../services/accountDetails";
import { userDetailsApi } from "../services/user";
import { ordersApi } from "../services/orders";
import { articlesApi } from "../services/articles";
import { documentsApi } from "../services/documents";
import { contactApi } from "../services/contact";
// Admin Services
import { usersByAdminApi } from "../services/admin/users";
import { newsfeedByAdminApi } from "../services/admin/newsfeed";
import { documentByAdminApi } from "../services/admin/document";
import { userAccountDetailsByAdminApi } from "../services/admin/account";
import { departmentByAdminApi } from "../services/admin/department";
// import { documentByClientApi } from "../services/uploadcsv";

export const store = configureStore({
  reducer: {
    /** Global State **/
    clientAuth: clientAuthReducer,
    clientUser: clientUserReducer,
    adminAuth: adminAuthReducer,
    adminUser: adminUserReducer,
    orderCart: orderCartReducer,
    orderEntry: orderEntryReducer,
    /** Services **/
    [authApi.reducerPath]: authApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [accountDetailsApi.reducerPath]: accountDetailsApi.reducer,
    [userDetailsApi.reducerPath]: userDetailsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    // Admin Services
    [usersByAdminApi.reducerPath]: usersByAdminApi.reducer,
    [newsfeedByAdminApi.reducerPath]: newsfeedByAdminApi.reducer,
    [documentByAdminApi.reducerPath]: documentByAdminApi.reducer,
    // [documentByClientApi.reducerPath]: documentByClientApi.reducer,
    [userAccountDetailsByAdminApi.reducerPath]: userAccountDetailsByAdminApi.reducer,
    [departmentByAdminApi.reducerPath]: departmentByAdminApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      dashboardApi.middleware,
      accountDetailsApi.middleware,
      userDetailsApi.middleware,
      ordersApi.middleware,
      articlesApi.middleware,
      documentsApi.middleware,
      contactApi.middleware,
      // Admin Services
      usersByAdminApi.middleware,
      newsfeedByAdminApi.middleware,
      documentByAdminApi.middleware,
      // documentByClientApi.middleware,
      userAccountDetailsByAdminApi.middleware,
      departmentByAdminApi.middleware,
    ]),
});