import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const contactApi = createApi({
    reducerPath: "contactApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().clientAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (builder) => ({
        saveContactForOrder: builder.mutation({
            query: (data) => {
                return {
                    url: `/contactus/`,
                    method: "POST",
                    body: data,
                };
            },
        }),

    }),
});

export const { useSaveContactForOrderMutation } = contactApi;