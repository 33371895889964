import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { baseUrl } from "../../config";
import {
  useAddOrderMutation,
  useUploadCSVMutation,
} from "../../services/orders";
import { capitalizeFirstLetter } from "../../utils/capitalize";
import { navToPrices, navToOrderAdd } from "../../navigations";
import { useSaveContactForOrderMutation } from "../../services/contact";
import {
  saveContactForOrderSchema1,
  saveContactForOrderSchema2,
} from "../../validations/contact";
import LoadingOverlay from "../LoadingOverlay";
import { successToast, failureToast } from "../Toast";
import Table from "../Table";
import Button from "../Button";
import Input from "../Input";
import InputLabel from "../Input/Label";
import TableBodyInput from "../Input/TableBodyInput";
import DropdownButton from "../DropdownButton";
import DragAndDrop from "../DragAndDrop";
import ErrorPopup from "./ErrorPopup";
import DatePickerInput from "../DatePickerInput";
import TimePickerInput from "../TimePickerInput";
import { modalTypes } from "./ModalTypes";
import { addZeroInSingleDigit } from "../../utils/addZeroInSingleDigit";
import DisableButton from "./button";
import DownloadButtonPopUpModal from "../../components/DownloadButtonPopUpModal";

const Modal = ({
  modalTitle = "",
  // onOkClick,
  onCancelClick,
  hasMoreBtn = true,
  headingCss = "",
  centeredHeading = false,
  modalWidth = "w-[75%]",
  modalHeight = "h-[70%]",
  modalType = "",
  noBgOverlay = false,
  noOutsideClickClose = false,
  // For Cart Modal
  cartData = [],
  changeItemQty = null,
  // removeSelectedItem = null,
  showRemoveItemModal = null,
  handleItemToRemove = null,
  handleYesToRemove = null,
  // For Order Detail Modal
  orderDetails = null,
  isOrderDetailError = false,
  isFetchingOrderDetail = false,
  selectedOrderNum = null,
  // For Contact Modal
  contactForm2Values = null,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.orderCart.cartItems);
  const authToken = useSelector((state) => state.clientAuth.authToken);

  const [orderDetailTable1, setOrderDetailTable1] = useState([]);
  const [orderDetailTable2, setOrderDetailTable2] = useState([]);
  const [file, setFile] = useState();

  const [showModalCSVTemplate, setShowModalCSVTemplate] = useState(false);
  const handleDownloadCSVTemplate = () => {
    setShowModalCSVTemplate(true);
  };
  const handleCancelCSVTemplate = () => {
    setShowModalCSVTemplate(false);
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  function handleSubmitUpload(event) {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    uploadFile({ files: formData });
  }

  const [
    uploadFile,
    {
      data: fileData,
      isSuccess: isFileSuccess,
      isError: isFileError,
      error: fileErrorMessage,
    },
  ] = useUploadCSVMutation();

  const [
    addOrder,
    {
      error: orderErrorMessage,
      isError: orderAddError,
      isSuccess: orderAddSuccess,
    },
  ] = useAddOrderMutation();

  useEffect(() => {
    if (isFileError) {
      failureToast(
        `${t("addOrdersPage.toastNotifications.failureAddingOrderFromCSV")}`
      );
    }

    if (isFileSuccess) {
      if (fileData?.payload) {
        fileData?.payload?.forEach((order) => {
          addOrder(order);
        });
      }
    }
  }, [isFileError, isFileSuccess, fileErrorMessage]);

  useEffect(() => {
    if (orderAddError) {
      if (orderErrorMessage?.data?.payload?.errors[0]?.message) {
        failureToast(
          capitalizeFirstLetter(
            orderErrorMessage?.data?.payload?.errors[0]?.message
          )
        );
      } else {
        failureToast(
          `${t("addOrdersPage.toastNotifications.failureAddingOrder")}`
        );
      }
    }
    if (orderAddSuccess) {
      successToast(
        `${t("addOrdersPage.toastNotifications.successAddingOrder")}`
      );
      navigate("/seller-platform/orders");
    }
  }, [orderAddError, orderAddSuccess]);

  const columns1A = useMemo(
    () => [
      {
        id: "ean",
        Header: `${t("viewOrderDetailPage.orderOverview.ean")}`,
        accessor: "ean",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "quantity",
        Header: `${t("viewOrderDetailPage.orderOverview.quantity")}`,
        accessor: "quantity",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "price",
        Header: `${t("viewOrderDetailPage.orderOverview.price")}`,
        accessor: "price",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
    ],
    []
  );

  const columns1B = useMemo(
    () => [
      {
        id: "trackingNumber",
        Header: `${t("viewOrderDetailPage.tracking.trackingNumber")}`,
        accessor: "trackingId",
        Cell: ({ cell: { value, row } }) => (
          <a
            href={row?.original?.trackingLink}
            className={`underline cursor-pointer`}
            target="_blank"
            rel="noreferrer"
          >
            {value || "-"}
          </a>
        ),
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "serviceProvider",
        Header: `${t("viewOrderDetailPage.tracking.serviceProvider")}`,
        accessor: "serviceProvider",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "trackingStatus",
        Header: `${t("viewOrderDetailPage.tracking.status")}`,
        accessor: "status",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
    ],
    []
  );

  const columns4 = useMemo(
    () => [
      {
        id: "ean",
        Header: `${t("viewPricesPage.basket.ean")}`,
        accessor: "ean",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "minStock",
        Header: `${t("viewPricesPage.basket.minStock")}`,
        accessor: "minStock",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "quantity",
        Header: `${t("viewPricesPage.basket.quantity")}`,
        accessor: "quantity",
        Cell: ({ cell: { value }, row }) => (
          <TableBodyInput
            value={value}
            minStock={row?.original?.minStock}
            id={row?.original?.ean}
            changeItemQty={changeItemQty}
            showRemoveItemModal={showRemoveItemModal}
            handleItemToRemove={handleItemToRemove}
          />
        ),
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "price",
        Header: `${t("viewPricesPage.basket.price")}`,
        accessor: "price",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
    ],
    [cartItems?.length]
  );

  useEffect(() => {
    if (orderDetails) {
      if (orderDetails?.fulfillments?.length <= 1) {
        setOrderDetailTable1(
          orderDetails?.items?.map((data) => {
            return {
              ean: data?.articleId,
              quantity: data?.requested,
              price: `${data?.price?.value} € (${t(
                "viewOrderDetailPage.orderOverview.net"
              )})`,
            };
          })
        );
      } else {
        const overviewTable = orderDetails?.fulfillments?.map((data) => {
          const getItem = data?.items.map((itemData) => {
            return {
              ean: itemData?.articleId,
              quantity: itemData?.qty,
            };
          });

          const price = orderDetails?.items?.map((priceData) => {
            return {
              ean: priceData?.articleId,
              price: (priceData?.price?.value / priceData?.requested).toFixed(
                2
              ),
            };
          });

          if (data?.id === selectedOrderNum) {
            const getPriceForEan = price.filter((element) => {
              return element.ean === getItem[0]?.ean;
            });
            return {
              ean: getItem[0]?.ean,
              quantity: getItem[0]?.quantity,
              price: `${(
                getPriceForEan[0]?.price * getItem[0]?.quantity
              ).toFixed(2)} € (${t("viewOrderDetailPage.orderOverview.net")})`,
            };
          } else {
            return {
              ean: null,
              quantity: null,
              price: null,
            };
          }
        });

        const getoverviewTable = overviewTable.filter((element) => {
          return element.ean !== null;
        });

        setOrderDetailTable1(getoverviewTable);
      }

      if (orderDetails?.parcels?.length) {
        // Filter parcels acc to fulfillmentId only if it can be split i.e. fulfillments?.length > 1
        if (orderDetails?.fulfillments?.length > 1) {
          const trackingTable = orderDetails?.parcels?.filter((data) => {
            if (data?.fulfillmentId === selectedOrderNum) {
              return {
                trackingNumber: data?.trackingId,
                trackingLink: data?.trackingLink,
                serviceProvider: data?.serviceProvider,
                trackingStatus: data?.status,
              };
            } else {
              return null;
            }
          });
          setOrderDetailTable2(trackingTable);
        } else {
          setOrderDetailTable2(
            orderDetails?.parcels?.filter((data) => {
              return {
                trackingNumber: data?.trackingId,
                trackingLink: data?.trackingLink,
                serviceProvider: data?.serviceProvider,
                trackingStatus: data?.status,
              };
            })
          );
        }
      } else {
        setOrderDetailTable2([]);
      }
    }
  }, [orderDetails, t]);

  const onOutsideClick = (e) => {
    if (e?.target?.className === "modalBackground") {
      onCancelClick();
    }
  };

  const jsxForType1 = () => {
    return (
      <>
        <div className="row-xs">
          {isFetchingOrderDetail ? (
            <div className="one-col-xs h-inherit">
              <LoadingOverlay heightClass="h-[300px]" />
            </div>
          ) : (
            <>
              <div className="two-col-xs border-r border-gray-300 pr-12">
                <div className="mb-20">
                  <h4 className="font-semibold pb-2">
                    {t("viewOrderDetailPage.orderOverview.orderOverview")}
                  </h4>
                  <Table
                    columns={columns1A}
                    data={orderDetailTable1}
                    isFetchError={isOrderDetailError}
                    hasPagination={false}
                    isBorderlessTable={true}
                    tableName="Item/s"
                  />
                </div>
                <div className="mb-20">
                  <h4 className="font-semibold pb-2">
                    {t("viewOrderDetailPage.tracking.tracking")}
                  </h4>
                  <Table
                    columns={columns1B}
                    data={orderDetailTable2}
                    isFetchError={isOrderDetailError}
                    hasPagination={false}
                    isBorderlessTable={true}
                    hasEllipis={true}
                    tableName="Item/s"
                  />
                </div>
              </div>
              <div className="two-col-xs pl-12">
                <div className="pb-8">
                  <h4 className="font-semibold pb-2">
                    {t("viewOrderDetailPage.shippingAddress.shippingAddress")}
                  </h4>
                  <div className="">
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t("viewOrderDetailPage.shippingAddress.company")}
                      </h5>
                      <p className="w-2/3 mb-1">
                        {orderDetails?.deliveryAddress?.companyName || "-"}
                      </p>
                    </div>
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t("viewOrderDetailPage.shippingAddress.additive")}
                      </h5>
                      <p className="w-2/3 mb-1">
                        {orderDetails?.deliveryAddress?.addressSupplement ||
                          "-"}
                      </p>
                    </div>
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t("viewOrderDetailPage.shippingAddress.firstName")}
                      </h5>
                      <p className="w-2/3 mb-1">
                        {orderDetails?.deliveryAddress?.firstName || "-"}
                      </p>
                    </div>
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t(
                          "viewOrderDetailPage.shippingAddress.streetHouseNum"
                        )}
                      </h5>
                      <p className="w-2/3 mb-1">{`${
                        orderDetails?.deliveryAddress?.streetName || "-"
                      } ${
                        orderDetails?.deliveryAddress?.houseNumber || "-"
                      }`}</p>
                    </div>
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t(
                          "viewOrderDetailPage.shippingAddress.countryZipLocation"
                        )}
                      </h5>
                      <p className="w-2/3 mb-1">{`${
                        orderDetails?.deliveryAddress?.countryCode || "-"
                      } ${orderDetails?.deliveryAddress?.zipCode || "-"}`}</p>
                    </div>
                  </div>
                </div>
                <div className="pb-8">
                  <h4 className="font-semibold pb-2">
                    {t("viewOrderDetailPage.assignment.assignment")}
                  </h4>
                  <div className="">
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t(
                          "viewOrderDetailPage.assignment.externalOrderNumber"
                        )}
                      </h5>
                      <p className="w-2/3 mb-1">
                        {orderDetails?.customerOrderReference || "-"}
                      </p>
                    </div>
                    <div className="flex">
                      <h5 className="w-1/3">
                        {t("viewOrderDetailPage.assignment.billNumber")}
                      </h5>
                      <p className="w-2/3 mb-1">
                        {orderDetails?.invoiceNumber || "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="text-center pt-3">
          <a href="#" onClick={onCancelClick}>
            {t("modal.conclude")}
          </a>
        </div>
      </>
    );
  };

  const jsxForType2 = () => {
    const contactFormEl1 = useRef(null);
    const contactFormEl2 = useRef(null);

    const [
      saveContactForOrder,
      {
        isLoading: savingContact,
        error: saveContactError,
        isSuccess: saveContactSuccess,
      },
    ] = useSaveContactForOrderMutation();

    // In API payload, orderId is optional.
    // const contactInitialValues1 = {
    //   description: "",
    //   id: 0,
    //   title: "Request for contact.",
    //   email: true,
    //   telephoneNo: true,
    // };
    const contactInitialValues1 = {
      phoneNumber: "",
      date: "",
      time: "",
      title: "Request for contact.",
      email: true,
      telephoneNo: true,
    };

    const contactInitialValues2 = {
      description: "",
      preferredFeedback: "",
      orderId: "",
      title: "",
    };

    useEffect(() => {
      if (saveContactError) {
        failureToast(`${t("viewContactPage.toastNotifications.failure")}`);
        contactFormEl1?.current?.setSubmitting(false);
        contactFormEl2?.current?.setSubmitting(false);
      }
      if (saveContactSuccess) {
        successToast(`${t("viewContactPage.toastNotifications.success")}`);
        contactFormEl1?.current?.resetForm();
        // Set a new initial state, not the initial state from contactForm2Values
        if (contactForm2Values) {
          contactFormEl2?.current?.resetForm({
            values: {
              description: "",
              preferredFeedback: "",
              orderId: "",
              title: "",
            },
          });
        } else {
          contactFormEl2?.current?.resetForm();
        }
      }
    }, [saveContactError, saveContactSuccess]);

    const handleFormSubmit1 = (values) => {
      const { phoneNumber, date, time, ...rest } = values;
      const payloadTime = time.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      const payloadDate = `${addZeroInSingleDigit(
        JSON.stringify(date.getDate())
      )}.${addZeroInSingleDigit(
        JSON.stringify(date.getMonth() + 1)
      )}.${date.getFullYear()}`;
      const contactPayload = {
        ...rest,
        description:
          "Phone - " +
          phoneNumber +
          "\n" +
          "Date (DD.MM.YYYY) - " +
          payloadDate +
          "\n" +
          "Time - " +
          payloadTime,
      };
      saveContactForOrder(contactPayload);
    };

    const handleFormSubmit2 = (values) => {
      const { preferredFeedback, ...rest } = values;
      let contactPayload;
      if (preferredFeedback === "email") {
        contactPayload = { ...rest, email: true, telephoneNo: false };
      } else if (preferredFeedback === "telephoneNo") {
        contactPayload = { ...rest, email: false, telephoneNo: true };
      }
      saveContactForOrder(contactPayload);
    };

    return (
      <>
        <div className="row-xs">
          <div className="two-col-xs border-r border-gray-300 pr-12">
            <div className="pb-8">
              <div className="">
                <div className="flex pb-6">
                  <h5 className="w-1/3">{t("viewContactPage.phone")}</h5>
                  <p className="w-2/3">+49 (0) 711 99585300</p>
                </div>
                <div className="flex pb-6">
                  <h5 className="w-1/3">{t("viewContactPage.email")}</h5>
                  <p className="w-2/3">service@canis-lupus.digital</p>
                </div>
                <div className="flex pb-6">
                  <h5 className="w-1/3">
                    {t("viewContactPage.requestRecall")}
                  </h5>
                  <Formik
                    initialValues={contactInitialValues1}
                    validationSchema={saveContactForOrderSchema1}
                    onSubmit={handleFormSubmit1}
                    innerRef={contactFormEl1}
                    enableReinitialize
                  >
                    {({ dirty, isSubmitting: isSubmitting1 }) => (
                      <Form className="w-2/3">
                        <Input
                          name="phoneNumber"
                          type="text"
                          placeholder={`${t("viewContactPage.phoneNumber")} *`}
                          className="text-center mb-3"
                        />
                        {/* <Input
                          name="date"
                          type="text"
                          placeholder={`${t('viewContactPage.date')} *`}
                          className="text-center mb-3"
                        /> */}
                        <DatePickerInput
                          name="date"
                          type="text"
                          placeholder={`${t("viewContactPage.date")} *`}
                          className="text-center mb-3"
                        />
                        {/* <Input
                          name="time"
                          type="text"
                          placeholder={`${t('viewContactPage.time')} *`}
                          className="text-center mb-3"
                        /> */}
                        <TimePickerInput
                          name="time"
                          type="text"
                          placeholder={`${t("viewContactPage.time")} *`}
                          className="text-center mb-3"
                        />
                        <div className="btn-container text-center">
                          <Button
                            disabled={!dirty}
                            loading={isSubmitting1 && savingContact}
                            type="submit"
                            className="!m-0"
                          >
                            {t("viewContactPage.submitRequest")}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="two-col-xs pl-12 pr-4">
            <div className="pb-8">
              <h5 className="pb-2 normal-case">
                {t("viewContactPage.contactForAnOrder")}
              </h5>
              <div className="">
                <Formik
                  initialValues={contactForm2Values || contactInitialValues2}
                  validationSchema={saveContactForOrderSchema2}
                  onSubmit={handleFormSubmit2}
                  innerRef={contactFormEl2}
                  enableReinitialize
                >
                  {({
                    dirty,
                    isSubmitting: isSubmitting2,
                    setFieldValue,
                    errors,
                  }) => (
                    <Form>
                      <Input
                        name="orderId"
                        type="text"
                        placeholder="AU2012220"
                        className="text-center"
                      />
                      <Input
                        name="title"
                        type="text"
                        placeholder={`${t("viewContactPage.requestTitle")} *`}
                        className="text-center"
                      />
                      <Input
                        name="description"
                        isTextArea={true}
                        rows="10"
                        placeholder="Text"
                      />
                      <div className="flex">
                        <div className="w-1/3 pr-3">
                          <InputLabel
                            label={t("viewContactPage.preferredFeedback")}
                            required={false}
                            className="pl-0 font-medium"
                            noWrap={false}
                          />
                          {errors?.preferredFeedback ? (
                            <div className="field-err-message">
                              {errors?.preferredFeedback}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="w-2/3 flex"
                          onChange={(e) =>
                            setFieldValue("preferredFeedback", e?.target?.value)
                          }
                        >
                          <span className="checkbox-container">
                            <input
                              type="radio"
                              id="Telephone Num"
                              name="preferredFeedback"
                              value="telephoneNo"
                            />
                            <InputLabel
                              htmlFor="Telephone Num"
                              label={t("viewContactPage.phone")}
                              required={false}
                              className="font-medium"
                            />
                          </span>
                          <span className="checkbox-container">
                            <input
                              type="radio"
                              id="Email"
                              name="preferredFeedback"
                              value="email"
                            />
                            <InputLabel
                              htmlFor="Email"
                              label={t("viewContactPage.email")}
                              required={false}
                              className="font-medium"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="btn-container text-center">
                        <Button
                          disabled={!dirty}
                          loading={isSubmitting2 && savingContact}
                          type="submit"
                          className="!m-0"
                        >
                          {t("viewContactPage.submitRequest")}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-3">
          <a href="#" onClick={onCancelClick}>
            {t("viewContactPage.conclude")}
          </a>
        </div>
      </>
    );
  };

  const jsxForType3 = () => {
    const fileUploadInputRef = useRef(null);

    const handleUploadOpen = () => {
      fileUploadInputRef?.current.click();
    };
    return (
      <>
        <div className="flex flex-col items-center justify-center">
          <DragAndDrop
            text={t("modal.csvModal.dropFile")}
            onClick={handleUploadOpen}
            file={file}
          />

          {fileErrorMessage && (
            <ErrorPopup
              ErrorMsg={fileErrorMessage?.data?.payload.errors}
              trigger={true}
              setTrigger={true}
              title="Errors"
            />
          )}

          <div className="pt-1 pb-6">
            <span className="text-[12px]">
              {t("modal.csvModal.downloadCsv")}
              <Button
                variant="link"
                className="text-secondary-dark inline underline"
                onClick={handleDownloadCSVTemplate}
              >
                <DownloadButtonPopUpModal
                  showModal={showModalCSVTemplate}
                  setShowModal={setShowModalCSVTemplate}
                  handleDownload={handleDownloadCSVTemplate}
                  handleCancel={handleCancelCSVTemplate}
                  downloadLink={`${baseUrl}/orders/template/download`}
                  authToken={authToken}
                  successMessage={t(
                    "viewDocumentsPage.toastNotifications.success"
                  )}
                  failureMessage={t(
                    "viewDocumentsPage.toastNotifications.failure"
                  )}
                />
                {t("modal.csvModal.downloadCsvLink")}
              </Button>
            </span>
          </div>

          <form method="post" onSubmit={handleSubmitUpload}>
            <input
              type="file"
              onChange={handleChange}
              ref={fileUploadInputRef}
              className="hidden"
            />

            <button type="submit">Upload</button>
          </form>

          {/* <button type="submit" onClick={()=>{handleSubmitUpload1()}} >alert</button> */}
          {/* <div className="form-container rest-of-six-col-xs">
            <Formik
                // initialValues={initialValuesdocument}
                validationSchema={clientAddDocumentSchema}
                onSubmit={() => {
                    handleSubmit();
                }}
                // innerRef={formikRef}
            >
                {() => (
                    <Form>
                        <div className="flex justify-center">
                            <div className="row-xs w-[25vw]"> */}
          {/*    <div className="one-col-xs text-center">
                                    <h3>Add Document</h3>
                                </div>
                                <div className="one-col-xs">
                                    <Input
                                        name="description"
                                        isTextArea={true}
                                        rows="8"
                                        placeholder="Description"
                                    />
                                </div>
                                <div className="one-col-xs">
                                    <Input
                                        name="fileName"
                                        type="text"
                                        placeholder="File Name"
                                    />
                                </div> */}
          {/* <div className="one-col-xs">
                                    <input type="file" name="file" onChange={(e) => handleFileUpload(e)} />
                                </div>
                                <div className="btn-container w-full relative">
                                    <Button
                                        type="submit"
                                        loading={addingDocument}
                                        className="!m-0"
                                    >
                                        Add Document
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>  */}

          {/* </div> */}
        </div>
        <div className="text-center pt-3">
          <a href="#" onClick={onCancelClick}>
            {t("modal.csvModal.cancel")}
          </a>
        </div>
      </>
    );
  };

  const jsxForType4 = () => {
    const acceptArticle = () => {
      navigate(`${navToOrderAdd()}`, { replace: true });
      onCancelClick();
    };

    const addMoreItems = () => {
      navigate(`${navToPrices()}`, { replace: true });
      onCancelClick();
    };

    return (
      <>
        <div className="h-[80%] flex flex-col justify-between">
          <Table
            columns={columns4}
            data={cartData}
            defaultPageSize={10}
            hasPagination={false}
            hasSorting={false}
            isBorderlessTable={true}
            tableName="Items/s"
          />
          <div className="btn-container">
            <Button
              type="submit"
              className="!mx-2"
              variant="white"
              onClick={acceptArticle}
              disabled={DisableButton(cartItems)}
            >
              {t("viewPricesPage.basket.acceptArticle")}
            </Button>
            <Button
              type="submit"
              className="!mx-2"
              onClick={addMoreItems}
              disabled={DisableButton(cartItems)}
            >
              {t("viewPricesPage.basket.addMoreItems")}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const jsxForType5 = () => {
    return (
      <>
        <div className="flex flex-col justify-between">
          <div className="btn-container">
            <Button
              type="button"
              className="!mx-2"
              variant="white"
              onClick={() => {
                handleYesToRemove(false);
                onCancelClick();
              }}
            >
              {t("modal.no")}
            </Button>
            <Button
              type="button"
              className="!mx-2"
              onClick={() => {
                handleYesToRemove(true);
                onCancelClick();
              }}
            >
              {t("modal.yes")}
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={`${
          noBgOverlay ? "modalBackground !bg-opacity-0" : "modalBackground"
        }`}
        onClick={noOutsideClickClose ? null : onOutsideClick}
      >
        <div className={`modalContainer ${modalWidth} ${modalHeight}`}>
          <div
            className={`flex ${
              centeredHeading ? "justify-center" : "justify-between"
            } pb-8`}
          >
            <h2 className={headingCss}>{modalTitle}</h2>
            {hasMoreBtn && (
              <DropdownButton
                listItems={[
                  `${t("viewOrderDetailPage.claims")}`,
                  `${t("viewOrderDetailPage.contactCustomerService")}`,
                ]}
              />
            )}
          </div>
          {modalType === modalTypes?.type1?.name ? (
            jsxForType1()
          ) : modalType === modalTypes?.type2?.name ? (
            jsxForType2()
          ) : modalType === modalTypes?.type3?.name ? (
            jsxForType3()
          ) : modalType === modalTypes?.type4?.name ? (
            jsxForType4()
          ) : modalType === modalTypes?.type5?.name ? (
            jsxForType5()
          ) : (
            <p>Please specify a modalType in the Modal Component.</p>
          )}
        </div>
      </div>
    </>
  );
};

Modal.propTypes = {
  modalTitle: PropTypes.string,
  // onOkClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  hasMoreBtn: PropTypes.bool,
  centeredHeading: PropTypes.bool,
  headingCss: PropTypes.string,
};

export default Modal;
