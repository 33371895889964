import * as Yup from 'yup';
import { phoneNumberValidation } from '../../yupValidations';

export const adminAddUserDefaultAddressSchema = Yup.object().shape({
    userId: Yup.string().required("Required"),
    addressSupplement: Yup.string(),
    city: Yup.string().required("Required"),
    companyName: Yup.string(),
    companySuppliment: Yup.string(),
    countryCode: Yup.string().required("Required"),
    email: Yup.string().email(),
    firstName: Yup.string(),
    houseNumber: Yup.string().required("Required"),
    lastName: Yup.string(),
    name1: Yup.string(),
    name2: Yup.string(),
    phoneNumber: phoneNumberValidation(),
    streetName: Yup.string().required("Required"),
    zipCode: Yup.string().required("Required"),
});