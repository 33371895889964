import { Outlet } from 'react-router-dom';

function Home() {
  return (
    <div>
      Go through the navigation panel to make use of this admin panel.
      <Outlet />
    </div>
  )
}

export default Home;