import { saveAs } from 'file-saver';
import { successToast, failureToast } from "../../components/Toast";

function UserException(message) {
    this.message = message;
    this.name = 'DownloadError';
}

export const downloadFile = (downloadLink,
    authToken,
    successMessage = "Successfully Downloaded",
    failureMessage = "Failed to Download",
    fileName = "download",
    fileType = "txt",
) => {
    let downloadedFileName = `${fileName}.${fileType}`;

    fetch(downloadLink, {
        headers: {
            'Authorization': `Basic ${authToken}`
        },
    })
        .then(res => {
            // console.log(...res.headers);
            if (res.ok) {
                if (res.headers.get("Content-Disposition")) {
                    downloadedFileName = res.headers.get("Content-Disposition").split("= ")[1];
                }
                return res.blob();
            } else {
                if (res?.status === 404) {
                    throw new UserException("File Not Found");
                } else {
                    throw new UserException(failureMessage);
                }
            }
        })
        .then(blob => {
            // If "Content-Disposition" header is allowed from the Backend, name will be according to the fileName from that header.
            // Else, we will use the default fileName and fileType or supply it when calling this function.
            saveAs(blob, downloadedFileName);
            successToast(successMessage);
        })
        .catch(error => {
            if (error?.name === "DownloadError")
                failureToast(error?.message);
        })
};