import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../../config";

export const newsfeedByAdminApi = createApi({
    reducerPath: "newsfeedByAdminApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().adminAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['AdminNewsfeed'],

    endpoints: (builder) => ({
        addNewsfeed: builder.mutation({
            query: (data) => {
                return {
                    url: `/newsfeed/`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminNewsfeed'],
        }),

        getNewsfeed: builder.query({
            query: () => {
                return {
                    url: `/dashboard`,
                    method: "GET",
                };
            },
            providesTags: ['AdminNewsfeed'],
        }),

        deleteNewsfeed: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `/newsfeed/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['AdminNewsfeed'],
        }),

    }),
});

export const {
    useAddNewsfeedMutation,
    useGetNewsfeedQuery,
    useDeleteNewsfeedMutation,
} = newsfeedByAdminApi;