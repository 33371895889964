export const viewUserAccountPageEn = {
    userAccount: "User Account",
    requestChangeOfMasterData: "Request change of master data",
    baseData: {
        baseData: "Base Data",
        nameOfCompany1: "Name of the company",
        nameOfCompany2: "Name of the company (2)",
        streetHouseNumber: "Street, House Number",
        countryCodeZipLocation: "Country Code, Zip Location",
        phone: "Phone",
        email: "E-Mail",
        taxId: "Tax ID",
        taxNumber: "Tax Number",
        commercialRegister: "Commercial Register",
        placeOfJurisdiction: "Place of Jurisdiction",
        bankName: "Bankname",
        iban: "IBAN",
        bic: "BIC",
    },
    furtherMasterData: {
        furtherMasterData: "Further Master Data",
        dropshippingOrWarehousing: "Dropshipping or Warehousing",
        warehouseAddress: "Warehouse Address",
        itConnection: "IT Connection",
        date: "Date (DD.MM.YYYY)",
        completedBy: "Completed By",
    },
    invoice: {
        invoice: "Invoice",
        contactPerson: "Contact Person",
        email: "E-Mail",
        phone: "Phone",
    },
    completion: {
        completion: "Completion",
        contactPerson: "Contact Person",
        email: "E-Mail",
        phone: "Phone",
    },
    orderConfirmation: {
        orderConfirmation: "Order Confirmation",
        contactPerson: "Contact Person",
        email: "E-Mail",
        phone: "Phone",
    },
    it: {
        it: "IT",
        contactPerson: "Contact Person",
        email: "E-Mail",
        phone: "Phone",
    },
    deliveryNote: {
        deliveryNote: "Delivery Note",
        contactPerson: "Contact Person",
        email: "E-Mail",
        phone: "Phone",
    },
    complaintOrReturns: {
        complaintOrReturns: "Complaint/Returns",
        contactPerson: "Contact Person",
        email: "E-Mail",
        phone: "Phone",
    },
    toastNotifications: {
        failureLoadingAccountDetails: "Failed to load account details.",
        failureLoadingDepartmentDetails: "Failed to load departments.",
    },
};

export const viewUserAccountPageDe = {
    userAccount: "Benutzerkonto",
    requestChangeOfMasterData: "Stammdaten Änderung anfragen",
    baseData: {
        baseData: "Stammdaten",
        nameOfCompany1: "Name der Gesellschaft",
        nameOfCompany2: "Name der Gesellschaft (2)",
        streetHouseNumber: "Straße & Hausnummer",
        countryCodeZipLocation: "Land, PLZ & Ort",
        phone: "Telefon",
        email: "E-Mail",
        taxId: "Umsatzsteuer-ID",
        taxNumber: "Steuernummer",
        commercialRegister: "Handelsregister",
        placeOfJurisdiction: "Zuständiger Ort",
        bankName: "Bankname",
        iban: "IBAN",
        bic: "BIC",
    },
    furtherMasterData: {
        furtherMasterData: "weitere Stammdaten",
        dropshippingOrWarehousing: "Dropshipping oder Lagerbevorratung",
        warehouseAddress: "Adresse Lagerbevorratung",
        itConnection: "IT Anbindung",
        date: "Datum (DD.MM.YYYY)",
        completedBy: "Ausgefüllt von",
    },
    invoice: {
        invoice: "Rechnung",
        contactPerson: "Ansprechpartner",
        email: "E-Mail",
        phone: "Telefon",
    },
    completion: {
        completion: "Abwicklung",
        contactPerson: "Ansprechpartner",
        email: "E-Mail",
        phone: "Telefon",
    },
    orderConfirmation: {
        orderConfirmation: "Auftragsbestätigung",
        contactPerson: "Ansprechpartner",
        email: "E-Mail",
        phone: "Telefon",
    },
    it: {
        it: "IT",
        contactPerson: "Ansprechpartner",
        email: "E-Mail",
        phone: "Telefon",
    },
    deliveryNote: {
        deliveryNote: "Lieferschein",
        contactPerson: "Ansprechpartner",
        email: "E-Mail",
        phone: "Telefon",
    },
    complaintOrReturns: {
        complaintOrReturns: "Reklamation/Retouren",
        contactPerson: "Ansprechpartner",
        email: "E-Mail",
        phone: "Telefon",
    },
    toastNotifications: {
        failureLoadingAccountDetails: "Kontodetails konnten nicht geladen werden.",
        failureLoadingDepartmentDetails: "Abteilungen konnten nicht geladen werden.",
    },
};