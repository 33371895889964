import { useMemo, useState, useEffect, useRef } from "react";
import { Formik, Form, } from "formik";
import countryList from "react-select-country-list";
import { adminAddUserDefaultAddressSchema } from "../../../../../validations/admin/user/addDefaultAddress";
import { useAddUserDefaultAddressMutation, useGetUsersQuery, useGetUserAddressQuery } from "../../../../../services/admin/users";
import SimpleTable from "../../../../../components/Table/SimpleTable";
import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";
import Button from "../../../../../components/Button";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { successToast, failureToast } from "../../../../../components/Toast";

const resetInitialValues = {
    userId: "",
    addressSupplement: "",
    city: "",
    companyName: "",
    companySuppliment: "",
    countryCode: "",
    email: "",
    firstName: "",
    houseNumber: "",
    lastName: "",
    name1: "",
    name2: "",
    phoneNumber: "",
    streetName: "",
    zipCode: ""
};

function AdminUserDefaultAddressAdd() {
    const [fetchData, setFetchData] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [tableData, setTableData] = useState([]);

    const formikRef = useRef(null);

    const countryCodeoptions = useMemo(() => countryList().getData(), []);

    // Get Users Api
    const { data: usersData, isError: isUsersError, isFetching: isFetchingUsers } = useGetUsersQuery();

    // Get Single User Default Address Api
    const { data: userAddressData, isError: isUserAddressError, isFetching: isFetchingUserAddress, refetch: refetchUserAddressData } = useGetUserAddressQuery(selectedUserId, {
        skip: !selectedUserId,
    });

    const [addUserDefaultAddress, {
        isLoading: addingAddress,
        isError: isAddressAddError,
        isSuccess: isAddressAddSuccess,
    }] = useAddUserDefaultAddressMutation();

    const [initialValues, setInitialValues] = useState({
        addressSupplement: "",
        city: "",
        companyName: "",
        companySuppliment: "",
        countryCode: "",
        email: "",
        firstName: "",
        houseNumber: "",
        lastName: "",
        name1: "",
        name2: "",
        phoneNumber: "",
        streetName: "",
        zipCode: ""
    });

    useEffect(() => {
        if (isAddressAddError)
            failureToast("Failed to add address");
        if (isAddressAddSuccess) {
            successToast("Successfully added address");
            setFetchData(false);
            // When we resetForm, formik by-default resets to initialValues which has been changed due to update.
            // So we set initialValues explicitly.
            formikRef?.current?.resetForm({ values: resetInitialValues });
        }
    }, [isAddressAddError, isAddressAddSuccess]);

    const handleSubmit = (values) => {
        const { userId, ...rest } = values;
        addUserDefaultAddress({ id: userId, data: rest });
    };

    // Table Logic
    const columns = useMemo(
        () => [
            {
                id: "id",
                Header: "User ID",
                accessor: "id",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "defaultAddress",
                Header: "Default Address",
                accessor: "defaultAddress",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: 'update',
                Header: "Update",
                accessor: 'update',
                Cell: ({ row }) => (
                    <Button
                        variant="white"
                        onClick={() => {
                            setSelectedUserId(row?.original?.id);
                            setFetchData(true);
                            refetchUserAddressData();
                        }}
                    >
                        Add
                    </Button>
                ),
                // Id of that column which needs to be filtered.
                filterColumnId: 'name',
                canBeFiltered: true,
                filterPlaceholder: "Search Name",
            },
        ],
        []
    );

    useEffect(() => {
        if (usersData) {
            setTableData(usersData?.payload?.map((data) => {
                return {
                    id: data?.id,
                    name: data?.name,
                    email: data?.email,
                    role: data?.role,
                    status: data?.active,
                    defaultAddress: data?.defaultAddress ? "Yes" : "No",
                }
            }));
        }
    }, [usersData]);

    // Single User Default Address Logic
    useEffect(() => {
        if (isUserAddressError)
            failureToast("Failed to add address");
        if (userAddressData && fetchData) {
            setInitialValues({
                userId: selectedUserId.toString(),
                addressSupplement: userAddressData?.payload?.addressSupplement || "",
                city: userAddressData?.payload?.city || "",
                companyName: userAddressData?.payload?.companyName || "",
                companySuppliment: userAddressData?.payload?.companySuppliment || "",
                countryCode: userAddressData?.payload?.countryCode || "",
                email: userAddressData?.payload?.email || "",
                firstName: userAddressData?.payload?.firstName || "",
                houseNumber: userAddressData?.payload?.houseNumber || "",
                lastName: userAddressData?.payload?.lastName || "",
                name1: userAddressData?.payload?.name1 || "",
                name2: userAddressData?.payload?.name2 || "",
                phoneNumber: userAddressData?.payload?.phoneNumber || "",
                streetName: userAddressData?.payload?.streetName || "",
                zipCode: userAddressData?.payload?.zipCode || "",
            });
        }
    }, [isUserAddressError, userAddressData]);

    return (
        <div className="row-xs">
            <div className="two-col-xs">
                <SimpleTable
                    columns={columns}
                    data={tableData}
                    isFetchError={isUsersError}
                    isLoadingData={isFetchingUsers}
                    loadingSvgFixed={false}
                />
            </div>
            <div className="two-col-xs">
                <div className="form-container">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={adminAddUserDefaultAddressSchema}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                        innerRef={formikRef}
                        enableReinitialize={true}
                    >
                        {() => (
                            <Form>
                                <div className="flex justify-center">
                                    <div className="row-xs w-[30vw]">
                                        <div className="one-col-xs text-center">
                                            {isFetchingUserAddress ?
                                                <LoadingOverlay /> :
                                                <h3>Save User Default Address</h3>
                                            }
                                        </div>
                                        <div className="two-col-xs">
                                            <div className="row-xs">
                                                <div className="one-col-xs">
                                                    <h4>Basic Details</h4>
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="userId"
                                                        type="text"
                                                        placeholder="User Id"
                                                    />
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="name1"
                                                        type="text"
                                                        placeholder="Name 1"
                                                    />
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="name2"
                                                        type="text"
                                                        placeholder="Name 2"
                                                    />
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Last Name"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="two-col-xs">
                                            <div className="row-xs">
                                                <div className="one-col-xs">
                                                    <h4>Address Details</h4>
                                                </div>
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="streetName"
                                                    type="text"
                                                    placeholder="Street Name"
                                                />
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="houseNumber"
                                                    type="text"
                                                    placeholder="House Number"
                                                />
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="addressSupplement"
                                                    type="text"
                                                    placeholder="Address Supplement"
                                                />
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="city"
                                                    type="text"
                                                    placeholder="City"
                                                />
                                            </div>
                                            <div className="one-col-xs">
                                                <Select name="countryCode">
                                                    <option value="" disabled={true} hidden={true}>Choose Country</option>
                                                    {countryCodeoptions?.map((item, index) => (
                                                        <option value={item?.value} key={index}>{item?.value}</option>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="zipCode"
                                                    type="text"
                                                    placeholder="Zip Code"
                                                />
                                            </div>
                                        </div>
                                        <div className="two-col-xs">
                                            <div className="row-xs">
                                                <div className="one-col-xs">
                                                    <h4>Company Details</h4>
                                                </div>
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="companyName"
                                                    type="text"
                                                    placeholder="Company Name"
                                                />
                                            </div>
                                            <div className="one-col-xs">
                                                <Input
                                                    name="companySuppliment"
                                                    type="text"
                                                    placeholder="Company Supplement"
                                                />
                                            </div>
                                        </div>
                                        <div className="two-col-xs">
                                            <div className="row-xs">
                                                <div className="one-col-xs">
                                                    <h4>Other Details</h4>
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                    />
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="phoneNumber"
                                                        type="text"
                                                        placeholder="Phone Number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-container w-full relative">
                                            <Button
                                                type="submit"
                                                loading={addingAddress}
                                                className="!m-0"
                                            >
                                                Save Default Address
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AdminUserDefaultAddressAdd;