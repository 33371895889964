import { useEffect, useRef, useMemo, useState } from "react";
import { Formik, Form, } from "formik";
import { adminAddUserSchema } from "../../../../validations/admin/user/addUser";
import { useAddUserMutation, useGetUsersQuery, useUpdateUserMutation } from "../../../../services/admin/users";
import SimpleTable from "../../../../components/Table/SimpleTable";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import PasswordInput from "../../../../components/Input/PasswordInput";
import { successToast, failureToast } from "../../../../components/Toast";

const resetInitialValues = {
    email: "",
    name: "",
    password: "",
    priceListGroup: "",
    role: "",
    stockName: ""
};

function AdminUserAdd() {
    const [isUpdateUser, setIsUpdateUser] = useState(false);
    const [updatedUserId, setUpdatedUserId] = useState(null);
    const [tableData, setTableData] = useState([]);

    const { data: usersData, isError: isUsersError, isFetching: isFetchingUsers } = useGetUsersQuery();

    const formikRef = useRef(null);

    const [addUser, {
        isLoading: addingUser,
        isError: isUserAddError,
        isSuccess: isUserAddSuccess
    }] = useAddUserMutation();

    const [updateUser, {
        isLoading: updatingUser,
        isError: isUserUpdateError,
        isSuccess: isUserUpdateSuccess
    }] = useUpdateUserMutation();

    // Form Logic
    const [initialValues, setInitialValues] = useState({
        email: "",
        name: "",
        password: "",
        priceListGroup: "",
        role: "",
        stockName: ""
    });

    // Add User Toasts
    useEffect(() => {
        if (isUserAddError)
            failureToast("Failed to add user");
        if (isUserAddSuccess) {
            successToast("Successfully added user");
            formikRef?.current?.resetForm();
        }
    }, [isUserAddError, isUserAddSuccess]);

    // Update User Toasts
    useEffect(() => {
        if (isUserUpdateError)
            failureToast("Failed to update user");
        if (isUserUpdateSuccess) {
            successToast("Successfully updated user");
            // When we resetForm, formik by-default resets to initialValues which has been changed due to update.
            // So we set initialValues explicitly.
            formikRef?.current?.resetForm({ values: resetInitialValues });
        }
    }, [isUserUpdateError, isUserUpdateSuccess]);

    const handleSubmit = (values, isUpdate = false) => {
        if (!isUpdate) {
            addUser(values);
        }
        else {
            if (!(values?.password?.length)) {
                const { password, ...rest } = values;
                updateUser({ ...rest, id: updatedUserId });
            } else {
                updateUser({ ...values, id: updatedUserId });
            }
        }
    };

    // Table Logic
    const columns = useMemo(
        () => [
            {
                id: "id",
                Header: "User ID",
                accessor: "id",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: 'search',
                Header: "Search",
                accessor: 'search',
                Cell: ({ row }) => (
                    <Button
                        variant="white"
                        onClick={() => {
                            setUpdatedUserId(row?.original?.id);
                            setInitialValues({
                                email: row?.original?.email || "",
                                name: row?.original?.name || "",
                                priceListGroup: row?.original?.priceListGroup || "",
                                role: row?.original?.role || "",
                                stockName: row?.original?.stockName || "",
                            })
                            setIsUpdateUser(true);
                        }}
                    >
                        Update
                    </Button>
                ),
                // Id of that column which needs to be filtered.
                filterColumnId: 'name',
                canBeFiltered: true,
                filterPlaceholder: "Search Name",
            },
        ],
        []
    );

    useEffect(() => {
        if (usersData) {
            setTableData(usersData?.payload?.map((data) => {
                return {
                    id: data?.id,
                    name: data?.name,
                    email: data?.email,
                    role: data?.role,
                    status: data?.active,
                    priceListGroup: data?.priceListGroup,
                    stockName: data?.stockName,
                }
            }));
        }
    }, [usersData]);

    return (
        <div className="row-xs">
            <div className="rest-of-three-col-xs">
                <SimpleTable
                    columns={columns}
                    data={tableData}
                    isFetchError={isUsersError}
                    isLoadingData={isFetchingUsers}
                    loadingSvgFixed={false}
                />
            </div>
            <div className="three-col-xs">
                <div className="form-container">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={adminAddUserSchema}
                        onSubmit={(values) => {
                            handleSubmit(values, isUpdateUser);
                        }}
                        innerRef={formikRef}
                        enableReinitialize={true}
                    >
                        {() => (
                            <Form>
                                <div className="flex justify-center">
                                    <div className="row-xs w-[25vw]">
                                        <div className="one-col-xs text-center">
                                            <h3>Save User</h3>
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="name"
                                                type="text"
                                                placeholder="Name"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <PasswordInput
                                                name="password"
                                                type="password"
                                                placeholder="Password*"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="priceListGroup"
                                                type="text"
                                                placeholder="Price List Group"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <Select name="role">
                                                <option value="" disabled={true} hidden={true}>Select Role</option>
                                                <option value="USER">USER</option>
                                                <option value="ADMIN">ADMIN</option>
                                            </Select>
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="stockName"
                                                type="text"
                                                placeholder="Stock Name"
                                            />
                                        </div>
                                        <div className="btn-container w-full relative">
                                            <Button
                                                type="submit"
                                                loading={isUpdateUser ? updatingUser : addingUser}
                                                className="!m-0"
                                            >
                                                Save User
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AdminUserAdd;