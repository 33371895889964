import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const accountDetailsApi = createApi({
    reducerPath: "accountDetailsApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().clientAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (builder) => ({
        getAccountDetails: builder.query({
            query: (id) => {
                return {
                    url: `/account/${id}/`,
                    method: "GET",
                };
            },
        }),

        getDepartmentDetails: builder.query({
            query: (id) => {
                return {
                    url: `/account/${id}/departments`,
                    method: "GET",
                };
            },
        }),

    }),
});

export const { useGetAccountDetailsQuery, useGetDepartmentDetailsQuery } = accountDetailsApi;