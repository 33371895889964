import { createSlice } from '@reduxjs/toolkit';
import { getAdminAuthToken, removeAdminAuthToken } from '../../storage/localStorage';

const initialState = {
    authToken: null,
}

export const adminAuthSlice = createSlice({
    name: 'adminAuth',
    initialState,
    reducers: {
        setAdminToken: (state) => {
            const token = getAdminAuthToken() || null;
            state.authToken = token;
        },
        removeAdminToken: (state) => {
            removeAdminAuthToken();
            state.authToken = null;
        },
    },
})

export const { setAdminToken, removeAdminToken } = adminAuthSlice.actions;

export default adminAuthSlice.reducer;