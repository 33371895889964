import { useField } from 'formik';
import PropTypes from 'prop-types';

function Input({
    name,
    fieldErrMessage = 'field-err-message',
    className = "",
    isTextArea=false,
    ...props
}) {
    const [field, meta] = useField(name);
    return (
        <div>
            {isTextArea ? (
                <textarea className={`custom-placeholder ${className}`} {...field} {...props}></textarea>
            ) : (
                <input className={`custom-placeholder ${className}`} {...field} {...props} />
            )}
            {meta.touched && meta.error ? (
                <div className={fieldErrMessage}>{meta.error}</div>
            ) : null}
        </div>
    );
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    fieldErrMessage: PropTypes.string,
    inputType: PropTypes.bool,
};

export default Input;
