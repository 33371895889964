import { dashboardPageEn, dashboardPageDe } from "./pageTranslations/home";
import { modalEn, modalDe } from "./pageTranslations/modal";
import {
  DownloadButtonPopUpModalen,
  DownloadButtonPopUpModalde,
} from "./pageTranslations/DownLoadPopUpModal";
import {
  viewOrdersPageEn,
  viewOrdersPageDe,
} from "./pageTranslations/orders/viewOrders";
import {
  addOrdersPageEn,
  addOrdersPageDe,
} from "./pageTranslations/orders/addOrders";
import {
  viewOrderDetailPageEn,
  viewOrderDetailPageDe,
} from "./pageTranslations/orders/viewOrderDetail";
import { viewPricesPageEn, viewPricesPageDe } from "./pageTranslations/prices";
import {
  viewDocumentsPageEn,
  viewDocumentsPageDe,
} from "./pageTranslations/documents";
import {
  viewContactPageEn,
  viewContactPageDe,
} from "./pageTranslations/contact";
import {
  viewUserAccountPageEn,
  viewUserAccountPageDe,
} from "./pageTranslations/userAccount";

export const resources = {
  en: {
    translation: {
      search: "Search",
      nav: {
        homepage: "Homepage",
        orders: "Orders",
        orderEntry: "Order Entry",
        prices: "Inventory / Prices",
        documents: "Forms / Documents",
        currentBasket: "Current Basket",
        contact: "Contact",
        userAccount: "User Account",
        logout: "Logout",
        imprint: "Imprint",
        privacy: "Privacy",
      },
      noDataFoundInTable: "Sorry, no data found.",
      modal: modalEn,
      DownloadButtonPopUpModal: DownloadButtonPopUpModalen,
      dashboardPage: dashboardPageEn,
      viewOrdersPage: viewOrdersPageEn,
      addOrdersPage: addOrdersPageEn,
      viewOrderDetailPage: viewOrderDetailPageEn,
      viewPricesPage: viewPricesPageEn,
      viewDocumentsPage: viewDocumentsPageEn,
      viewContactPage: viewContactPageEn,
      viewUserAccountPage: viewUserAccountPageEn,
    },
  },
  de: {
    translation: {
      search: "Suche",
      nav: {
        homepage: "Startseite",
        orders: "Bestellungen",
        orderEntry: "Auftragserfassung",
        prices: "Bestand / Preise",
        documents: "Formulare / Dokumente",
        currentBasket: "Aktueller Sammelkorb",
        contact: "Kontakt",
        userAccount: "Benutzerkonto",
        logout: "Abmelden",
        imprint: "Impressum",
        privacy: "Datenschutz",
      },
      noDataFoundInTable: "Entschuldigung, keine Daten gefunden.",
      modal: modalDe,
      DownloadButtonPopUpModal: DownloadButtonPopUpModalde,
      dashboardPage: dashboardPageDe,
      viewOrdersPage: viewOrdersPageDe,
      addOrdersPage: addOrdersPageDe,
      viewOrderDetailPage: viewOrderDetailPageDe,
      viewPricesPage: viewPricesPageDe,
      viewDocumentsPage: viewDocumentsPageDe,
      viewContactPage: viewContactPageDe,
      viewUserAccountPage: viewUserAccountPageDe,
    },
  },
};
