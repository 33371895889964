import { useEffect, useRef } from "react";
import { Formik, Form, } from "formik";
import { adminAddDocumentSchema } from "../../../../validations/admin/document/addDocument";
import { useAddDocumentMutation } from "../../../../services/admin/document";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import { successToast, failureToast } from "../../../../components/Toast";

function AdminDocumentAdd() {
    const formikRef = useRef(null);
    const formData = new FormData();

    const [addDocument, {
        isLoading: addingDocument,
        isError: isDocumentAddError,
        isSuccess: isDocumentAddSuccess
    }] = useAddDocumentMutation();

    const initialValues = {
        description: "",
        fileName: "",
    };

    useEffect(() => {
        if (isDocumentAddError)
            failureToast("Failed to add document");
        if (isDocumentAddSuccess) {
            successToast("Successfully added document");
            formikRef?.current?.resetForm();
        }
    }, [isDocumentAddError, isDocumentAddSuccess]);

    const handleFileUpload = (e) => {
        const files = e?.target?.files;
        formData.append('file', files[0]);
    };

    const handleSubmit = (values) => {
        const { description, fileName } = values;
        addDocument({ description, fileName, fileFormData: formData });
    };

    return (
        <div className="form-container rest-of-six-col-xs">
            <Formik
                initialValues={initialValues}
                validationSchema={adminAddDocumentSchema}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
                innerRef={formikRef}
            >
                {() => (
                    <Form>
                        <div className="flex justify-center">
                            <div className="row-xs w-[25vw]">
                                <div className="one-col-xs text-center">
                                    <h3>Add Document</h3>
                                </div>
                                <div className="one-col-xs">
                                    <Input
                                        name="description"
                                        isTextArea={true}
                                        rows="8"
                                        placeholder="Description"
                                    />
                                </div>
                                <div className="one-col-xs">
                                    <Input
                                        name="fileName"
                                        type="text"
                                        placeholder="File Name"
                                    />
                                </div>
                                <div className="one-col-xs">
                                    <input type="file" name="file" onChange={(e) => handleFileUpload(e)} />
                                </div>
                                <div className="btn-container w-full relative">
                                    <Button
                                        type="submit"
                                        loading={addingDocument}
                                        className="!m-0"
                                    >
                                        Add Document
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AdminDocumentAdd;