export const viewOrdersPageEn = {
    orders: "Orders",
    last30Days: "Last 30 Days",
    orderDate: "Order Date",
    externalOrderNumber: "External Order Number",
    orderNumber: "Order Number",
    billNumber: "Bill Number",
    status: "Status",
    search: "Search",
    more: "more",
    // For status filtering
    outstanding: "Outstanding",
    accepted: "Accepted",
    acceptedPartial: "Accepted Partial",
    declined: "Declined",
    inTransport: "In Transport",
    inTransportPartial: "In Transport Partial",
    deliveredPartial: "Delivered Partial",
    returned: "Credited",
    delivered: "Delivered",
    cancelled: "Cancelled",
    splitOrder: "Split-Order",
};

export const viewOrdersPageDe = {
    orders: "Bestellungen",
    last30Days: "letzte 30 Tage",
    orderDate: "Bestelldatum",
    externalOrderNumber: "ext. Auftragsnummer",
    orderNumber: "Auftragsnummer",
    billNumber: "Rechnungsnummer",
    status: "Status",
    search: "Suche",
    more: "mehr",
    // For status filtering
    outstanding: "Offen",
    accepted: "Akzeptiert",
    acceptedPartial: "Teilweise akzeptiert",
    returned: "Begutschriftet ",
    declined: "Abgelehnt",
    inTransport: "Versendet",
    inTransportPartial: "Teilweise versendet",
    deliveredPartial: "Teilweise zugestellt",
    delivered: "Zugestellt",
    cancelled: "Storniert",
    splitOrder: "Split-Auftrag",
};