export const viewOrderDetailPageEn = {
    details: "Details Order",
    orderOverview: {
        orderOverview: "Order Overview",
        ean: "EAN",
        quantity: "Quantity",
        price: "Price",
        net: "net",
    },
    tracking: {
        tracking: "Tracking",
        trackingNumber: "Tracking Number",
        serviceProvider: "Service Provider",
        status: "Status",
        trackingLink: "Tracking Link",
    },
    shippingAddress: {
        shippingAddress: "Shipping Address",
        company: "Company",
        additive: "Additive",
        firstName: "First Name",
        streetHouseNum: "Street, House Number",
        countryZipLocation: "Country ZIP Location",
    },
    assignment: {
        assignment: "Assignment",
        externalOrderNumber: "External Order Number",
        billNumber: "Bill Number",
    },
    claims: "Claims",
    contactCustomerService: "Contact Customer Service",
};

export const viewOrderDetailPageDe = {
    details: "Details Auftag",
    orderOverview: {
        orderOverview: "Auftragsübersicht",
        ean: "EAN",
        quantity: "Menge",
        price: "Preis",
        net: "netto",
    },
    tracking: {
        tracking: "Tracking",
        trackingNumber: "Sendungsnummer",
        serviceProvider: "Dienstleiser",
        status: "Status",
        trackingLink: "Tracking-Link",
    },
    shippingAddress: {
        shippingAddress: "Lieferadresse",
        company: "Firma",
        additive: "Zusatz",
        firstName: "Vorname",
        streetHouseNum: "Straße & Hausnummer",
        countryZipLocation: "Land, PLZ & Ort",
    },
    assignment: {
        assignment: "Auftrag",
        externalOrderNumber: "ext. Auftragsnummer",
        billNumber: "Rechnungsnummer",
    },
    claims: "Reklamation",
    contactCustomerService: "Kundenservice kontaktieren",
};