import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { navToAdminHome } from "../../../navigations";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import PasswordInput from "../../../components/Input/PasswordInput";
import { successToast, failureToast } from "../../../components/Toast";
import { getAdminAuthToken, setAdminAuthToken } from "../../../storage/localStorage";
import { useLazyLoginQuery } from "../../../services/auth";

const initialValues = {
  username: "",
  password: "",
};

function AdminLogin() {
  const navigate = useNavigate();
  const [encodedAuth, setEncodedAuth] = useState("");

  const [trigger, { data: loginData, isFetching, isSuccess, isError }] = useLazyLoginQuery();

  useEffect(() => {
    const token = getAdminAuthToken();
    if (token) {
      navigate(`${navToAdminHome()}`,
        { replace: true }
      );
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (loginData?.payload?.role === "ADMIN") {
        /* 
          On Success, we set localStorage not global state.
          The ClientLayout wrapper will set global state automatically if local storage exists.
          BUT on logging out, 
          we dispatch the removeClientToken state action which will remove the token from both localStorage and global state.
        */
        successToast("Successfully Logged In");
        setAdminAuthToken(encodedAuth);

        navigate(`${navToAdminHome()}`,
          { replace: true }
        );
      } else {
        failureToast("Only Admin can log in.");
      }
    }
    if (isError) {
      failureToast("Failed to log in");
    }
  }, [isError, isSuccess, loginData]);

  const handleLogin = (username, password) => {
    const encodedCred = window.btoa(username + ":" + password);
    trigger(encodedCred);
    setEncodedAuth(encodedCred);
  };

  return (
    <main className="main-view-no-sidebar login-box-wrapper">
      <div className="page-content-bg login-box">
        <h1 className="text-center pb-8">Admin Login</h1>
        <div className="form-container">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleLogin(values?.username, values?.password);
            }}
          >
            {({ dirty }) => (
              <Form>
                <div>
                  <Input
                    name="username"
                    type="text"
                    placeholder="Username*"
                  />
                  <PasswordInput
                    name="password"
                    type="password"
                    placeholder="Password*"
                  />
                  <div className="btn-container">
                    <Button
                      loading={isFetching}
                      disabled={!dirty || isFetching}
                      type="submit"
                      className="mr-0 mb-4"
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
}

export default AdminLogin;