import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const userDetailsApi = createApi({
    reducerPath: "userDetailsApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().clientAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (builder) => ({
        getUserAddress: builder.query({
            query: (id) => {
                return {
                    url: `/users/${id}/address`,
                    method: "GET",
                };
            },
        }),

    }),
});

export const { useGetUserAddressQuery } = userDetailsApi;