export const addOrdersPageEn = {
    orderEntry: "Order Entry",
    csvUpload: "CSV Upload",
    orderItem: "Order Item",
    shippingAddress: "Delivery Address",
    overview: "Overview",
    addMoreItems: "Add More Items",
    return: "Return",
    continue: "Continue",
    send: "Send",
    enterAnotherOrder: "Enter Another Order",
    abort: "Abort",
    step1: {
        ean: "EAN",
        quantity: "Quantity",
        minStock: "Minimum Order Quantity",
        price: "Price",
        addItemSelection: "Add Item Selection",
    },
    step2: {
        orderType: "Order Type",
        defaultAddress: "Default Address",
        shippingAddress: "Shipping Address",
        standardDelivery: "Standard Delivery",
        externalOrderNumber: "External Order Number",
        company: "Company",
        name: "Name",
        firstName: "First Name",
        salutation: "Salutation",
        street: "Street",
        nr: "Nr.",
        additive: "Additive",
        country: "Country",
        postCode: "Postcode",
        location: "Location",
        requiredFields: "Required Fields",
    },
    step3: {
        ean: "EAN",
        quantity: "Quantity",
        minStock: "Minimum Order Quantity",
        price: "Price",
        total: "Total",
        deliveryAddress: "Delivery Address",
        net: "net",
    },
    toastNotifications: {
        failureLoadingAccountDetails: "Failed loading account details.",
        successAddingOrder: "Successfully added orders.",
        failureAddingOrder: "Error adding orders.",
        failureAddingOrderFromCSV: "Failed to add document for order.",
        failureAddingQuantity: "Quantity must be multiple of Minimum Order Quantity.",
    },
};

export const addOrdersPageDe = {
    orderEntry: "Auftragserfassung",
    csvUpload: "CSV Upload",
    orderItem: "Bestellposition",
    shippingAddress: "Lieferadresse",
    overview: "Übersicht",
    addMoreItems: "Weitere Elemente hinzufügen",
    return: "Zurück",
    continue: "Weiter",
    send: "Abschicken",
    enterAnotherOrder: "Weiteren Auftrag Erfassen",
    abort: "abbrechen",
    step1: {
        ean: "EAN",
        quantity: "Menge",
        minStock: "Mindestbestellmenge",
        price: "Preis",
        addItemSelection: "Artikelauswahl hinzufügen",
    },
    step2: {
        orderType: "Auftragsart",
        defaultAddress: "Standardadresse",
        shippingAddress: "Lieferadresse",
        standardDelivery: "Standardlieferung",
        externalOrderNumber: "Externe Auftragsnummer",
        company: "Firma",
        name: "Name",
        firstName: "Vorname",
        salutation: "Anrede",
        street: "Straße*",
        nr: "Nr.",
        additive: "Zusatz",
        country: "Land",
        postCode: "PLZ",
        location: "Ort",
        requiredFields: "Pflichtfelder",
    },
    step3: {
        ean: "EAN",
        quantity: "Menge",
        minStock: "Mindestbestellmenge",
        price: "Preis",
        total: "Summe",
        deliveryAddress: "Lieferadresse",
        net: "netto",
    },
    toastNotifications: {
        failureLoadingAccountDetails: "Fehler beim Laden der Kontodetails.",
        successAddingOrder: "Bestellungen erfolgreich hinzugefügt.",
        failureAddingOrder: "Fehler beim Hinzufügen von Bestellungen.",
        failureAddingOrderFromCSV: "Bestellungen konnten nicht hinzugefügt werden.",
        failureAddingQuantity: "Die Menge muss ein Vielfaches der Mindestbestellmenge sein.",
    },
};