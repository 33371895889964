import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { navToPrices } from "../../../../../navigations";
import { changeQtyOrderCartItems, removeSelectedCartItem } from "../../../../../features/orderCart/orderCartSlice";
import Modal from "../../../../../components/Modal";
import { modalTypes } from "../../../../../components/Modal/ModalTypes";
import Table from "../../../../../components/Table";
import TableBodyInput from "../../../../../components/Input/TableBodyInput";

function Step1() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.orderCart.cartItems);
    const [tableData, setTableData] = useState([]);

    const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
    const [itemToRemove, setItemToRemove] = useState('');
    const [yesToRemove, setYesToRemove] = useState(false);

    /* For Managing Cart State */
    const handleQty = (id, value) => {
        dispatch(changeQtyOrderCartItems({ id, value }));
    };

    const showRemoveItemModal = () => setOpenRemoveItemModal(true);

    useEffect(() => {
        if (itemToRemove && yesToRemove) {
            dispatch(removeSelectedCartItem(itemToRemove));
        }
    }, [itemToRemove, yesToRemove]);

    const handleItemToRemove = (id) => {
        setItemToRemove(id);
    };

    const handleYesToRemove = (isYes) => {
        setYesToRemove(isYes);
    };
    /* For Managing Cart State */

    const columns = useMemo(
        () => [
            {
                id: "ean",
                Header: `${t('addOrdersPage.step1.ean')}`,
                accessor: "ean",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: true,
                canBeFiltered: false,
            },
            {
                id: "minStock",
                Header: `${t("addOrdersPage.step1.minStock")}`,
                accessor: "minStock",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: true,
                canBeFiltered: false,
            },
            {
                id: "quantity",
                Header: `${t('addOrdersPage.step1.quantity')}`,
                accessor: "quantity",
                Cell: ({ cell: { value }, row }) => (
                    <TableBodyInput
                        value={value}
                        minStock={row?.original?.minStock}
                        id={row?.original?.ean}
                        changeItemQty={handleQty}
                        showRemoveItemModal={showRemoveItemModal}
                        handleItemToRemove={handleItemToRemove}
                    />

                ),
                canBeSorted: true,
                canBeFiltered: false,
            },
            {
                id: "price",
                Header: `${t('addOrdersPage.step1.price')}`,
                accessor: "price",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: true,
                canBeFiltered: false,
            },
        ],
        // [cartItems?.length, t]
        [cartItems, t]
    );

    useEffect(() => {
        setTableData(cartItems?.map((item, index) => {
            return {
                id: index,
                ean: item?.ean,
                quantity: item?.quantity,
                minStock: item?.minStock,
                price: item?.price,
            };
        }))
    }, [cartItems]);

    return (
        <div className="px-8 py-4 rest-of-six-col-xs">
            {openRemoveItemModal ? (
                <Modal
                    modalTitle={`${t('modal.removePrompt')}`}
                    onCancelClick={() => {
                        setOpenRemoveItemModal(false);
                    }}
                    modalWidth="w-[40%]"
                    modalHeight="h-[25%]"
                    modalType={modalTypes?.type5?.name}
                    handleItemToRemove={handleItemToRemove}
                    handleYesToRemove={handleYesToRemove}
                    hasMoreBtn={false}
                    noOutsideClickClose={true}
                />
            ) : null}
            <Table
                columns={columns}
                data={tableData}
                defaultPageSize={10}
                hasPagination={false}
                hasSorting={false}
                isBorderlessTable={true}
                noTableData={true}
                tableName="Order Item/s"
            />
            <div className="flex flex-col justify-center items-center h-[200px]">
                {!tableData?.length ?
                    <NavLink
                        to={navToPrices()}
                        className="flex flex-col justify-center items-center"
                    >
                        <svg
                            width="25"
                            height="25"
                            xmlns="http://www.w3.org/2000/svg"
                            fillRule="evenodd"
                        >
                            <path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z" />
                        </svg>
                        <p className="pt-3">{t('addOrdersPage.step1.addItemSelection')}</p>
                    </NavLink> :
                    null
                }
            </div>
        </div>
    );
}

export default Step1; 