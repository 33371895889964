import {
  Routes,
  Route,
} from "react-router-dom";
/* Routes */
import NotFound from "./pages/notFound";
import AdminLayout from "./layouts/admin";
import ClientLayout from "./layouts/client";
// Admin Routes
import AdminLogin from './pages/admin/login';
import AdminHome from './pages/admin/home';
import AdminUserAdd from "./pages/admin/users/add";
import AdminUserDefaultAddressAdd from "./pages/admin/users/defaultAddress/add";
import AdminNewsfeedAdd from "./pages/admin/newsfeed/add";
import AdminDocumentAdd from "./pages/admin/documents/add";
import AdminDocumentView from "./pages/admin/documents/view";
import AdminAccountDetailsAdd from "./pages/admin/account/detail/add";
import AdminDepartmentAdd from "./pages/admin/department/add";

/** Client Routes **/
import ClientLogin from './pages/client/login';
import Home from './pages/client/home';
// Order Routes
import OrdersLayout from "./pages/client/orders";
import ViewOrder from "./pages/client/orders/view";
import AddOrder from "./pages/client/orders/add";
// Price Routes
import ViewPrices from './pages/client/prices/view';
// Document Routes
import ViewDocuments from './pages/client/documents/view';
// My Profile Route
import UserAccount from './pages/client/userAccount';

function App() {
  return (
    <Routes>
      {/* Client Routes */}
      <Route path={process.env.REACT_APP_STAGING_ROUTE ? process.env.REACT_APP_STAGING_ROUTE + "/login" : "/login"} element={<ClientLogin />} />
      <Route path={process.env.REACT_APP_STAGING_ROUTE ? process.env.REACT_APP_STAGING_ROUTE : "/"} element={<ClientLayout />}>
        <Route index element={<Home />} />
        <Route path="orders" element={<OrdersLayout />}>
          <Route index element={<ViewOrder
            horBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-hor.png"}
            verBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-ver-2.png"}
            hasSideTable={true}
          />}
          />
          <Route path="add" element={<AddOrder
            horBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-hor.png"}
            verBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-ver-2.png"}
            hasSideTable={false}
          />} />
        </Route>
        <Route path="prices" element={<ViewPrices
          horBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-hor.png"}
          verBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-ver-2.png"}
          hasSideTable={true}
        />} />
        <Route path="documents" element={<ViewDocuments
          horBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-hor.png"}
          verBannerAd={process.env.PUBLIC_URL + "/images/banner-ad-ver-2.png"}
          hasSideTable={true}
        />} />
        <Route path="user-account" element={<UserAccount />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      {/* Admin Routes */}
      <Route path={process.env.REACT_APP_STAGING_ROUTE ? process.env.REACT_APP_STAGING_ROUTE + "/admin/login" : "/admin/login"} element={<AdminLogin />} />
      <Route path={process.env.REACT_APP_STAGING_ROUTE ? process.env.REACT_APP_STAGING_ROUTE + "/admin" : "/admin"} element={<AdminLayout />}>
        <Route index element={<AdminHome />} />
        <Route path="user/add" element={<AdminUserAdd />} />
        <Route path="user/defaultAddress/add" element={<AdminUserDefaultAddressAdd />} />
        <Route path="newsfeed/add" element={<AdminNewsfeedAdd />} />
        <Route path="document/add" element={<AdminDocumentAdd />} />
        <Route path="document/view" element={<AdminDocumentView />} />
        <Route path="account/detail/add" element={<AdminAccountDetailsAdd />} />
        <Route path="department/add" element={<AdminDepartmentAdd />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes >
  );
}

export default App;

