export const viewContactPageEn = {
    contact: "Contact",
    phone: "Phone",
    email: "E-Mail",
    requestRecall: "Request Recall",
    phoneNumber: "Phone Number",
    date: "Date",
    time: "Time",
    contactForAnOrder: "Contact For An Order",
    requestTitle: "Request Title",
    preferredFeedback: "Preferred Feedback",
    submitRequest: "Submit Request",
    conclude: "Conclude",
    toastNotifications: {
        success: "Successfully sent inquiry.",
        failure: "Failed to send inquiry.",
    },
};

export const viewContactPageDe = {
    contact: "Kontakt",
    phone: "Telefon",
    email: "E-Mail",
    requestRecall: "Rückruf anfordern",
    phoneNumber: "Telefonnummer",
    date: "Datum",
    time: "Uhrzeit",
    contactForAnOrder: "Kontakt zu einem Auftrag",
    requestTitle: "Titel der Anfrage",
    preferredFeedback: "Bevorzugte Rückmeldung:",
    submitRequest: "Anfrage abschicken",
    conclude: "schließen",
    toastNotifications: {
        success: "Anfrage erfolgreich versendet.",
        failure: "Fehler beim Senden der Anfrage.",
    },
};