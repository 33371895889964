import { useMemo, useState, useEffect, useRef } from "react";
import { Formik, Form, } from "formik";
import countryList from "react-select-country-list";
import { adminAddUserAccountDetailsSchema } from "../../../../../validations/admin/account/addAccountDetail";
import { useAddUserAccountDetailsMutation, useGetUsersQuery, useGetUserAccountDetailsQuery } from "../../../../../services/admin/account";
import SimpleTable from "../../../../../components/Table/SimpleTable";
import Input from "../../../../../components/Input";
import InputLabel from "../../../../../components/Input/Label";
import Select from "../../../../../components/Select";
import Button from "../../../../../components/Button";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { successToast, failureToast } from "../../../../../components/Toast";

const resetInitialValues = {
    userId: "",
    accountAddress: {
        name1: "",
        name2: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companySuppliment: "",
        streetName: "",
        addressSupplement: "",
        houseNumber: "",
        city: "",
        zipCode: "",
        countryCode: "",
        phoneNumber: "",
        email: "",
    },
    taxId: "",
    taxNumber: "",
    commericalRegister: "",
    jurisdiction: "",
    bankName: "",
    iban: "",
    bic: "",
    warehouseOrDopshiping: "",
    warehouseAddress: {
        name1: "",
        name2: "",
        firstName: "",
        lastName: "",
        companyName: "",
        companySuppliment: "",
        streetName: "",
        addressSupplement: "",
        houseNumber: "",
        city: "",
        zipCode: "",
        countryCode: "",
        phoneNumber: "",
        email: "",
    },
    connectionInfo: "",
};

function AdminAccountDetailsAdd() {
    const [fetchData, setFetchData] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [tableData, setTableData] = useState([]);

    const formikRef = useRef(null);

    const countryCodeoptions = useMemo(() => countryList().getData(), []);

    // Get Users Api
    const { data: usersData, isError: isUsersError, isFetching: isFetchingUsers } = useGetUsersQuery();

    // Get Single User Account Details Api
    const { data: userAccountDetailsData, isError: isUserAccountDetailsError, isFetching: isFetchingUserAccountDetails, refetch: refetchUserAccountDetails } = useGetUserAccountDetailsQuery(selectedUserId, {
        skip: !selectedUserId,
    });

    const [addUserAccountDetails, {
        isLoading: addingAccDetails,
        isError: isAccDetailsAddError,
        isSuccess: isAccDetailsAddSuccess,
    }] = useAddUserAccountDetailsMutation();

    const [initialValues, setInitialValues] = useState({
        userId: "",
        accountAddress: {
            name1: "",
            name2: "",
            firstName: "",
            lastName: "",
            companyName: "",
            companySuppliment: "",
            streetName: "",
            addressSupplement: "",
            houseNumber: "",
            city: "",
            zipCode: "",
            countryCode: "",
            phoneNumber: "",
            email: "",
        },
        taxId: "",
        taxNumber: "",
        commericalRegister: "",
        jurisdiction: "",
        bankName: "",
        iban: "",
        bic: "",
        warehouseOrDopshiping: "",
        warehouseAddress: {
            name1: "",
            name2: "",
            firstName: "",
            lastName: "",
            companyName: "",
            companySuppliment: "",
            streetName: "",
            addressSupplement: "",
            houseNumber: "",
            city: "",
            zipCode: "",
            countryCode: "",
            phoneNumber: "",
            email: "",
        },
        connectionInfo: "",
    });

    useEffect(() => {
        if (isAccDetailsAddError)
            failureToast("Failed to add account details");
        if (isAccDetailsAddSuccess) {
            successToast("Successfully added account details");
            setFetchData(false);
            // When we resetForm, formik by-default resets to initialValues which has been changed due to update.
            // So we set initialValues explicitly.
            formikRef?.current?.resetForm({ values: resetInitialValues });
        }
    }, [isAccDetailsAddError, isAccDetailsAddSuccess]);

    const handleSubmit = (values) => {
        const { userId, ...rest } = values;
        if (rest?.warehouseOrDopshiping === "dropshipping") {
            rest.warehouseAddress = null;
            addUserAccountDetails({ id: userId, data: rest });
            // console.log({ id: userId, data: rest });
        } else {
            addUserAccountDetails({ id: userId, data: rest });
            // console.log({ id: userId, data: rest });
        }
    };

    // Table Logic
    const columns = useMemo(
        () => [
            {
                id: "id",
                Header: "User ID",
                accessor: "id",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: 'update',
                Header: "Update",
                accessor: 'update',
                Cell: ({ row }) => (
                    <Button
                        variant="white"
                        onClick={() => {
                            setSelectedUserId(row?.original?.id);
                            setFetchData(true);
                            refetchUserAccountDetails();
                        }}
                    >
                        Add
                    </Button>
                ),
                // Id of that column which needs to be filtered.
                filterColumnId: 'name',
                canBeFiltered: true,
                filterPlaceholder: "Search Name",
            },
        ],
        []
    );

    useEffect(() => {
        if (usersData) {
            setTableData(usersData?.payload?.map((data) => {
                return {
                    id: data?.id,
                    name: data?.name,
                    email: data?.email,
                    role: data?.role,
                    status: data?.active,
                }
            }));
        }
    }, [usersData]);

    // Single User Account Details Logic
    useEffect(() => {
        if (isUserAccountDetailsError)
            failureToast("Failed to add address");
        if (userAccountDetailsData && fetchData) {
            setInitialValues({
                userId: selectedUserId.toString(),
                accountAddress: {
                    name1: userAccountDetailsData?.payload?.accountAddress?.name1 || "",
                    name2: userAccountDetailsData?.payload?.accountAddress?.name2 || "",
                    firstName: userAccountDetailsData?.payload?.accountAddress?.firstName || "",
                    lastName: userAccountDetailsData?.payload?.accountAddress?.lastName || "",
                    companyName: userAccountDetailsData?.payload?.accountAddress?.companyName || "",
                    companySuppliment: userAccountDetailsData?.payload?.accountAddress?.companySuppliment || "",
                    streetName: userAccountDetailsData?.payload?.accountAddress?.streetName || "",
                    addressSupplement: userAccountDetailsData?.payload?.accountAddress?.addressSupplement || "",
                    houseNumber: userAccountDetailsData?.payload?.accountAddress?.houseNumber || "",
                    city: userAccountDetailsData?.payload?.accountAddress?.city || "",
                    zipCode: userAccountDetailsData?.payload?.accountAddress?.zipCode || "",
                    countryCode: userAccountDetailsData?.payload?.accountAddress?.countryCode || "",
                    phoneNumber: userAccountDetailsData?.payload?.accountAddress?.phoneNumber || "",
                    email: userAccountDetailsData?.payload?.accountAddress?.email || "",
                },
                taxId: userAccountDetailsData?.payload?.taxId || "",
                taxNumber: userAccountDetailsData?.payload?.taxNumber || "",
                commericalRegister: userAccountDetailsData?.payload?.commericalRegister || "",
                jurisdiction: userAccountDetailsData?.payload?.jurisdiction || "",
                bankName: userAccountDetailsData?.payload?.bankName || "",
                iban: userAccountDetailsData?.payload?.iban || "",
                bic: userAccountDetailsData?.payload?.bic || "",
                warehouseOrDopshiping: userAccountDetailsData?.payload?.warehouseOrDopshiping || "",
                warehouseAddress: {
                    name1: userAccountDetailsData?.payload?.warehouseAddress?.name1 || "",
                    name2: userAccountDetailsData?.payload?.warehouseAddress?.name2 || "",
                    firstName: userAccountDetailsData?.payload?.warehouseAddress?.firstName || "",
                    lastName: userAccountDetailsData?.payload?.warehouseAddress?.lastName || "",
                    companyName: userAccountDetailsData?.payload?.warehouseAddress?.companyName || "",
                    companySuppliment: userAccountDetailsData?.payload?.warehouseAddress?.companySuppliment || "",
                    streetName: userAccountDetailsData?.payload?.warehouseAddress?.streetName || "",
                    addressSupplement: userAccountDetailsData?.payload?.warehouseAddress?.addressSupplement || "",
                    houseNumber: userAccountDetailsData?.payload?.warehouseAddress?.houseNumber || "",
                    city: userAccountDetailsData?.payload?.warehouseAddress?.city || "",
                    zipCode: userAccountDetailsData?.payload?.warehouseAddress?.zipCode || "",
                    countryCode: userAccountDetailsData?.payload?.warehouseAddress?.countryCode || "",
                    phoneNumber: userAccountDetailsData?.payload?.warehouseAddress?.phoneNumber || "",
                    email: userAccountDetailsData?.payload?.warehouseAddress?.email || "",
                },
                connectionInfo: userAccountDetailsData?.payload?.connectionInfo || "",
            });
        }
    }, [isUserAccountDetailsError, userAccountDetailsData]);

    return (
        <div className="row-xs">
            <div className="two-col-xs">
                <SimpleTable
                    columns={columns}
                    data={tableData}
                    isFetchError={isUsersError}
                    isLoadingData={isFetchingUsers}
                    loadingSvgFixed={false}
                />
            </div>
            <div className="two-col-xs">
                <div className="form-container">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={adminAddUserAccountDetailsSchema}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                        innerRef={formikRef}
                        enableReinitialize={true}
                    >
                        {({ errors, setFieldValue, values }) => (
                            <Form>
                                <div className="flex justify-center">
                                    <div className="row-xs w-[30vw]">
                                        <div className="one-col-xs text-center">
                                            {isFetchingUserAccountDetails ?
                                                <LoadingOverlay /> :
                                                <h3>Save Account Detail</h3>
                                            }
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="userId"
                                                type="text"
                                                placeholder="User Id"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <div className="row-xs">
                                                <div className="one-col-xs">
                                                    <h4>Account Address</h4>
                                                </div>
                                                <div className="two-col-xs">
                                                    <div className="row-xs">
                                                        <div className="one-col-xs">
                                                            <p>Basic Details</p>
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.name1"
                                                                type="text"
                                                                placeholder="Name 1"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.name2"
                                                                type="text"
                                                                placeholder="Name 2"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.firstName"
                                                                type="text"
                                                                placeholder="First Name"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.lastName"
                                                                type="text"
                                                                placeholder="Last Name"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="two-col-xs">
                                                    <div className="row-xs">
                                                        <div className="one-col-xs">
                                                            <p>Address Details</p>
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.streetName"
                                                                type="text"
                                                                placeholder="Street Name"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.houseNumber"
                                                                type="text"
                                                                placeholder="House Number"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.addressSupplement"
                                                                type="text"
                                                                placeholder="Address Supplement"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.city"
                                                                type="text"
                                                                placeholder="City"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Select name="accountAddress.countryCode">
                                                                <option value="" disabled={true} hidden={true}>Choose Country</option>
                                                                {countryCodeoptions?.map((item, index) => (
                                                                    <option value={item?.value} key={index}>{item?.value}</option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.zipCode"
                                                                type="text"
                                                                placeholder="Zip Code"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="two-col-xs">
                                                    <div className="row-xs">
                                                        <div className="one-col-xs">
                                                            <p>Company Details</p>
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.companyName"
                                                                type="text"
                                                                placeholder="Company Name"
                                                            />
                                                        </div>
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.companySuppliment"
                                                                type="text"
                                                                placeholder="Company Supplement"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="two-col-xs">
                                                    <div className="row-xs">
                                                        <div className="one-col-xs">
                                                            <p>Other Details</p>
                                                        </div>
                                                        {/* <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.email"
                                                                type="email"
                                                                placeholder="Email"
                                                            />
                                                        </div> */}
                                                        <div className="one-col-xs">
                                                            <Input
                                                                name="accountAddress.phoneNumber"
                                                                type="text"
                                                                placeholder="Phone Number"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="taxId"
                                                                type="text"
                                                                placeholder="Tax Id"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="taxNumber"
                                                                type="text"
                                                                placeholder="Tax Number"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="commericalRegister"
                                                                type="text"
                                                                placeholder="Commerical Register"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="jurisdiction"
                                                                type="text"
                                                                placeholder="Jurisdiction"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="bankName"
                                                                type="text"
                                                                placeholder="Bank Name"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="iban"
                                                                type="text"
                                                                placeholder="IBAN"
                                                            />
                                                        </div>
                                                        <div className="two-col-xs">
                                                            <Input
                                                                name="bic"
                                                                type="text"
                                                                placeholder="BIC"
                                                            />
                                                        </div>
                                                        <div
                                                            className="one-col-xs flex"
                                                            onChange={(e) => {
                                                                setFieldValue('warehouseOrDopshiping', e?.target?.value)
                                                            }}
                                                        >
                                                            <span className="checkbox-container items-start">
                                                                <input
                                                                    type="radio"
                                                                    id="Warehouse"
                                                                    name="warehouseOrDopshiping"
                                                                    value="warehouse"
                                                                    checked={values?.warehouseOrDopshiping === "warehouse"}
                                                                />
                                                                <InputLabel
                                                                    htmlFor="Warehouse"
                                                                    label="Warehouse"
                                                                    required={false}
                                                                    className="font-medium"
                                                                />
                                                            </span>
                                                            <span className="checkbox-container items-start">
                                                                <input
                                                                    type="radio"
                                                                    id="Dropshipping"
                                                                    name="warehouseOrDopshiping"
                                                                    value="dropshipping"
                                                                    checked={values?.warehouseOrDopshiping === "dropshipping"}
                                                                />
                                                                <InputLabel
                                                                    htmlFor="Dropshipping"
                                                                    label="Dropshipping"
                                                                    required={false}
                                                                    className="font-medium"
                                                                />
                                                            </span>
                                                            {errors?.warehouseOrDopshiping ?
                                                                <div className="field-err-message">{errors?.warehouseOrDopshiping}</div> :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {values?.warehouseOrDopshiping === "warehouse" ?
                                            <div className="one-col-xs">
                                                <div className="row-xs">
                                                    <div className="one-col-xs">
                                                        <h4>Warehouse Address</h4>
                                                    </div>
                                                    <div className="two-col-xs">
                                                        <div className="row-xs">
                                                            <div className="one-col-xs">
                                                                <p>Basic Details</p>
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.name1"
                                                                    type="text"
                                                                    placeholder="Name 1"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.name2"
                                                                    type="text"
                                                                    placeholder="Name 2"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.firstName"
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.lastName"
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="two-col-xs">
                                                        <div className="row-xs">
                                                            <div className="one-col-xs">
                                                                <p>Address Details</p>
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.streetName"
                                                                    type="text"
                                                                    placeholder="Street Name"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.houseNumber"
                                                                    type="text"
                                                                    placeholder="House Number"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.addressSupplement"
                                                                    type="text"
                                                                    placeholder="Address Supplement"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.city"
                                                                    type="text"
                                                                    placeholder="City"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Select name="warehouseAddress.countryCode">
                                                                    <option value="" disabled={true} hidden={true}>Choose Country</option>
                                                                    {countryCodeoptions?.map((item, index) => (
                                                                        <option value={item?.value} key={index}>{item?.value}</option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.zipCode"
                                                                    type="text"
                                                                    placeholder="Zip Code"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="two-col-xs">
                                                        <div className="row-xs">
                                                            <div className="one-col-xs">
                                                                <p>Company Details</p>
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.companyName"
                                                                    type="text"
                                                                    placeholder="Company Name"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.companySuppliment"
                                                                    type="text"
                                                                    placeholder="Company Supplement"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="two-col-xs">
                                                        <div className="row-xs">
                                                            <div className="one-col-xs">
                                                                <p>Other Details</p>
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="connectionInfo"
                                                                    type="text"
                                                                    placeholder="Connection Info"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.email"
                                                                    type="email"
                                                                    placeholder="Email"
                                                                />
                                                            </div>
                                                            <div className="one-col-xs">
                                                                <Input
                                                                    name="warehouseAddress.phoneNumber"
                                                                    type="text"
                                                                    placeholder="Phone Number"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            null
                                        }
                                        <div className="btn-container w-full relative">
                                            <Button
                                                type="submit"
                                                loading={addingAccDetails}
                                                className="!m-0"
                                            >
                                                Save Account Details
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AdminAccountDetailsAdd;