import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetDocumentsQuery } from '../../../../services/documents';
import { baseUrl } from '../../../../config';
import SimpleTable from '../../../../components/Table/SimpleTable';
import { downloadFile } from '../../../../utils/downloadFile';
import { addZeroInSingleDigit } from '../../../../utils/addZeroInSingleDigit';

function ViewDocuments({ horBannerAd = null, verBannerAd = null, hasSideTable = false }) {
    const { t } = useTranslation();
    const authToken = useSelector((state) => state.clientAuth.authToken);
    const [tableData, setTableData] = useState([]);

    const { data: documentsData, isError: isDocumentsError, isFetching: isFetchingDocuments } = useGetDocumentsQuery();

    const columns = useMemo(
        () => [
            {
                id: 'designation',
                Header: `${t('viewDocumentsPage.designation')}`,
                accessor: 'designation',
                Cell: ({ cell: { value } }) => value || '-',
                canBeFiltered: false,
            },
            {
                id: 'format',
                Header: `${t('viewDocumentsPage.format')}`,
                accessor: 'format',
                Cell: ({ cell: { value } }) => value || '-',
                canBeFiltered: false,
            },
            {
                id: 'lastUpdate',
                Header: `${t('viewDocumentsPage.lastUpdate')}`,
                accessor: 'lastUpdate',
                Cell: ({ cell: { value } }) => value || '-',
                canBeFiltered: false,
            },
            {
                id: 'search',
                Header: `${t('viewDocumentsPage.search')}`,
                accessor: 'search',
                Cell: ({ row }) => (
                    <a
                        className="underline cursor-pointer"
                        onClick={() => {
                            downloadFile(`${baseUrl}/document/download/${row?.original?.designation}`,
                                authToken,
                                `${t('viewDocumentsPage.toastNotifications.success')}`,
                                `${t('viewDocumentsPage.toastNotifications.failure')}`,
                                row?.original?.designation,
                                row?.original?.format,
                            );
                        }}
                    >
                        Download
                    </a>
                ),
                // Id of that column which needs to be filtered.
                filterColumnId: 'designation',
                canBeFiltered: true,
            },
        ],
        [t]
    );

    useEffect(() => {
        if (documentsData) {
            setTableData(documentsData?.payload?.map((item, index) => {
                return {
                    id: index,
                    designation: item?.fileName,
                    format: item?.format,
                    lastUpdate: `${addZeroInSingleDigit(item?.lastUpdateDate[2])}.${addZeroInSingleDigit(item?.lastUpdateDate[1])}.${item?.lastUpdateDate[0]}`,
                };
            }))
        }
    }, [documentsData]);

    return (
        <section>
            <div className='page-view'>
                <div className="row-xs">
                    <div className="rest-of-six-col-xs">
                        <div className="page-ad-space-horizontal">
                            <div className="page-ad">
                                {horBannerAd ?
                                    <img src={horBannerAd} /> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-header">
                    <h1>{t('viewDocumentsPage.formsOrDocuments')}</h1>
                </div>
                <div className="page-content-no-bg">
                    <div className="row-xs">
                        <div className="rest-of-six-col-xs">
                            <SimpleTable
                                columns={columns}
                                data={tableData}
                                isFetchError={isDocumentsError}
                                isLoadingData={isFetchingDocuments}
                            />
                        </div>
                        <div className="six-col-xs">
                            <div className='page-ad-space-vertical'>
                                <div className={`${hasSideTable ? "page-ad page-ad-mt" : "page-ad"}`}>
                                    {verBannerAd ?
                                        <img src={verBannerAd} /> :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ViewDocuments;