import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),

  endpoints: (builder) => ({
    login: builder.query({
      query: (encodedCred) => {
        return {
          url: `/users/login`,
          method: "GET",
          headers: {
            Authorization: `Basic ${encodedCred}`,
          },
        };
      },
    }),

  }),
});

export const { useLazyLoginQuery } = authApi;