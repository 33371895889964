import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetAccountDetailsQuery, useGetDepartmentDetailsQuery } from "../../../services/accountDetails";
import Modal from "../../../components/Modal";
import { modalTypes } from "../../../components/Modal/ModalTypes";
import { failureToast } from "../../../components/Toast";
import LoadingOverlay from "../../../components/LoadingOverlay";
import Button from "../../../components/Button";

function UserAccount() {
    const { t } = useTranslation();
    const clientId = useSelector((state) => state.clientUser.clientId);
    const { data, isError, isFetching, isSuccess } = useGetAccountDetailsQuery(clientId, {
        skip: !clientId,
    });

    const {
        data: departmentData,
        isError: isDepErr,
        isFetching: isFetchingDep,
        isSuccess: isDepSuccess
    } = useGetDepartmentDetailsQuery(clientId, {
        skip: !clientId,
    });

    const [openModal, setOpenModal] = useState(false);
    const [accountDetails, setAccountDetails] = useState({});
    const [allDetails, setAllDetails] = useState({});

    useEffect(() => {
        if (isError) {
            failureToast(`${t('viewUserAccountPage.toastNotifications.failureLoadingAccountDetails')}`);
        }
        if (isSuccess) {
            setAccountDetails(data?.payload?.accountAddress);
            setAllDetails(data?.payload);
        }
        if (isDepErr) {
            failureToast(`${t('viewUserAccountPage.toastNotifications.failureLoadingDepartmentDetails')}`);
        }
    }, [isError, isSuccess, isDepErr]);

    return (
        <section>
            {openModal ? (
                <Modal
                    modalTitle={`${t('viewContactPage.contact')}`}
                    onCancelClick={() => {
                        setOpenModal(false);
                    }}
                    modalType={modalTypes?.type2?.name}
                    hasMoreBtn={false}
                    contactForm2Values={{
                        description: "I would like to request the admin to change my user account data.",
                        preferredFeedback: "",
                        title: "Request for data change"
                    }}
                />
            ) : null}
            <div className="page-view">
                <div className="page-header pt-12">
                    <h1>{t('viewUserAccountPage.userAccount')}</h1>
                </div>
                <div className="page-content-no-bg">
                    {isFetching || isFetchingDep ?
                        <LoadingOverlay heightClass="h-[75vh]" /> :
                        isSuccess ?
                            <>
                                <div className="flex justify-end pr-1">
                                    <Button
                                        variant="secondary"
                                        className="!mr-0"
                                        onClick={() => setOpenModal(true)}
                                    >
                                        {t('viewUserAccountPage.requestChangeOfMasterData')}
                                    </Button>
                                </div>
                                <div className="row-xs">
                                    <div className="two-col-sm">
                                        <div className="page-content-bg h-full">
                                            <h4 className="detail-heading">{t('viewUserAccountPage.baseData.baseData')}</h4>
                                            <div className="detail-wrapper pt-6">
                                                <div className="single-detail">
                                                    <h5 className="single-detail-heading">
                                                        {t('viewUserAccountPage.baseData.nameOfCompany1')}
                                                    </h5>
                                                    <p className="single-detail-desc">
                                                        {accountDetails?.name1 || "-"}
                                                    </p>
                                                </div>
                                                <div className="single-detail">
                                                    <h5 className="single-detail-heading">
                                                        {t('viewUserAccountPage.baseData.streetHouseNumber')}
                                                    </h5>
                                                    <p className="single-detail-desc">
                                                        {accountDetails?.streetName || "-"}, {accountDetails?.houseNumber || "-"}
                                                    </p>
                                                </div>
                                                <div className="single-detail">
                                                    <h5 className="single-detail-heading">
                                                        {t('viewUserAccountPage.baseData.countryCodeZipLocation')}
                                                    </h5>
                                                    <p className="single-detail-desc">
                                                        {accountDetails?.countryCode || "-"}, {accountDetails?.zipCode || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">
                                                        {t('viewUserAccountPage.baseData.phone')}
                                                    </h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {accountDetails?.phoneNumber || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.email')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {accountDetails?.email || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.taxId')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.taxId || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.taxNumber')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.taxNumber || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.commercialRegister')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.commericalRegister || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.placeOfJurisdiction')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.jurisdiction || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.bankName')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.bankName || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.iban')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.iban || "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.baseData.bic')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.bic || "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="two-col-sm ">
                                        {isDepSuccess ?
                                            (departmentData?.payload?.length ?
                                                <>
                                                    <div className="page-content-bg mb-3">
                                                        <h4 className="pb-4">{t('viewUserAccountPage.invoice.invoice')}</h4>
                                                        <div className="detail-wrapper">
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.invoice.contactPerson')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "INVOICE")?.contactPerson || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.invoice.email')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "INVOICE")?.email || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.invoice.phone')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "INVOICE")?.phoneNumber || "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="page-content-bg mb-3">
                                                        <h4 className="pb-4">{t('viewUserAccountPage.completion.completion')}</h4>
                                                        <div className="detail-wrapper">
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.completion.contactPerson')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "COMPLETION")?.contactPerson || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.completion.email')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "COMPLETION")?.email || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.completion.phone')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "COMPLETION")?.phoneNumber || "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="page-content-bg !mb-0">
                                                        <h4 className="pb-4">{t('viewUserAccountPage.orderConfirmation.orderConfirmation')}</h4>
                                                        <div className="detail-wrapper">
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.orderConfirmation.contactPerson')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "ORDER_CONFIRMATION")?.contactPerson || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.orderConfirmation.email')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "ORDER_CONFIRMATION")?.email || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.orderConfirmation.phone')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "ORDER_CONFIRMATION")?.phoneNumber || "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                null
                                            ) :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className="row-xs">
                                    <div className="two-col-sm">
                                        <div className="page-content-bg h-full">
                                            <h4 className="detail-heading">{t('viewUserAccountPage.furtherMasterData.furtherMasterData')}</h4>
                                            <div className="detail-wrapper pt-6">
                                                <div className="single-detail">
                                                    <h5 className="single-detail-heading">
                                                        {t('viewUserAccountPage.furtherMasterData.dropshippingOrWarehousing')}
                                                    </h5>
                                                    <p className="single-detail-desc">
                                                        {allDetails?.warehouseOrDopshiping || "-"}
                                                    </p>
                                                </div>
                                                <div className="single-detail">
                                                    <h5 className="single-detail-heading">
                                                        {t('viewUserAccountPage.furtherMasterData.warehouseAddress')}
                                                    </h5>
                                                    <div className="single-detail-desc">
                                                        <p>
                                                            {allDetails?.warehouseAddress?.name1 || "-"}
                                                            {allDetails?.warehouseAddress?.name2 ? "/" + allDetails?.warehouseAddress?.name2 : "-"}
                                                        </p>
                                                        <p>{allDetails?.warehouseAddress?.streetName || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.addressSupplement || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.houseNumber || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.city || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.zipCode || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.countryCode || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.phoneNumber || "-"}</p>
                                                        <p>{allDetails?.warehouseAddress?.email || "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="single-detail">
                                                    <h5 className="single-detail-heading">
                                                        {t('viewUserAccountPage.furtherMasterData.itConnection')}
                                                    </h5>
                                                    <div className="single-detail-desc">
                                                        <p>{allDetails?.connectionInfo || "-"}</p>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">
                                                        {t('viewUserAccountPage.furtherMasterData.date')}
                                                    </h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.date?.length ?
                                                            `${allDetails?.date[2] || "-"}.${allDetails?.date[1] || "-"}.${allDetails?.date[0] || "-"}` :
                                                            "-"}
                                                    </p>
                                                </div>
                                                <div className="flex">
                                                    <h5 className="w-1/2 pl-24">{t('viewUserAccountPage.furtherMasterData.completedBy')}</h5>
                                                    <p className="w-1/2 mb-1 pl-3">
                                                        {allDetails?.createdBy || "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="two-col-sm ">
                                        {isDepSuccess ?
                                            (departmentData?.payload?.length ?
                                                <>
                                                    <div className="page-content-bg mb-3">
                                                        <h4 className="pb-4">{t('viewUserAccountPage.it.it')}</h4>
                                                        <div className="detail-wrapper">
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.it.contactPerson')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "IT")?.contactPerson || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.it.email')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "IT")?.email || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.it.phone')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "IT")?.phoneNumber || "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="page-content-bg mb-3">
                                                        <h4 className="pb-4">{t('viewUserAccountPage.deliveryNote.deliveryNote')}</h4>
                                                        <div className="detail-wrapper">
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.deliveryNote.contactPerson')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "DELIVERY_NOTE")?.contactPerson || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.deliveryNote.email')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "DELIVERY_NOTE")?.email || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.deliveryNote.phone')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "DELIVERY_NOTE")?.phoneNumber || "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="page-content-bg !mb-0">
                                                        <h4 className="pb-4">{t('viewUserAccountPage.complaintOrReturns.complaintOrReturns')}</h4>
                                                        <div className="detail-wrapper">
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.complaintOrReturns.contactPerson')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "COMPLAIN_RETURN")?.contactPerson || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.complaintOrReturns.email')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "COMPLAIN_RETURN")?.email || "-"}
                                                                </p>
                                                            </div>
                                                            <div className="single-detail">
                                                                <h5 className="single-detail-heading">
                                                                    {t('viewUserAccountPage.complaintOrReturns.phone')}
                                                                </h5>
                                                                <p className="single-detail-desc">
                                                                    {departmentData?.payload?.find(item => item?.departmentType === "COMPLAIN_RETURN")?.phoneNumber || "-"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> :
                                                null
                                            ) :
                                            null
                                        }
                                    </div>
                                </div>
                            </> :
                            null
                    }
                </div>
            </div>
        </section>
    );
}

export default UserAccount;