import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../../config";

export const documentByAdminApi = createApi({
    reducerPath: "documentByAdminApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().adminAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['AdminDocuments'],

    endpoints: (builder) => ({
        addDocument: builder.mutation({
            query: ({ description, fileName, fileFormData }) => {
                return {
                    url: `/document/upload?description=${description}&fileName=${fileName}`,
                    method: 'POST',
                    body: fileFormData,
                };
            },
            invalidatesTags: ['AdminDocuments'],
        }),

        getDocuments: builder.query({
            query: () => {
                return {
                    url: `/document/all`,
                    method: "GET",
                };
            },
            providesTags: ['AdminDocuments'],
        }),

        deleteDocument: builder.mutation({
            query: ({ fileName }) => {
                return {
                    url: `/document/${fileName}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['AdminDocuments'],
        }),

    }),
});

export const {
    useAddDocumentMutation,
    useGetDocumentsQuery,
    useDeleteDocumentMutation
} = documentByAdminApi;