import { useState, useEffect, useRef, useMemo } from "react";
import { Formik, Form, } from "formik";
import { adminAddDepartmentSchema } from "../../../../validations/admin/department/addDepartment";
import { useAddDepartmentMutation } from "../../../../services/admin/department";
import { useGetUsersQuery } from "../../../../services/admin/users";
import SimpleTable from "../../../../components/Table/SimpleTable";
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import { successToast, failureToast } from "../../../../components/Toast";

function AdminDepartmentAdd() {
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [tableData, setTableData] = useState([]);

    const formikRef = useRef(null);

    // Get Users Api
    const { data: usersData, isError: isUsersError, isFetching: isFetchingUsers } = useGetUsersQuery();

    const [addDepartment, {
        isLoading: addingDepartment,
        isError: isDepartmentAddError,
        isSuccess: isDepartmentAddSuccess
    }] = useAddDepartmentMutation();

    const [initialValues, setInitialValues] = useState({
        userId: "",
        contactPerson: "",
        departmentType: "",
        email: "",
        phoneNumber: "",
    });

    useEffect(() => {
        if (isDepartmentAddError)
            failureToast("Failed to add department");
        if (isDepartmentAddSuccess) {
            successToast("Successfully added department");
            formikRef?.current?.resetForm();
        }
    }, [isDepartmentAddError, isDepartmentAddSuccess]);

    const handleSubmit = (values) => {
        addDepartment({ id: selectedUserId, data: values });
    };

    // Table Logic
    const columns = useMemo(
        () => [
            {
                id: "id",
                Header: "User ID",
                accessor: "id",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: 'update',
                Header: "Update",
                accessor: 'update',
                Cell: ({ row }) => (
                    <Button
                        variant="white"
                        onClick={() => {
                            setSelectedUserId(row?.original?.id);
                            setInitialValues({
                                userId: row?.original?.id,
                                contactPerson: "",
                                departmentType: "",
                                email: "",
                                phoneNumber: "",
                            })
                        }}
                    >
                        Add Department
                    </Button>
                ),
                // Id of that column which needs to be filtered.
                filterColumnId: 'name',
                canBeFiltered: true,
                filterPlaceholder: "Search Name",
            },
        ],
        []
    );

    useEffect(() => {
        if (usersData) {
            setTableData(usersData?.payload?.map((data) => {
                return {
                    id: data?.id,
                    name: data?.name,
                    email: data?.email,
                    role: data?.role,
                    status: data?.active,
                }
            }));
        }
    }, [usersData]);

    return (
        <div className="row-xs">
            <div className="rest-of-three-col-xs">
                <SimpleTable
                    columns={columns}
                    data={tableData}
                    isFetchError={isUsersError}
                    isLoadingData={isFetchingUsers}
                    loadingSvgFixed={false}
                />
            </div>
            <div className="three-col-xs">
                <div className="form-container">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={adminAddDepartmentSchema}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                        innerRef={formikRef}
                        enableReinitialize={true}
                    >
                        {() => (
                            <Form>
                                <div className="flex justify-center">
                                    <div className="row-xs w-[25vw]">
                                        <div className="one-col-xs text-center">
                                            <h3>Save Department</h3>
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="userId"
                                                type="text"
                                                placeholder="User Id"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="contactPerson"
                                                type="text"
                                                placeholder="Contact Person"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <Select name="departmentType">
                                                <option value="" disabled={true} hidden={true}>Choose Department Type</option>
                                                <option value="INVOICE">Invoice</option>
                                                <option value="COMPLETION">Completion</option>
                                                <option value="ORDER_CONFIRMATION">Order Confirmation</option>
                                                <option value="IT">IT</option>
                                                <option value="DELIVERY_NOTE">Delivery Note</option>
                                                <option value="COMPLAIN_RETURN">Complain Return</option>
                                            </Select>
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                            />
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="phoneNumber"
                                                type="text"
                                                placeholder="Phone Number"
                                            />
                                        </div>
                                        <div className="btn-container w-full relative">
                                            <Button
                                                type="submit"
                                                loading={addingDepartment}
                                                className="!m-0"
                                            >
                                                Save Department
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AdminDepartmentAdd;