// import Link from 'next/link';
import { /* useState, */ useEffect, /* useRef, */ /* forwardRef */ } from 'react';
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useRowSelect,
    useExpanded
} from "react-table";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import CustomPagination from "../../components/Pagination";
import { DefaultColumnFilter } from "./Filter";

function Table({
    columns,
    data,
    fetchData,
    isFetchError,
    isLoadingData,
    pageCount: controlledPageCount,
    defaultPageSize,
    totalData,
    rowOptions,
    viewRoute,
    // viewRouteQuery,
    editRoute,
    deleteQuery,
    isDeleting,
    isDeleteError,
    deletedError,
    isDeleteSuccess,
    // If you want to show delete btn for only some userRoles, then pass allowDeleteFor prop and assign it that user role. Else, don't pass allowDeleteFor prop.
    // allowDeleteFor = 'all',
    // conditionalDelete,
    // For Revert Users
    revertQuery,
    isReverting,
    isRevertError,
    revertError,
    isRevertSuccess,
    afterRevertRoute,
    // Look at the comment above allowDeleteFor. Same applies here
    // allowRevertFor = 'all',
    //
    hasExport,
    addPage,
    tableNote = "",
    hasPagination = true,
    hasSorting = true,
    isBorderlessTable = false,
    noTableData = false,
    sumTotal = null,
    hasArrowHint = false,
    hintMessage = "",
    hasEllipis = false,
    tableName = "item/s",
    // customBtns
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // canPreviousPage,
        // canNextPage,

        // Might be imp for pagination
        // pageCount,

        gotoPage,
        // nextPage,
        // previousPage,
        // setPageSize,
        // selectedFlatRows,
        state: { pageIndex, /* pageSize, */ sortBy, filters, /* expanded */ },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: defaultPageSize },
            manualPagination: true,
            manualSortBy: true,
            manualFilters: true,
            autoResetSortBy: false,
            defaultColumn: { Filter: DefaultColumnFilter },
            pageCount: controlledPageCount,
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );
    const { t } = useTranslation();

    // useEffect(() => {
    //     // sortBy looks like: [{id: 'vehicleName', desc: false}]
    //     fetchData({ pageIndex, pageSize, sortBy, /* filters: debounceFilter */ filters });
    // }, [fetchData, pageIndex, pageSize, sortBy, /* debounceFilter */]);

    useEffect(() => {
        // sortBy looks like: [{id: 'vehicleName', desc: false}]        
        if (fetchData)
        // console.log('...........jhdjkjdatafetch',fetchData)
            fetchData({ filters, sortBy, pageIndex });
    }, [fetchData, filters, sortBy, pageIndex]);

    const handlePageChange = (pNo) => {
        // Page Index works like 0, 1, 2.
        gotoPage(pNo);
    };

    return (
        <div className="flex flex-col relative">
            {/* <div className="shadow overflow-auto w-full border-b border-gray-200 rounded-none h-[calc(100vh-200px)] min-h-[400px]"> */}
            <div
                className={`${isBorderlessTable ? "" : `table-wrapper shadow border-b border-gray-200 min-h-[calc(100vh-350px)]`
                    } overflow-auto w-full rounded-none max-h-[calc(100vh-70px)]`}
            >
                <div>
                    <table {...getTableProps()} className="min-w-full relative">
                        <div
                            className={
                                isLoadingData
                                    ? "absolute w-full bg-white py-3 opacity-90 flex flex-col justify-center items-center top-[85px] bottom-0 left-0 right-0"
                                    : "hidden"
                            }
                        >
                            <div className="flex flex-wrap justify-center fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 ">
                                <div className="w-full flex justify-center">
                                    <svg
                                        className={`animate-spin h-10 w-10 text-primary`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                                {/* <div className="text-center pt-2 text-textColor">
                                    <h4 className="h4">Loading...</h4>
                                    <p className="mt-2">
                                        Please wait. Data is loading.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        <thead>
                            {headerGroups.map((headerGroup) => {
                                const { key, ...restHeaderGroupProps } =
                                    headerGroup.getHeaderGroupProps();
                                return (
                                    <tr
                                        key={key}
                                        {...restHeaderGroupProps}
                                        className="px-0"
                                    >
                                        {headerGroup.headers.map((column) => {
                                            const { key, ...restColumn } =
                                                column.getHeaderProps();
                                            // We are removing the title attribute that byDefault shows 'Toggle SortBy' when we hover over the div with getSortByToggleProps.
                                            const {
                                                title,
                                                ...togglePropsExceptTitle
                                            } = column.getSortByToggleProps();
                                            return (
                                                <th
                                                    key={key}
                                                    {...restColumn}
                                                    scope="col"
                                                    className={`${isBorderlessTable ? "w-[25%]" : ""} pt-0 pb-2 px-1 text-left`}
                                                >
                                                    {
                                                        // Only use getSortByToggleProps when column.canBeSorted is true.
                                                        // Also, sorting is automatically ascending at first and at second descending and at last no-sort then so on. This type of behaviour is given by react-table out of the box.
                                                        column.canBeSorted ? (
                                                            <div
                                                                className="flex justify-around px-1 py-1 bg-white shadow-sm shadow-gray-400"
                                                                {...togglePropsExceptTitle}
                                                            >
                                                                <h5
                                                                    className={
                                                                        column.isBadge
                                                                            ? ""
                                                                            : "font-semibold text-textColor overflow-ellipsis overflow-hidden normal-case"
                                                                    }
                                                                    title={
                                                                        column.Header
                                                                    }
                                                                >
                                                                    {column.render(
                                                                        "Header"
                                                                    )}
                                                                    &nbsp;
                                                                </h5>
                                                                {hasSorting ?
                                                                    <span className='flex items-center'>
                                                                        {column.isSortedDesc ===
                                                                            undefined ? (
                                                                            // No Sort Icon
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 18.738 14.563">
                                                                                <g id="Group_20" data-name="Group 20" transform="translate(-856.781 -268.219)">
                                                                                    <g id="Group_2" data-name="Group 2" transform="translate(194.521 92)">
                                                                                        <line id="Line_6" data-name="Line 6" x1="3.329" y2="4.993" transform="translate(663.3 177.259)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                        <line id="Line_7" data-name="Line 7" x2="3.329" y2="4.993" transform="translate(666.629 177.259)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                        <line id="Line_8" data-name="Line 8" y2="11.651" transform="translate(666.629 178.091)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                    </g>
                                                                                    <g id="Group_3" data-name="Group 3" transform="translate(194.521 92)">
                                                                                        <line id="Line_9" data-name="Line 9" x1="3.329" y2="4.993" transform="translate(679.957 189.741) rotate(180)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                        <line id="Line_10" data-name="Line 10" x2="3.329" y2="4.993" transform="translate(676.629 189.741) rotate(180)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                        <line id="Line_11" data-name="Line 11" y2="11.651" transform="translate(676.629 188.909) rotate(180)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                    </g>
                                                                                </g>
                                                                                <title>Not Sorted</title>
                                                                            </svg>
                                                                        ) : column.isSortedDesc ? (
                                                                            // Descending Icon
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8.738 14.273">
                                                                                <g id="Group_18" data-name="Group 18" transform="translate(-672.26 -176.509)">
                                                                                    <line id="Line_9" data-name="Line 9" x1="3.329" y2="4.993" transform="translate(679.957 189.741) rotate(180)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                    <line id="Line_10" data-name="Line 10" x2="3.329" y2="4.993" transform="translate(676.629 189.741) rotate(180)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                    <line id="Line_11" data-name="Line 11" y2="11.651" transform="translate(676.629 188.909) rotate(180)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                </g>
                                                                                <title>Descending Sorted</title>
                                                                            </svg>
                                                                        ) : (
                                                                            // Ascending Icon
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8.737 14.273">
                                                                                <g id="Group_17" data-name="Group 17" transform="translate(-662.26 -176.219)">
                                                                                    <line id="Line_6" data-name="Line 6" x1="3.329" y2="4.993" transform="translate(663.3 177.259)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                    <line id="Line_7" data-name="Line 7" x2="3.329" y2="4.993" transform="translate(666.629 177.259)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                    <line id="Line_8" data-name="Line 8" y2="11.651" transform="translate(666.629 178.091)" fill="none" stroke="#312828" strokeLinecap="round" strokeWidth="1.5" />
                                                                                </g>
                                                                                <title>Ascending Sorted</title>
                                                                            </svg>
                                                                        )}
                                                                    </span> :
                                                                    null
                                                                }
                                                            </div>
                                                        ) : !column.canBeSorted &&
                                                            column.canBeFiltered ? null : (
                                                            <div
                                                                className="flex justify-center px-1 py-1 bg-white shadow-sm shadow-gray-400"
                                                            // {...togglePropsExceptTitle}
                                                            >
                                                                <h5
                                                                    className="font-semibold text-textColor overflow-ellipsis overflow-hidden"
                                                                    title={
                                                                        column.Header
                                                                    }
                                                                >
                                                                    {column.render(
                                                                        "Header"
                                                                    )}
                                                                    &nbsp;
                                                                </h5>
                                                            </div>
                                                        )
                                                    }
                                                    {column.canBeFiltered ? (
                                                        <div
                                                            className="px-1 bg-white shadow-sm shadow-gray-400 relative"
                                                        // {...togglePropsExceptTitle}
                                                        >
                                                            {column.render(
                                                                "Filter"
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody
                            {...getTableBodyProps()}
                            className={`bg-white h5-m ${isBorderlessTable ?
                                "" :
                                `${data?.length ? "shadow-sm shadow-gray-300" : ""}`
                                }`}
                        >
                            {isFetchError ? (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className="px-6 py-4 whitespace-nowrap"
                                    >
                                        Error loading data!!!
                                    </td>
                                </tr>
                            ) : /* To show no data when loading instead of showing previous data. */
                                isLoadingData ? (
                                    <tr></tr>
                                ) : data && data.length ? (
                                    <>
                                        {page.map((row) => {
                                            prepareRow(row);
                                            const { key, ...restRowProps } =
                                                row.getRowProps();
                                            return (
                                                <tr
                                                    key={key}
                                                    {...restRowProps}
                                                    // CSS for row expannsion
                                                    className={`${(row?.canExpand && row?.isExpanded) || isBorderlessTable ?
                                                        "" :
                                                        // If isLastRow property that we passed in subRow is true
                                                        row?.original?.isLastRow ?
                                                            "border-b border-gray-300" :
                                                            // Check for rows that cannot be expanded (i.e. Normal row) and add border bottom to them
                                                            row?.id?.split(".")?.length > 1 ?
                                                                "" :
                                                                "border-b border-gray-300"
                                                        }`}
                                                >
                                                    {row.cells.map((cell) => {
                                                        const {
                                                            key,
                                                            ...restCellProps
                                                        } = cell.getCellProps();
                                                        return (
                                                            <td
                                                                key={key}
                                                                {...restCellProps}
                                                                className={`text-center whitespace-nowrap ${isBorderlessTable
                                                                    ? "py-1"
                                                                    // : "py-2 border-b border-gray-300"
                                                                    : "py-2"
                                                                    } ${hasEllipis ? "max-w-[150px]" : ""}`}
                                                                title={cell.value}
                                                            >
                                                                {hasEllipis ?
                                                                    <span className='inline-block whitespace-nowrap	text-ellipsis overflow-hidden max-w-[75%]'>
                                                                        {cell.render(
                                                                            "Cell"
                                                                        )}
                                                                    </span> :
                                                                    <>
                                                                        {
                                                                            cell.render(
                                                                                "Cell"
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                        <>
                                            {sumTotal ?
                                                Object.keys(sumTotal).length ?
                                                    <tr>
                                                        {Object.values(sumTotal).map((item, index) => {
                                                            return (
                                                                <td
                                                                    key={index}
                                                                    className={`text-center whitespace-nowrap ${item?.className} ${isBorderlessTable
                                                                        ? "py-1"
                                                                        : "py-2 border-b border-gray-300"
                                                                        }`}
                                                                    title={item?.value}
                                                                >
                                                                    {item?.value}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr> :
                                                    null :
                                                null
                                            }
                                        </>
                                    </>
                                ) : (
                                    <tr className={hasArrowHint ? "relative" : ""}>
                                        {hasArrowHint ?
                                            <div>
                                                <h3 className='w-[180px] normal-case text-center absolute top-[52px] right-[85px]'>{hintMessage}</h3>
                                                <img src={process.env.PUBLIC_URL + "/images/hint-arrow.png"} className="absolute top-[14px] right-[20px] w-[70px]" />
                                            </div> :
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan={columns.length}>
                                                {noTableData ? "" : t('noDataFoundInTable')}
                                            </td>
                                        }
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
            {tableNote.length > 0 && (
                <div className="flex justify-end">
                    <p className="text-[10px] text-right w-1/3">
                        {/* * Stocks may change due to regular reconciliations. */}
                        {tableNote}
                    </p>
                </div>
            )}
            <div className="flex justify-center">
                {hasPagination && (
                    <div className="custom-pagination flex justify-center items-center pt-4">
                         <CustomPagination
                            pageIndex={pageIndex}
                            handlePageChange={handlePageChange}
                            pageSize={defaultPageSize} 
                            totalData={totalData}
                        />
                        
                    </div>
                )}
            </div>
        </div>
    );
}

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchData: PropTypes.func.isRequired,
    isFetchError: PropTypes.bool.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    defaultPageSize: PropTypes.number.isRequired,
    totalData: PropTypes.number.isRequired,
    rowOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    viewRoute: PropTypes.string,
    editRoute: PropTypes.string,
    deleteQuery: PropTypes.func,
    isDeleting: PropTypes.bool,
    isDeleteError: PropTypes.bool,
    deletedError: PropTypes.string,
    isDeleteSuccess: PropTypes.bool,
    revertQuery: PropTypes.func,
    isReverting: PropTypes.bool,
    isRevertError: PropTypes.bool,
    revertError: PropTypes.string,
    isRevertSuccess: PropTypes.bool,
    afterRevertRoute: PropTypes.string,
    hasExport: PropTypes.bool,
    addPage: PropTypes.shape({
        page: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
    }),
    tableNote: PropTypes.string,
    hasPagination: PropTypes.bool,
    tableName: PropTypes.string,
};

export default Table;