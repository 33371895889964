import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../../config";

export const departmentByAdminApi = createApi({
    reducerPath: "departmentByAdminApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().adminAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),

    endpoints: (builder) => ({
        addDepartment: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/account/${id}/department`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const { useAddDepartmentMutation } = departmentByAdminApi;