import { createSlice } from '@reduxjs/toolkit';
import { setClientId } from '../client/clientUserSlice';
import { setAdminId } from '../admin/adminUserSlice';


const initialState = {
  clientId: null,
  adminId: null,
  cartItems: [],
};

export const orderCartSlice = createSlice({
  name: 'orderCart',
  initialState,
  reducers: {
    setOrderCartItems: (state, action) => {
      state.cartItems = action.payload;
      setCartItemsToStorage(state.clientId || state.adminId, action.payload);
    },

    changeQtyOrderCartItems: (state, action) => {
      const { id, value } = action.payload;
      state.cartItems = state.cartItems.map((item) =>
        item?.ean === id ? { ...item, quantity: value } : item
      );
      setCartItemsToStorage(state.clientId || state.adminId, state.cartItems);
    },

    removeOrderCartItems: (state) => {
      state.cartItems = [];
      removeCartItemsFromStorage(state.clientId || state.adminId);
    },

    removeSelectedCartItem: (state, action) => {
      const selectedItemId = action.payload;
      state.cartItems = state.cartItems.filter((item) => item?.ean !== selectedItemId);
      setCartItemsToStorage(state.clientId || state.adminId, state.cartItems);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setClientId, (state, action) => {
        state.clientId = action.payload;
        state.cartItems = getCartItemsFromStorage(action.payload);
    })
    .addCase(setAdminId, (state, action) => {
      state.adminId = action.payload;
      state.cartItems = getCartItemsFromStorage(state.adminId);
    });
    ;
  },
  
});

export const {
  setOrderCartItems,
  changeQtyOrderCartItems,
  removeOrderCartItems,
  removeSelectedCartItem,
} = orderCartSlice.actions;

export default orderCartSlice.reducer;

export const getCartItemsFromStorage = (id) => {
  const cartKey = `cartItems_${id}`;
  const cartItems = localStorage.getItem(cartKey);
  const expiration = localStorage.getItem(`${cartKey}_expiration`);

  if (cartItems && expiration) {
    const currentTime = new Date().getTime();
    if (currentTime < expiration) {
      return JSON.parse(cartItems);
    } else {
      removeCartItemsFromStorage(id);
    }
  }

  return [];
}

export const setCartItemsToStorage = (id, cartItems) => {
  const cartKey = `cartItems_${id}`;
  localStorage.setItem(cartKey, JSON.stringify(cartItems));

  if (cartItems.length > 0) {
    const expiration = new Date().getTime() +  24 * 60 * 60 * 1000; 
    localStorage.setItem(`${cartKey}_expiration`, expiration.toString());
    setTimeout(() => {
      removeCartItemsFromStorage(id);
    },  24 * 60 * 60 * 1000);
  } else {
    localStorage.removeItem(`${cartKey}_expiration`);
  }
  
}

export const removeCartItemsFromStorage = (id) => {
  const cartKey = `cartItems_${id}`;
  localStorage.removeItem(cartKey);
  localStorage.removeItem(`${cartKey}_expiration`);
  window.location.reload();
  
}