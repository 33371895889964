import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";

export const DefaultColumnFilter = ({
  column: { setFilter, setShowTableHint },
  placeholder = "",
}) => {
  const { t } = useTranslation();
  const [inputVal, setInputVal] = useState("");

  const handleKeyUp = (e) => {
    setShowTableHint && setShowTableHint(false);
    if (e?.key === "Enter") setFilter(inputVal || undefined);
  };

  return (
    <div className="relative cursor-default">
      <span
        className="absolute top-[4px] left-[4px] cursor-pointer hover:text-primary"
        title="Search"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
          onClick={() => setFilter(inputVal || undefined)} // Set undefined to remove the filter entirely
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </span>
      <input
        // value={filterValue || ""}
        onChange={(e) => {
          setInputVal(e?.target?.value);
        }}
        onKeyUp={handleKeyUp}
        className="table-input !pl-6"
        placeholder={placeholder || `${t("search")}`}
      />
      {/* {filterValue && (
                <span title="Clear">
                    <XIcon
                        className="h-5 w-5 absolute top-3 right-0 hover:text-primary"
                        onClick={() => {
                            setFilter('');
                        }}
                    />
                </span>
            )} */}
    </div>
  );
};

export const DateColumnFilter = ({
  column: { filterValue, setFilter, Header },
}) => {
  const { t } = useTranslation();
  const datepickerRef = useRef(null);
  const validDate = filterValue ? new Date(filterValue) : "";
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div
      className="flex justify-around pl-1 pr-5 py-1 bg-white cursor-pointer custom-date-input"
      onClick={onClick}
      ref={ref}
    >
      <h5
        className="font-semibold text-textColor overflow-ellipsis overflow-hidden"
        title={Header}
      >
        {Header}
      </h5>
    </div>
  ));
  DatePickerInput.displayName = "DatePickerInput";

  return (
    <>
      <DatePicker
        className="border-none px-2 py-1 mt-2 text-sm rounded-sm"
        customInput={<DatePickerInput />}
        selected={validDate}
        value={filterValue || ""}
        onChange={(date) => {
          let searchedDate;
          if (date) {
            const d = new Date(date);
            const dateString =
              d.getFullYear() +
              "-" +
              ("0" + (d.getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + d.getDate()).slice(-2);
            searchedDate = dateString;
          } else {
            searchedDate = null;
          }
          setFilter(searchedDate);
        }}
        dateFormat="yyyy/MM/dd"
        placeholderText="yyyy/mm/dd"
        maxDate={new Date()}
        // Only show clearable when filterValue exists
        isClearable={filterValue}
        calendarStartDay={1}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        ref={datepickerRef}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="react-datepicker__current-month">
            <svg
              className="cursor-pointer"
              onClick={prevMonthButtonDisabled ? null : decreaseMonth}
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10 12"
            >
              <g
                id="Polygon_17"
                data-name="Polygon 17"
                transform="translate(0 12) rotate(-90)"
                fill="none"
              >
                <path d="M6,0l6,10H0Z" stroke="none" />
                <path
                  d="M 6 1.943650245666504 L 1.766189575195312 9 L 10.23381042480469 9 L 6 1.943650245666504 M 6 0 L 12 10 L 0 10 L 6 0 Z"
                  stroke="none"
                  fill={prevMonthButtonDisabled ? "#ccc" : "#312828"}
                />
              </g>
            </svg>
            <div>
              <span className="mr-3">{months[date?.getMonth()]}</span>
              <span>{date?.getFullYear()}</span>
            </div>
            <svg
              className="cursor-pointer"
              onClick={nextMonthButtonDisabled ? null : increaseMonth}
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10 12"
            >
              <g
                id="Polygon_16"
                data-name="Polygon 16"
                transform="translate(10) rotate(90)"
                fill="none"
              >
                <path d="M6,0l6,10H0Z" stroke="none" />
                <path
                  d="M 6 1.943650245666504 L 1.766189575195312 9 L 10.23381042480469 9 L 6 1.943650245666504 M 6 0 L 12 10 L 0 10 L 6 0 Z"
                  stroke="none"
                  fill={nextMonthButtonDisabled ? "#ccc" : "#312828"}
                />
              </g>
            </svg>
          </div>
        )}
      >
        <div className="text-center pb-2">
          <span
            className="text-[10px] font-normal cursor-pointer hover:text-primary"
            onClick={() => datepickerRef?.current?.setOpen(false)}
          >
            {t("modal.conclude")}
          </span>
        </div>
      </DatePicker>
    </>
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, possibleFilters },
}) => {
  return (
    <div className="table-select-wrapper">
      <select
        onChange={(e) => {
          const val = e.target.value;
          setFilter(val);
        }}
        className="table-select py-1"
      >
        {possibleFilters.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const SelectColumnFilterWithDefaultValue = ({
  column: { filterValue, setFilter, possibleFilters },
}) => {
  // We're not actually using default value here. We just removed the "All" option here.
  // To follow the correct way to putting default values in select, see in react docs.
  return (
    <select
      onChange={(e) => {
        const val = e.target.value;
        setFilter(val);
      }}
      className="border-none px-2 py-1 mt-2 text-sm rounded-sm"
    >
      {possibleFilters.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
