import { useState, /* useEffect */ } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { /* useSelector, */ useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    navToAdminHome,
    navToAdminUserAdd,
    navToAdminLogin,
    navToAdminNewsfeedAdd,
    navToAdminDocumentAdd,
    navToAdminUserDefaultAddressAdd,
    navToAdminAccountDetailAdd,
    navToAdminDepartmentAdd,
    navToAdminDocumentView
} from "../../navigations";
import Modal from "../Modal";
import { modalTypes } from "../Modal/ModalTypes";
import { removeAdminToken } from "../../features/auth/adminAuthSlice";
import { removeOrderCartItems } from "../../features/orderCart/orderCartSlice";
import { removeOrderEntry } from "../../features/orderEntry/orderEntrySlice";
import { removeAdminAuthToken } from "../../storage/localStorage";

function AdminSidebar({ open = true }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, /* i18n */ } = useTranslation();

    const [openModal, setOpenModal] = useState(false);
    // const [openDropdown, setOpenDropdown] = useState(false);

    const logout = () => {
        removeAdminAuthToken();
        // On logging out, we dispatch the removeAdminToken state action which will remove the token from both localStorage and global state.
        dispatch(removeAdminToken());
        dispatch(removeOrderCartItems());
        dispatch(removeOrderEntry());
        navigate(`${navToAdminLogin()}`,
            { replace: true }
        );
    };

    return (
        <div className={open ? "sidebar" : "sidebar sidebar-close"}>
            <aside>
                <div className="logo hover:cursor-pointer" onClick={() => navigate(navToAdminHome())}>
                    <h1>Admin</h1>
                </div>
                <nav className="!justify-start">
                    <ul className="nav-items">
                        <li>
                            <NavLink
                                to={navToAdminHome()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminUserAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                User
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminUserDefaultAddressAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                User Default Address
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminAccountDetailAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                User Account Details
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminDepartmentAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                Departments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminNewsfeedAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                Newsfeed
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminDocumentAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                Add Document
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToAdminDocumentView()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                View Documents
                            </NavLink>
                        </li>
                    </ul>
                    <ul className="action-nav-items mt-12">
                        <li>
                            <img src={process.env.PUBLIC_URL + "/images/logout.png"} width="18" height="18" />
                            <a href="#" onClick={() => logout()}>Logout</a>
                        </li>
                    </ul>
                </nav>
            </aside>
            {openModal ? (
                <Modal
                    modalTitle={`${t('viewContactPage.contact')}`}
                    onCancelClick={() => {
                        setOpenModal(false);
                    }}
                    modalType={modalTypes?.type2?.name}
                    hasMoreBtn={false}
                />
            ) : null}
        </div>
    );
}

export default AdminSidebar;