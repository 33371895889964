import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { navToHome } from "../../../navigations";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import PasswordInput from "../../../components/Input/PasswordInput";
import { successToast, failureToast } from "../../../components/Toast";
import { getAuthToken, setAuthToken } from "../../../storage/localStorage";
import { useLazyLoginQuery } from "../../../services/auth";

const initialValues = {
  username: "",
  password: "",
};

function Login() {
  const navigate = useNavigate();
  const [encodedAuth, setEncodedAuth] = useState("");

  const [trigger, { isFetching, isSuccess, isError }] = useLazyLoginQuery();

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      // navigate("/", { replace: true });
      navigate(`${navToHome()}`, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      /* 
            On Success, we set localStorage not global state.
            The ClientLayout wrapper will set global state automatically if local storage exists.
            BUT on logging out, 
            we dispatch the removeClientToken state action which will remove the token from both localStorage and global state.
            */
      successToast("Successfully Logged In");
      setAuthToken(encodedAuth);
      // navigate("/", { replace: true });
      navigate(`${navToHome()}`, { replace: true });
    }
    if (isError) {
      failureToast("Failed to log in");
    }
  }, [isError, isSuccess]);

  const handleLogin = (username, password) => {
    const encodedCred = window.btoa(username + ":" + password);

    trigger(encodedCred);
    setEncodedAuth(encodedCred);
  };

  return (
    <main className="main-view-no-sidebar login-box-wrapper">
      <div className="page-content-bg login-box">
        <h1 className="text-center pb-8">Login</h1>
        <div className="form-container">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              handleLogin(values?.username, values?.password);
            }}
          >
            {({ dirty }) => (
              <Form>
                <div>
                  <Input name="username" type="text" placeholder="Username*" />
                  <PasswordInput
                    name="password"
                    type="password"
                    placeholder="Password*"
                  />
                  <div className="btn-container">
                    <Button
                      loading={isFetching}
                      disabled={!dirty || isFetching}
                      type="submit"
                      className="mr-0 mb-4"
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
}

export default Login;
