import { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useField, useFormikContext } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

function DatePickerInput({
    name,
    required = true,
    className = "",
    fieldErrMessageStyle = 'field-err-message',
    placeholder = 'Enter Date',
    hasYearPicker = false,
    // maxDate,
    // minDate,
    ...props
}) {
    const { t } = useTranslation();
    const datepickerRef = useRef(null);
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return (
        <div>
            <DatePicker
                {...field}
                {...props}
                className={`custom-placeholder ${className}`}
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val) => {
                    if (val && hasYearPicker) {
                        const date = val.getFullYear().toString();
                        setFieldValue(name, date);
                    } else if (val && !hasYearPicker) {
                        setFieldValue(name, val);
                    } else {
                        setFieldValue(name, "");
                    }
                }}
                showYearPicker={hasYearPicker}
                // maxDate={maxDate || new Date()}
                // minDate={minDate || 0}
                // isClearable
                closeOnScroll
                placeholderText={placeholder}
                autoComplete="off"
                calendarStartDay={1}
                formatWeekDay={nameOfDay => nameOfDay.substring(0, 1)}
                ref={datepickerRef}
                renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className='react-datepicker__current-month'>
                        <svg className="cursor-pointer" onClick={prevMonthButtonDisabled ? null : decreaseMonth} xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12">
                            <g id="Polygon_17" data-name="Polygon 17" transform="translate(0 12) rotate(-90)" fill="none">
                                <path d="M6,0l6,10H0Z" stroke="none" />
                                <path d="M 6 1.943650245666504 L 1.766189575195312 9 L 10.23381042480469 9 L 6 1.943650245666504 M 6 0 L 12 10 L 0 10 L 6 0 Z" stroke="none" fill={prevMonthButtonDisabled ? "#ccc" : "#312828"} />
                            </g>
                        </svg>
                        <div>
                            <span className='mr-3'>{months[date?.getMonth()]}</span>
                            <span>{date?.getFullYear()}</span>
                        </div>
                        <svg className="cursor-pointer" onClick={nextMonthButtonDisabled ? null : increaseMonth} xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12">
                            <g id="Polygon_16" data-name="Polygon 16" transform="translate(10) rotate(90)" fill="none">
                                <path d="M6,0l6,10H0Z" stroke="none" />
                                <path d="M 6 1.943650245666504 L 1.766189575195312 9 L 10.23381042480469 9 L 6 1.943650245666504 M 6 0 L 12 10 L 0 10 L 6 0 Z" stroke="none" fill={nextMonthButtonDisabled ? "#ccc" : "#312828"} />
                            </g>
                        </svg>
                    </div>
                )}
            >
                <div className='text-center pb-2'>
                    <span className='text-[10px] font-normal cursor-pointer hover:text-primary' onClick={() => datepickerRef?.current?.setOpen(false)}>{t('modal.conclude')}</span>
                </div>
            </DatePicker>
            {meta.touched && meta.error ? (
                <div className={fieldErrMessageStyle}>{meta.error}</div>
            ) : null}
        </div>
    );
}

export default DatePickerInput;