import { useState } from "react";
import PropTypes from "prop-types";

const DropdownButton = ({ listItems }) => {
	const [openDropdown, setOpenDropdown] = useState(false);
	return (
		<div className="dd-btn" onClick={() => setOpenDropdown(!openDropdown)}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-5 w-5"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
			</svg>
			<div className={`${openDropdown ? "" : "hidden "}list-container`}>
				<ul>
					{listItems.map((item, index) => {
						return (
							<li key={index}>
								<a href="#">{item}</a>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

DropdownButton.propTypes = {
	listItems: PropTypes.arrayOf(PropTypes.string),
};

export default DropdownButton;