import { useState, useLayoutEffect } from 'react';
// import useIsomorphicLayoutEffect from 'use-isomorphic-layout-effect';

function useWindowWidth() {
    const [winWidth, setWinWidth] = useState(0);

    const handleSize = () => {
        setWinWidth(window.innerWidth);
    };

    useLayoutEffect(() => {
        if (typeof window !== 'undefined') {
            setWinWidth(window.innerWidth);
            window.addEventListener('resize', handleSize);
            return () => window.removeEventListener('resize', handleSize);
        }
    }, []);

    // useIsomorphicLayoutEffect(() => {
    //     if (typeof window !== 'undefined') {
    //         setWinWidth(window.innerWidth);
    //         window.addEventListener('resize', handleSize);
    //         return () => window.removeEventListener('resize', handleSize);
    //     }
    // }, []);

    return winWidth;
}

export default useWindowWidth;
