export const modalEn = {
    conclude: "Conclude",
    removePrompt: "Do you want to remove the selected item from current basket?",
    yes: "Yes",
    no: "No",
    csvModal:{
        dropFile: "Browse your computer to upload file",   //   Drop file here to upload or 
        downloadCsv: "Download CSV",
        downloadCsvLink: "Template",
        uploadFile: "Upload File",
        cancel: "Cancel",
    }
};

export const modalDe = {
    conclude: "schließen",
    removePrompt: "Möchten Sie den ausgewählten Artikel aus dem aktuellen Sammelkorb entfernen?",
    yes: "Ja",
    no: "Nein",
    csvModal: {
        dropFile: "Datei zum Hochladen hier ablegen oder den Computer durchsuchen",
        downloadCsv: "CSV Vorlage",
        downloadCsvLink: "herunterladen",
        uploadFile: "Datei hochladen",
        cancel: "zurück",
    }
};