import PropTypes from 'prop-types';

function InputLabel({
    htmlFor='',
    label,
    className = '',
    required = true,
    noWrap = true,
}) {
    return (
        <label htmlFor={htmlFor} className={`${noWrap ? "whitespace-nowrap" : ""} ${className}`}>
            {label}
            <span className={required ? "required" : ""}>
                <span>{required && "*"}</span>
            </span>
        </label>
    );
}

InputLabel.propTypes = {
    htmlFor: PropTypes.string,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    required: PropTypes.bool,
    noWrap: PropTypes.bool,
};

export default InputLabel;