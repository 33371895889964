import * as Yup from 'yup';
import { phoneNumberValidation, phoneNumberValidationNotReq, } from '../../yupValidations';

export const adminAddUserAccountDetailsSchema = Yup.object().shape({
    userId: "",
    accountAddress: Yup.object().shape({
        name1: Yup.string().required("Required"),
        name2: Yup.string(),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        companyName: Yup.string(),
        companySuppliment: Yup.string(),
        streetName: Yup.string().required("Required"),
        addressSupplement: Yup.string(),
        houseNumber: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        zipCode: Yup.string().required("Required"),
        countryCode: Yup.string().required("Required"),
        phoneNumber: phoneNumberValidation(),
        // email: Yup.string().email(),
    }),
    taxId: Yup.string().required("Required"),
    taxNumber: Yup.string(),
    commericalRegister: Yup.string(),
    jurisdiction: Yup.string(),
    bankName: Yup.string().required("Required"),
    iban: Yup.string().required("Required"),
    bic: Yup.string().required("Required"),
    warehouseOrDopshiping: Yup.string().required("Required"),
    warehouseAddress: Yup.object().when('warehouseOrDopshiping', {
        is: 'warehouse',
        then: Yup.object().shape({
            name1: Yup.string().required("Required"),
            name2: Yup.string(),
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            companyName: Yup.string(),
            companySuppliment: Yup.string(),
            streetName: Yup.string().required("Required"),
            addressSupplement: Yup.string(),
            houseNumber: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            zipCode: Yup.string().required("Required"),
            countryCode: Yup.string().required("Required"),
            phoneNumber: phoneNumberValidationNotReq(),
            email: Yup.string().email(),
        }),
        otherwise: Yup.object().shape({
            name1: Yup.string(),
            name2: Yup.string(),
            firstName: Yup.string(),
            lastName: Yup.string(),
            companyName: Yup.string(),
            companySuppliment: Yup.string(),
            streetName: Yup.string(),
            addressSupplement: Yup.string(),
            houseNumber: Yup.string(),
            city: Yup.string(),
            zipCode: Yup.string(),
            countryCode: Yup.string(),
            phoneNumber: phoneNumberValidationNotReq(),
            email: Yup.string().email(),
        })
    }),
    connectionInfo: Yup.string(),
});