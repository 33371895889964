import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../config";

export const ordersApi = createApi({
    reducerPath: "ordersApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().clientAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['Orders'],

    endpoints: (builder) => ({
        addOrder: builder.mutation({
            query: (data) => {
                return {
                    url: `/orders`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['Orders'],
        }),
        getOrders: builder.query({
            query: (q) => {
                return {
                    url: `/orders/${q}`,
                    method: "GET",
                };
            },
            providesTags: ['Orders'],
        }),
        getOrderDetail: builder.query({
            query: (id) => {
                return {
                    url: `/orders/${id}`,
                    method: "GET",
                };
            },
        }),
        uploadCSV: builder.mutation({
            query: ({ files }) => {
              return {
                url: `/orders/preview`,
                method: "POST",
                body: files,
              };
            },
            invalidatesTags: ['Orders'],
          }),
    }),
});

export const { useAddOrderMutation, useGetOrdersQuery, useGetOrderDetailQuery, useUploadCSVMutation } = ordersApi;