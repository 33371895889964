import React, { useState } from "react";
import { ProgressBar } from "../ProgressBarModal";
import { downloadFile } from "../../utils/downloadFile";
import { useTranslation } from "react-i18next";

const DownloadButtonPopUpModal = ({
  downloadLink,
  authToken,
  successMessage,
  failureMessage,
  showModal,
  setShowModal,
}) => {
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const { t } = useTranslation();

  const resetDownloadState = () => {
    setProgress(0);
    setIsDownloading(false);
    setShowModal(false);
  };

  const startDownload = async () => {
    setIsDownloading(true);

    try {
      await downloadFile(
        downloadLink,
        authToken,
        successMessage,
        failureMessage,
        (downloadProgress) => {
          const progressPercentage = Math.round((downloadProgress.loaded / downloadProgress.total) * 100);
          setProgress(progressPercentage);       
        }
      );

      setProgress(100);

      setTimeout(resetDownloadState, 1000); // Allow 1 second time for modal animation to finish before resetting
    } catch (error) {
      setIsDownloading(false);
      setProgress(0);      
    }
  };

  const handleCancel = (event) => {
    event.stopPropagation(); // Prevent click event propagation

    setIsDownloading(false);
    setProgress(0);
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="fixed z-10 top-4 right-4">
          <div className="relative bg-white rounded-lg shadow-lg w-84 h-24">
            <div className="px-8 py-2">
              <ProgressBar progress={progress} />
              {progress === 0 ? (
                <button
                  className="px-2 py-1 m-3 text-white bg-green-500 rounded-lg text-sm focus:outline-none focus:shadow-outline-green"
                  onClick={startDownload}
                  disabled={isDownloading}
                >
                  {t("DownloadButtonPopUpModal.confirmDownload")}
                </button>
              ) : (
                <button
                  className="px-2 py-1 m-3 text-white bg-gray-500 rounded-lg text-sm focus:outline-none focus:shadow-outline-green cursor-not-allowed"
                  disabled
                >
                  {t("DownloadButtonPopUpModal.confirmDownload")}
                </button>
              )}
              <button
                className={`px-2 py-1 text-gray-700 bg-gray-300 rounded-lg text-sm focus:outline-none focus:shadow-outline-gray ${
                  isDownloading ? "cursor-not-allowed" : ""
                }`}
                onClick={handleCancel}
                disabled={isDownloading}
              >
                {t("DownloadButtonPopUpModal.cancelDownload")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DownloadButtonPopUpModal;
