export const viewPricesPageEn = {
    prices: "Inventory / Prices",
    sku: "SKU",
    ean: "EAN",
    han: "HAN",
    availableStock: "Available Stock",
    minimumOrderQuantity: "Minimum Order Quantity",
    price: "Price",
    search: "Search",
    tableNote: "* Stocks may change due to regular reconciliations.",
    enterOrder: "Enter Order",
    basket: {
        currentBasket: "Current Basket",
        ean: "EAN",
        minStock: "Minimum Order Quantity",
        quantity: "Quantity",
        price: "Price",
        acceptArticle: "Accept article & switch to order entry",
        addMoreItems: "Add more items",
    },
    currentInventory: "Current Inventory",
    currentPriceList: "Current Price List",
    downloadNewPriceList: "Download New Price List",
    searchHint: "Search for EAN/HAN",
};

export const viewPricesPageDe = {
    prices: "Bestand / Preise",
    sku: "SKU",
    ean: "EAN",
    han: "HAN",
    availableStock: "Verfügbarer Bestand",
    minimumOrderQuantity: "Mindestbestellmenge",
    price: "Preis",
    search: "Suche",
    tableNote: "* Bestände können sich aufgrund regelmäßiger Änderungen ändern.",
    enterOrder: "Auftrag erfassen",
    basket: {
        currentBasket: "aktueller Sammelkorb",
        ean: "EAN",
        minStock: "Mindestbestellmenge",
        quantity: "Menge",
        price: "Preis",
        acceptArticle: "Artikel übernehmen & zur Auftragserfassung wechseln",
        addMoreItems: "weitere Artikel hinzufügen",
    },
    currentInventory: "Aktuelle Bestandsliste",
    currentPriceList: "Aktuelle Preisliste",
    downloadNewPriceList: "Neue Preisliste herunterladen",
    searchHint: "Suchen Sie nach EAN/HAN",
};

