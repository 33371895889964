import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    useTable,
    useFilters,
    useGlobalFilter,
} from "react-table";
import PropTypes from "prop-types";
// import { DefaultColumnFilter } from "./SimpleFilter";

// We used setFilter here instead of column: setFilter, as the latter is only used to filter that column which it is associated in.
// The setFilter used here takes columnId as first parameter, so that we can select which column to filter explicitly.
const DefaultColumnFilter = ({ setFilter, column: { filterColumnId, filterPlaceholder } }) => {
    const { t } = useTranslation();
    const [inputVal, setInputVal] = useState("");

    const handleKeyUp = (e) => {
        if (e?.key === "Enter")
            setFilter(filterColumnId, inputVal || undefined);
    };

    return (
        <div className="relative cursor-default">
            <span className="absolute top-[4px] left-[4px] cursor-pointer hover:text-primary" title="Search">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    // Searching only designation columns.
                    onClick={() => setFilter(filterColumnId, inputVal || undefined)} // Set undefined to remove the filter entirely
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                </svg>
            </span>
            <input
                onChange={(e) => {
                    setInputVal(e?.target?.value);
                }}
                onKeyUp={handleKeyUp}
                className="table-input !pl-6"
                placeholder={filterPlaceholder || `${t('search')}`}
            />
        </div>
    );
};

function SimpleTable({
    columns,
    data,
    isFetchError,
    isLoadingData,
    tableNote = "",
    isBorderlessTable = false,
    noTableData = false,
    loadingSvgFixed = true,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
        },
        useFilters, // For Filtering based on only one column
        useGlobalFilter, // For filtering based on more than one columns
    );
    const { t } = useTranslation();

    return (
        <div className="flex flex-col relative">
            <div
                className={`${isBorderlessTable ? "" : `table-wrapper shadow border-b border-gray-200 min-h-[calc(100vh-350px)]`
                    } overflow-auto w-full rounded-none max-h-[calc(100vh-300px)]`}
            >
                <div>
                    <table {...getTableProps()} className="min-w-full relative">
                        <div
                            className={
                                isLoadingData
                                    ? "absolute w-full bg-white py-3 opacity-90 flex flex-col justify-center items-center top-[85px] bottom-0 left-0 right-0"
                                    : "hidden"
                            }
                        >
                            <div className={`flex flex-wrap justify-center ${loadingSvgFixed ? "fixed" : ""} top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 `}>
                                <div className="w-full flex justify-center">
                                    <svg
                                        className={`animate-spin h-10 w-10 text-primary`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <thead>
                            {headerGroups.map((headerGroup) => {
                                const { key, ...restHeaderGroupProps } =
                                    headerGroup.getHeaderGroupProps();
                                return (
                                    <tr
                                        key={key}
                                        {...restHeaderGroupProps}
                                        className="px-0"
                                    >
                                        {headerGroup.headers.map((column) => {
                                            const { key, ...restColumn } =
                                                column.getHeaderProps();
                                            return (
                                                <th
                                                    key={key}
                                                    {...restColumn}
                                                    scope="col"
                                                    className={`${isBorderlessTable ? "w-[33.33%]" : ""} pt-0 pb-2 px-1 text-left`}
                                                >
                                                    {column.canBeFiltered ? (
                                                        <div
                                                            className="px-1 bg-white shadow-sm shadow-gray-400 relative"
                                                        >
                                                            {column.render(
                                                                "Filter"
                                                            )}
                                                        </div>
                                                    ) :
                                                        <div
                                                            className="flex justify-center px-1 py-1 bg-white shadow-sm shadow-gray-400"
                                                        >
                                                            <h5
                                                                className="font-semibold text-textColor overflow-ellipsis overflow-hidden"
                                                                title={
                                                                    column.Header
                                                                }
                                                            >
                                                                {column.render(
                                                                    "Header"
                                                                )}
                                                                &nbsp;
                                                            </h5>
                                                        </div>}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </thead>
                        <tbody
                            {...getTableBodyProps()}
                            className={`bg-white h5-m ${isBorderlessTable ?
                                "" :
                                `${data?.length ? "shadow-sm shadow-gray-300" : ""}`
                                }`}
                        >
                            {isFetchError ? (
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        className="px-6 py-4 whitespace-nowrap"
                                    >
                                        Error loading data!!!
                                    </td>
                                </tr>
                            ) : /* To show no data when loading instead of showing previous data. */
                                isLoadingData ? (
                                    <tr></tr>
                                ) : (data && data.length) && (rows?.length) ? (
                                    <>
                                        {rows.map((row) => {
                                            prepareRow(row);
                                            const { key, ...restRowProps } =
                                                row.getRowProps();
                                            return (
                                                <tr
                                                    key={key}
                                                    {...restRowProps}
                                                    // CSS for row expannsion
                                                    className={`${(row?.canExpand && row?.isExpanded) || isBorderlessTable ?
                                                        "" :
                                                        // If isLastRow property that we passed in subRow is true
                                                        row?.original?.isLastRow ?
                                                            "border-b border-gray-300" :
                                                            // Check for rows that cannot be expanded (i.e. Normal row) and add border bottom to them
                                                            row?.id?.split(".")?.length > 1 ?
                                                                "" :
                                                                "border-b border-gray-300"
                                                        }`}
                                                >
                                                    {row.cells.map((cell) => {
                                                        const {
                                                            key,
                                                            ...restCellProps
                                                        } = cell.getCellProps();
                                                        return (
                                                            <td
                                                                key={key}
                                                                {...restCellProps}
                                                                className={`text-center whitespace-nowrap ${isBorderlessTable
                                                                    ? "py-1"
                                                                    // : "py-2 border-b border-gray-300"
                                                                    : "py-2"
                                                                    }`}
                                                                title={cell.value}
                                                            >
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan={columns.length}>
                                            {noTableData ? "" : t('noDataFoundInTable')}
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
            </div>
            {tableNote.length > 0 && (
                <div className="flex justify-end">
                    <p className="text-[10px] text-right w-1/3">
                        {/* * Stocks may change due to regular reconciliations. */}
                        {tableNote}
                    </p>
                </div>
            )}
        </div>
    );
}

SimpleTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isFetchError: PropTypes.bool.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    tableNote: PropTypes.string,
    hasSorting: PropTypes.bool,
    isBorderlessTable: PropTypes.bool,
    noTableData: PropTypes.bool,
};

export default SimpleTable;