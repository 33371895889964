import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyLoginQuery } from '../../services/auth';
import { getAuthToken } from '../../storage/localStorage';
import { setClientToken, removeClientToken } from '../../features/auth/clientAuthSlice';
import { setClientId } from '../../features/client/clientUserSlice';
import { navToLogin } from '../../navigations';
import Sidebar from '../../components/sidebar';
import useWindowWidth from '../../hooks/useWindowWidth';
import { failureToast } from '../../components/Toast';

function ClientLayout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = useSelector((state) => state.clientAuth.authToken);

    // In this application, we use just the loginApi for validating user..
    const [trigger, { data, isError, isSuccess }] = useLazyLoginQuery();

    const [openSideNav, setOpenSideNav] = useState(true);
    const windowWidth = useWindowWidth();

    useEffect(() => {
        if (windowWidth > 640) {
            setOpenSideNav(true);
        }
    }, [windowWidth]);

    useEffect(() => {
        const token = getAuthToken();
        if (!token) {
            dispatch(removeClientToken());
            navigate(`${navToLogin()}`,
                { replace: true }
            );
        } else {
            dispatch(setClientToken());
        }
    }, []);

    useEffect(() => {
        if (authToken) {
            // Validate User
            trigger(authToken);
        }
    }, [authToken]);

    useEffect(() => {
        if (isError) {
            failureToast('Login Expired. Please login again.');
            navigate(`${navToLogin()}`,
                { replace: true }
            );
            // removing token and user from store and local storage
            dispatch(removeClientToken());
        }
        if (isSuccess) {
            dispatch(setClientId(data?.payload?.id));
        }
    }, [isError, isSuccess]);

    return (
        <>
            {!authToken ? null :
                <section className='layout-with-sidebar'>
                    <Sidebar open={openSideNav} />
                    <main className='main-view'>
                        <div className="container py-4">
                            <div className='absolute right-0 top-0'>
                                <button id="sidenavOpener" className='sidenav-opener' onClick={() => setOpenSideNav(!openSideNav)}>
                                    {openSideNav ?
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
                                        </svg> :
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h7" />
                                        </svg>
                                    }
                                </button>
                            </div>
                            <Outlet />
                        </div>
                    </main>
                </section>
            }
        </>

    )
}

export default ClientLayout;