import PropTypes from 'prop-types';

const Button = ({
    variant = 'primary',
    loading,
    disabled,
    onClick,
    children,
    type = 'button',
    size,
    className,
}) => {
    const getVariantClass = () => {
        let classes = '';
        if (variant === 'primary') {
            classes += ' btn-primary ';
        } else if (variant === 'outlined') {
            classes += ' btn-outline ';
        } else if (variant === 'secondary') {
            classes += 'btn-secondary';
        }
        else if (variant === 'link') {
            classes += 'btn-link';
        }
        else if (variant === 'white') {
            classes += 'btn-white';
        }
        else if (variant === 'gray') {
            classes += 'btn-gray';
        }
        return classes;
    };
    return (
        <button
            className=
            {
                `inline-flex items-center transition ease-in-out duration-150 btn
                ${getVariantClass()}
                ${size === 'sm' ? ' btn-sm ' : ''}
                ${size === 'lg' ? ' btn-lg ' : ''}
                ${loading || disabled ? 'opacity-75 pointer-events-none' : ''}
                ${className}
                `
            }
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {loading && (
                <svg
                    className={`animate-spin -ml-2 mr-2 h-4 w-4 ${variant === 'outlined' || variant === 'white' ? 'text-textColor' : 'text-white'
                        }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            )}
            {children}
        </button>
    );
};

Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'outlined', 'secondary', 'link', 'white']),
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    type: PropTypes.oneOf(['submit', 'button', 'reset']),
    size: PropTypes.oneOf(['default', 'sm', 'lg']),
    className: PropTypes.string,
};

export default Button;
