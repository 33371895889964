import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../../config";

export const userAccountDetailsByAdminApi = createApi({
    reducerPath: "userAccountDetailsByAdminApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().adminAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['AdminUserAccountDetails'],

    endpoints: (builder) => ({
        addUserAccountDetails: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/account/${id}/`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUserAccountDetails'],
        }),

        getUsers: builder.query({
            query: () => {
                return {
                    url: `/users`,
                    method: "GET",
                };
            },
            providesTags: ['AdminUserAccountDetails'],
        }),

        getUserAccountDetails: builder.query({
            query: (id) => {
                return {
                    url: `/account/${id}/`,
                    method: "GET",
                };
            },
            providesTags: ['AdminUserAccountDetails'],
        }),

    }),
});

export const {
    useAddUserAccountDetailsMutation,
    useGetUsersQuery,
    useGetUserAccountDetailsQuery,
} = userAccountDetailsByAdminApi;