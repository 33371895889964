// Client Routes
export const navToHome = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE : "/";
export const navToPrices = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/prices" : "/prices";
export const navToOrderView = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/orders" : "/orders";
export const navToOrderAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/orders/add" : "/orders/add";
export const navToDocuments = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/documents" : "/documents";
export const navToUserAccount = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/user-account" : "/user-account";
export const navToLogin = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/login" : "/login";

// Admin Routes
export const navToAdminHome = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin" : "/admin";
export const navToAdminLogin = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/login" : "/admin/login";
export const navToAdminUserAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/user/add" : "/admin/user/add";
export const navToAdminNewsfeedAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/newsfeed/add" : "/admin/newsfeed/add";
export const navToAdminDocumentAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/document/add" : "/admin/document/add";
export const navToAdminDocumentView = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/document/view" : "/admin/document/view";
export const navToAdminUserDefaultAddressAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/user/defaultAddress/add" : "/admin/user/defaultAddress/add";
export const navToAdminAccountDetailAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/account/detail/add" : "/admin/account/detail/add";
export const navToAdminDepartmentAdd = () => process.env.REACT_APP_STAGING_ROUTE ? "/" + process.env.REACT_APP_STAGING_ROUTE + "/admin/department/add" : "/admin/department/add";