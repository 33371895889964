import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderType: "standardDelivery",
    orderNumber: "",
    deliveryAddress: {
        companyName: "",
        firstName: "",
        lastName: "",
        streetName: "",
        addressSupplement: "",
        houseNumber: "",
        city: "",
        zipCode: "",
        countryCode: "",
        phoneNumber: "",
        email: ""
    },
}

export const orderEntrySlice = createSlice({
    name: 'orderEntry',
    initialState,
    reducers: {
        setOrderType: (state, action) => {
            state.orderType = action.payload;
        },
        setOrderNumber: (state, action) => {
            state.orderNumber = action.payload;
        },
        setDeliveryAddress: (state, action) => {
            state.deliveryAddress = action.payload;
        },
        removeOrderEntry: (state) => {
            state.orderType = initialState.orderType;
            state.orderNumber = "";
            state.deliveryAddress = {
                companyName: "",
                firstName: "",
                lastName: "",
                streetName: "",
                addressSupplement: "",
                houseNumber: "",
                city: "",
                zipCode: "",
                countryCode: "",
                phoneNumber: "",
                email: ""
            };
        },
    },
})

export const {
    setOrderType,
    setOrderNumber,
    setDeliveryAddress,
    removeOrderEntry,
} = orderEntrySlice.actions;

export default orderEntrySlice.reducer;