import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form, } from "formik";
import countryList from "react-select-country-list";
import { useGetAccountDetailsQuery } from "../../../../../services/accountDetails";

import { addOrdersSchema } from "../../../../../validations/orders";
import { setOrderType, setOrderNumber, setDeliveryAddress } from "../../../../../features/orderEntry/orderEntrySlice";
import Input from "../../../../../components/Input";
import InputLabel from "../../../../../components/Input/Label";
import Select from "../../../../../components/Select";
import Button from "../../../../../components/Button";

function Step2({
    changeStep,
    payload,
    handlePayload,
    // addressType,
    // handleAddressType,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const clientId = useSelector((state) => state.clientUser.clientId);
    // Global Order Entry State
    const orderType = useSelector(state => state.orderEntry.orderType);
    const orderNumberState = useSelector(state => state.orderEntry.orderNumber);
    const deliveryAddressState = useSelector(state => state.orderEntry.deliveryAddress);

    // Countries API
    const countries = useMemo(() => countryList().getData(), []);
    const countryCodeoptions = countries?.map(data => data?.value);
    countryCodeoptions.sort();

    const { data: warehouseAddressData, isError: isUserAddressError, isFetching: isFetchingUserAddress } = useGetAccountDetailsQuery(clientId, {
        skip: !clientId,
    });

    const [initialValues, setInitialValues] = useState({
        customerOrderReference: "",
        deliveryAddress: {
            // name1: "",
            // name: "",
            companyName: "",
            firstName: "",
            lastName: "",
            // salutation: "",
            streetName: "",
            addressSupplement: "",
            houseNumber: "",
            city: "",
            zipCode: "",
            countryCode: "",
            phoneNumber: "",
            email: ""
        },
    });

    useEffect(() => {
        setInitialValues({
            customerOrderReference: payload?.customerOrderReference,
            deliveryAddress: payload?.deliveryAddress,
        });
    }, [payload]);


    // For getting Delivery Address if "Shipping Address" radio btn is selected
    useEffect(() => {
        setInitialValues({
            customerOrderReference: orderNumberState,
            deliveryAddress: {
                ...deliveryAddressState,
                phoneNumber: payload?.deliveryAddress?.phoneNumber,
                email: payload?.deliveryAddress?.email
            }
        });
    }, [deliveryAddressState, orderNumberState]);

    const handleStandardDelivery = (values) => {
        const { customerOrderReference } = values;
        const newDelAddr = {
            companyName: warehouseAddressData?.payload?.warehouseAddress?.companyName,
            firstName: warehouseAddressData?.payload?.warehouseAddress?.firstName,
            lastName: warehouseAddressData?.payload?.warehouseAddress?.lastName,
            streetName: warehouseAddressData?.payload?.warehouseAddress?.streetName,
            addressSupplement: warehouseAddressData?.payload?.warehouseAddress?.addressSupplement,
            houseNumber: warehouseAddressData?.payload?.warehouseAddress?.houseNumber,
            city: warehouseAddressData?.payload?.warehouseAddress?.city,
            zipCode: warehouseAddressData?.payload?.warehouseAddress?.zipCode,
            countryCode: warehouseAddressData?.payload?.warehouseAddress?.countryCode,
            phoneNumber: warehouseAddressData?.payload?.warehouseAddress?.phoneNumber,
            email: warehouseAddressData?.payload?.warehouseAddress?.email,
        }
        const newPayload = { ...payload, customerOrderReference, deliveryAddress: newDelAddr };
        handlePayload(newPayload);
        changeStep(3);
    };

    // Action for Global "orderType" state
    const handleOrderTypeState = (value) => {
        dispatch(setOrderType(value));
    };

    // Action for Global "deliveryAddress" state
    const handleDeliveryAddressState = (value) => {
        dispatch(setDeliveryAddress(value));
    };

    // Action for Global "orderNumber" state
    const handleOrderNumberState = (value) => {
        dispatch(setOrderNumber(value));
    };

    return (
        <div className="form-container rest-of-six-col-xs">
            <Formik
                initialValues={initialValues}
                validationSchema={addOrdersSchema}
                onSubmit={(values) => {
                    const { customerOrderReference, deliveryAddress } = values;
                    const newPayload = { ...payload, customerOrderReference, deliveryAddress };
                    handlePayload(newPayload);
                    handleDeliveryAddressState(deliveryAddress);
                    handleOrderNumberState(customerOrderReference);
                    changeStep(3);
                }}
                enableReinitialize
            >
                {({ values, setFieldTouched }) => (
                    <Form>
                        <div>
                            <div className="row mt-6">
                                <div className="four-col-xs ml-24">
                                    <InputLabel
                                        htmlFor="firstName"
                                        label={`${t('addOrdersPage.step2.orderType')}`}
                                    />
                                </div>
                                <div className="two-col-xs">
                                    <div className="row-xs" onChange={(e) => {
                                        handleOrderTypeState(e?.target?.value);
                                    }}>
                                        <div className="two-col-xs">
                                            <span className="checkbox-container">
                                                <input
                                                    type="radio"
                                                    id="Shipping Address"
                                                    name="firstName"
                                                    value="shippingAddress"
                                                    // checked={addressType === "shippingAddress" || orderType === "shippingAddress"}
                                                    checked={orderType === "shippingAddress"}
                                                />
                                                <InputLabel
                                                    htmlFor="Shipping Address"
                                                    label={`${t('addOrdersPage.step2.shippingAddress')}`}
                                                    required={
                                                        false
                                                    }
                                                />
                                            </span>
                                        </div>
                                        <div className="two-col-xs">
                                            <span className="checkbox-container">
                                                <input
                                                    type="radio"
                                                    id="Standard Delivery"
                                                    name="firstName"
                                                    value="standardDelivery"
                                                    // checked={addressType === "standardDelivery" || orderType === "standardDelivery"}
                                                    checked={orderType === "standardDelivery"}
                                                />
                                                <InputLabel
                                                    htmlFor="Standard Delivery"
                                                    label={`${t('addOrdersPage.step2.standardDelivery')}`}
                                                    required={
                                                        false
                                                    }
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* addressType === "standardDelivery" */ orderType === "standardDelivery" ?
                                <>
                                    <div className="row">
                                        <div className="four-col-xs ml-24">
                                            <InputLabel
                                                htmlFor="customerOrderReference"
                                                label={`${t('addOrdersPage.step2.externalOrderNumber')}`}
                                            />
                                        </div>
                                        <div className="two-col-xs">
                                            <Input
                                                name="customerOrderReference"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="four-col-xs ml-24">
                                            <InputLabel
                                                label={`${t('addOrdersPage.step2.defaultAddress')}`}
                                            />
                                        </div>
                                        <div className="two-col-xs pt-1">
                                            {isUserAddressError ?
                                                <p className="mb-0">Error Loading Default Address</p> :
                                                isFetchingUserAddress ?
                                                    <p className="mb-0">Loading...</p> :
                                                    warehouseAddressData?.payload ?
                                                        Object.keys(warehouseAddressData?.payload)?.length ?
                                                            <>
                                                                <p className="mb-0">{warehouseAddressData?.payload?.warehouseAddress?.name1 || "-"}</p>
                                                                <p className="mb-0">{warehouseAddressData?.payload?.warehouseAddress?.addressSupplement || "-"}</p>
                                                                <p className="mb-0">{warehouseAddressData?.payload?.warehouseAddress?.name2 || "-"}</p>
                                                                <p className="mb-0">{`${warehouseAddressData?.payload?.warehouseAddress?.phoneNumber || "-"} ${warehouseAddressData?.payload?.warehouseAddress?.email || "-"}`}</p>
                                                                <p className="mb-0">{`${warehouseAddressData?.payload?.warehouseAddress?.streetName || "-"} ${warehouseAddressData?.payload?.warehouseAddress?.houseNumber || "-"}`}</p>
                                                                <p className="mb-0">{`${warehouseAddressData?.payload?.warehouseAddress?.countryCode || "-"} ${warehouseAddressData?.payload?.warehouseAddress?.zipCode || "-"} ${warehouseAddressData?.payload?.warehouseAddress?.city || "-"}`}</p>
                                                            </> :
                                                            <p className="mb-0">You have not set your default address.</p> :
                                                        null
                                            }
                                            <div className="btn-container w-full relative pr-5">
                                                <Button
                                                    type="button"
                                                    variant="white"
                                                    onClick={() => changeStep(1)}
                                                >
                                                    {t('addOrdersPage.return')}
                                                </Button>
                                                <Button
                                                    type="button"
                                                    loading={isFetchingUserAddress}
                                                    disabled={isUserAddressError}
                                                    onClick={() => {
                                                        if (values?.customerOrderReference) {
                                                            handleStandardDelivery(values);
                                                            handleOrderNumberState(values?.customerOrderReference);
                                                        } else
                                                            setFieldTouched('customerOrderReference');
                                                    }}
                                                >
                                                    {t('addOrdersPage.continue')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="row">
                                        <div className="four-col-xs ml-24">
                                            <InputLabel
                                                htmlFor="customerOrderReference"
                                                label={`${t('addOrdersPage.step2.externalOrderNumber')}`}
                                            />
                                        </div>
                                        <div className="two-col-xs">
                                            <Input
                                                name="customerOrderReference"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="row !mb-0">
                                        <div className="four-col-xs ml-24">
                                            <InputLabel label={`${t('addOrdersPage.step2.shippingAddress')}`} />
                                        </div>
                                        <div className="two-col-xs">
                                            <div className="row-xs">
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="deliveryAddress.companyName"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.company')}`}
                                                    />
                                                </div>
                                                <div className="three-col-xs">
                                                    <Input
                                                        name="deliveryAddress.firstName"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.firstName')} *`}
                                                    />
                                                </div>
                                                <div className="three-col-xs">
                                                    <Input
                                                        name="deliveryAddress.lastName"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.name')} *`}
                                                    />
                                                </div>
                                                {/* <div className="three-col-xs">
                                                    <Input
                                                        name="deliveryAddress.salutation"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.salutation')}`}
                                                    />
                                                </div> */}
                                                <div className="rest-of-five-col-xs">
                                                    <Input
                                                        name="deliveryAddress.streetName"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.street')}`}
                                                    />
                                                </div>
                                                <div className="five-col-xs">
                                                    <Input
                                                        name="deliveryAddress.houseNumber"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.nr')} *`}
                                                    />
                                                </div>
                                                <div className="one-col-xs">
                                                    <Input
                                                        name="deliveryAddress.addressSupplement"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.additive')}`}
                                                    />
                                                </div>
                                                <div className="three-col-xs">
                                                    <Select name="deliveryAddress.countryCode">
                                                        <option value="" disabled={true} hidden={true}>{`${t('addOrdersPage.step2.country')} *`}</option>
                                                        {countryCodeoptions?.map((item, index) => (
                                                            <option value={item} key={index}>{item}</option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="three-col-xs">
                                                    <Input
                                                        name="deliveryAddress.zipCode"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.postCode')} *`}
                                                    />
                                                </div>
                                                <div className="three-col-xs">
                                                    <Input
                                                        name="deliveryAddress.city"
                                                        type="text"
                                                        placeholder={`${t('addOrdersPage.step2.location')} *`}
                                                    />
                                                </div>
                                                <div className="one-col-xs text-center text-textColor-light">
                                                    <h6>
                                                        {`* ${t('addOrdersPage.step2.requiredFields')}`}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="btn-container w-full relative pr-5">
                                                <Button
                                                    type="button"
                                                    variant="white"
                                                    onClick={() => changeStep(1)}
                                                >
                                                    {t('addOrdersPage.return')}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                >
                                                    {t('addOrdersPage.continue')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default Step2;