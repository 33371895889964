export const viewDocumentsPageEn = {
    formsOrDocuments: "Forms / Documents",
    designation: "Designation",
    format: "Format",
    lastUpdate: "Last Update",
    search: "Search",
    delete: "Delete",
    actions: "Actions",
    toastNotifications: {
        success: "Successfully downloaded.",
        failure: "Error downloading file.",
    },
};

export const viewDocumentsPageDe = {
    formsOrDocuments: "Formulare / Dokumente",
    designation: "Bezeichnung",
    format: "Format",
    lastUpdate: "letzte Aktualisierung",
    search: "Suche",
    delete: "Löschen",
    actions: "Aktionen",
    toastNotifications: {
        success: "Erfolgreich heruntergeladen.",
        failure: "Fehler beim Herunterladen der Datei.",
    },
};

