import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetDashboardQuery } from "../../../services/dashboard";
import { baseUrl } from "../../../config";
import { navToOrderView, navToPrices } from "../../../navigations";
import { failureToast } from "../../../components/Toast";
import LoadingOverlay from "../../../components/LoadingOverlay";
import DownloadButtonPopUpModal from "../../../components/DownloadButtonPopUpModal";
import { addZeroInSingleDigit } from "../../../utils/addZeroInSingleDigit";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.clientAuth.authToken);
  const { data, isError, isFetching } = useGetDashboardQuery();

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); // "CI", "PL", "CPL"

  const handleDownload = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (isError) {
      failureToast(`${t("dashboardPage.toastNotifications.failure")}`);
    }
  }, [isError]);

  return (
    <section className="dashboard-section">
      <div className="page-view">
        <div className="page-content-no-bg">
          {isFetching ? (
            <LoadingOverlay />
          ) : (
            <div className="row-xs pr-16">
              <div className="two-col-xs">
                <div className="row-xs">
                  <div className="two-col-xs">
                    <div
                      className="page-content-bg dashboard-smallbox"
                      onClick={() => navigate(navToOrderView())}
                    >
                      <h5 className="text-center">
                        {t("dashboardPage.orders")} {new Date().getFullYear()}
                      </h5>
                      <h1 className="text-[32px] font-bold text-center pt-2">
                        {data?.payload?.totalOrders}
                      </h1>
                    </div>
                  </div>
                  <div className="two-col-xs">
                    <div
                      className={`page-content-bg dashboard-smallbox${
                        data?.payload?.newPriceList ? "" : " !cursor-default"
                      }`}
                      onClick={() => {
                        if (data?.payload?.newPriceList) {
                          handleDownload("PL");
                        }
                      }}
                    >
                      <DownloadButtonPopUpModal
                        showModal={showModal && modalType === "PL"}
                        setShowModal={setShowModal}
                        handleDownload={() => handleDownload("PL")}
                        handleCancel={handleCancel}
                        downloadLink={`${baseUrl}/articles/prices/upcoming/download`}
                        authToken={authToken}
                        successMessage={t(
                          "viewDocumentsPage.toastNotifications.success"
                        )}
                        failureMessage={t(
                          "viewDocumentsPage.toastNotifications.failure"
                        )}
                      />
                      <h1 className="single-heading">
                        {t("dashboardPage.priceList")}
                      </h1>
                      <h1 className="single-heading">
                        {data?.payload?.newPriceList
                          ? `${addZeroInSingleDigit(
                              data?.payload?.newPriceList[1]
                            )}/${data?.payload?.newPriceList[0]}`
                          : "-"}
                      </h1>
                    </div>
                  </div>
                  <div className="two-col-xs">
                    <div
                      className="page-content-bg dashboard-smallbox dashboard-smallbox-yellow"
                      onClick={() => handleDownload("CI")}
                    >
                      <DownloadButtonPopUpModal
                        showModal={showModal && modalType === "CI"}
                        setShowModal={setShowModal}
                        handleDownload={() => handleDownload("CI")}
                        handleCancel={handleCancel}
                        downloadLink={`${baseUrl}/articles/stocks/download`}
                        authToken={authToken}
                        successMessage={t(
                          "viewDocumentsPage.toastNotifications.success"
                        )}
                        failureMessage={t(
                          "viewDocumentsPage.toastNotifications.failure"
                        )}
                      />

                      <h1 className="single-heading">
                        {t("dashboardPage.currentInventory")}
                      </h1>
                    </div>
                  </div>

                  <div className="two-col-xs">
                    <div
                      className="page-content-bg dashboard-smallbox"
                      onClick={() => handleDownload("CPL")}
                    >
                      <DownloadButtonPopUpModal
                        showModal={showModal && modalType === "CPL"}
                        setShowModal={setShowModal}
                        handleDownload={() => handleDownload("CPL")}
                        handleCancel={handleCancel}
                        downloadLink={`${baseUrl}/articles/prices/current/download`}
                        authToken={authToken}
                        successMessage={t(
                          "viewDocumentsPage.toastNotifications.success"
                        )}
                        failureMessage={t(
                          "viewDocumentsPage.toastNotifications.failure"
                        )}
                      />
                      <h1 className="single-heading">
                        {t("dashboardPage.currentPriceList")}
                      </h1>
                    </div>
                  </div>
                  <div className="one-col-xs">
                    <div
                      className="page-content-bg dashboard-searchbox"
                      onClick={() => navigate(navToPrices())}
                    >
                      <span className="icon-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-10 w-10"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </span>
                      <h3 className="single-heading pl-9 normal-case">
                        {t("dashboardPage.priceSearch")}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="two-col-xs ">
                <div className="row-xs">
                  <div className="one-col-xs">
                    <div className="page-content-bg dashboard-adAndContentBox dashboard-smallbox-blue mb-2">
                      <div className="ad-space-wrapper">
                        <img
                          src={process.env.PUBLIC_URL + "/images/hella.png"}
                        />
                      </div>
                      <h1 className="single-heading">
                        {t("dashboardPage.currentSpecialOrders")}
                      </h1>
                    </div>
                  </div>
                  <div className="one-col-xs">
                    <div className="dashboard-newsFeedBox-wrapper">
                      {/* <div className="page-content-bg dashboard-newsFeedBox"> */}
                      <div className="dashboard-newsFeedBox">
                        <ul>
                          {data?.payload?.newsFeeds.map((item, index) => (
                            <li key={index}>
                              <span className="news-date">{`${addZeroInSingleDigit(
                                item?.date[2]
                              )}.${addZeroInSingleDigit(item?.date[1])}.${
                                item?.date[0]
                              }`}</span>
                              <NavLink
                                to="#"
                                className="news-title"
                                title={`${item?.description}`}
                              >
                                {`${item?.description}`}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Home;
