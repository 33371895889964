// import ReactPaginate from 'react-paginate';
import Pagination from 'react-js-pagination';
import PropTypes from 'prop-types';

let noIs=1;
function CustomPagination({
    pageIndex,
    handlePageChange,
    pageSize,
    totalData,
}) {
    // const handlePageClick = (e) => {
    //     handlePageChange(e.selected);
    // };

if(totalData%15>0){
noIs=Math.floor(totalData/15)+1
}
if(totalData%15===0){
    noIs=totalData/15
}


    const handlePageClick = (pgNum) => {
        
        // console.log('size',pgNum)
        handlePageChange(pgNum -1);
    };

    const nextSvg = () => {
        return (
            <span className='flex'>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                    />
                </svg>
            </span>
        );
    };

    const prevSvg = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clipRule="evenodd"
                />
            </svg>
        );
    };

    const lastPageSvg = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
                <path fillRule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
        );
    }

    const firstPageSvg = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
        );
    };

    return (
        <>
            {/* <ReactPaginate
                breakLabel=""
                nextLabel={nextSvg()}
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={0}
                pageCount={pageCount}
                previousLabel={pageIndex === 0 ? null : prevSvg()}
                renderOnZeroPageCount={null}
                // When we are on let's say, page 10 and we sort a column, we get data for the 1st page with sorted data. So we have to move the pagination ui to page 1. So using forcePage prop.
                forcePage={pageIndex}
                className={isLoading ? 'pointer-events-none' : ''}
            /> */}
{/* {console.log('pageno.',totalData)} */}
            <Pagination
                activePage={pageIndex +1 }
                itemsCountPerPage={pageSize}
                totalItemsCount={totalData}
                pageRangeDisplayed={noIs}
                // nextPageText={nextSvg()}
                nextPageText={pageIndex === noIs-1 ? null : nextSvg()}
                prevPageText={pageIndex === 0 ? null : prevSvg()}
                // lastPageText={lastPageSvg()}
                lastPageText={((pageIndex === noIs-1)||(noIs===2)) ? null : lastPageSvg()}

                firstPageText={((pageIndex === 0)||(noIs===2))  ? null : firstPageSvg()}
                onChange={handlePageClick}
            />
        </>
    );
}

CustomPagination.propTypes = {
    pageIndex: PropTypes.number,
    handlePageChange: PropTypes.func,
}

export default CustomPagination;
