import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    adminId: null,
}

export const adminUserSlice = createSlice({
    name: 'adminUser',
    initialState,
    reducers: {
        setAdminId: (state, action) => {
            state.adminId = action.payload;
        },
        removeAdminId: (state) => {
            state.adminId = null;
        },
    },
})

export const { setAdminId, removeAdminId } = adminUserSlice.actions;

export default adminUserSlice.reducer;