export const DownloadButtonPopUpModalen = {
  confirmDownload: "Confirm Download",
  cancelDownload: "Cancel Download",
  completed: "Completed",
};

export const DownloadButtonPopUpModalde = {
  confirmDownload: "Download bestätigen",
  cancelDownload: "Download abbrechen",
  completed: "Abgeschlossen",
};
