import { useState, useEffect, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { baseUrl } from "../../../../config";
import { useGetDocumentsQuery, useDeleteDocumentMutation } from "../../../../services/admin/document";
import { downloadFile } from "../../../../utils/downloadFile";
import SimpleTable from "../../../../components/Table/SimpleTable";
import Button from "../../../../components/Button";
import { successToast, failureToast } from "../../../../components/Toast";
import { addZeroInSingleDigit } from "../../../../utils/addZeroInSingleDigit";

function AdminDocumentView() {
    const { t } = useTranslation();
    const authToken = useSelector((state) => state.adminAuth.authToken);
    const [tableData, setTableData] = useState([]);
    const [clickedRow, setClickedRow] = useState("");

    const { data: documentsData, isError: isDocumentsError, isFetching: isFetchingDocuments } = useGetDocumentsQuery();
    const [deleteDoc, {
        isError: isDeleteError,
        isLoading: deletingDoc,
        isSuccess: isDeleteSuccess,
    }] = useDeleteDocumentMutation();

    const columns = useMemo(
        () => [
            {
                id: 'designation',
                Header: `${t('viewDocumentsPage.designation')}`,
                accessor: 'designation',
                Cell: ({ cell: { value } }) => value || '-',
                canBeFiltered: false,
            },
            {
                id: 'format',
                Header: `${t('viewDocumentsPage.format')}`,
                accessor: 'format',
                Cell: ({ cell: { value } }) => value || '-',
                canBeFiltered: false,
            },
            {
                id: 'lastUpdate',
                Header: `${t('viewDocumentsPage.lastUpdate')}`,
                accessor: 'lastUpdate',
                Cell: ({ cell: { value } }) => value || '-',
                canBeFiltered: false,
            },
            {
                id: 'search',
                Header: `${t('viewDocumentsPage.search')}`,
                accessor: 'search',
                Cell: ({ row }) => (
                    <a
                        className="underline cursor-pointer"
                        onClick={() => {
                            downloadFile(`${baseUrl}/document/download/${row?.original?.designation}`,
                                authToken,
                                `${t('viewDocumentsPage.toastNotifications.success')}`,
                                `${t('viewDocumentsPage.toastNotifications.failure')}`,
                                row?.original?.designation,
                                row?.original?.format,
                            );
                        }}
                    >
                        Download
                    </a>
                ),
                // Id of that column which needs to be filtered.
                filterColumnId: 'designation',
                canBeFiltered: true,
            },
            {
                id: 'delete',
                Header: `${t('viewDocumentsPage.actions')}`,
                accessor: 'delete',
                Cell: ({ row }) => (
                    <Button
                        variant="white"
                        loading={clickedRow === row?.original?.id ? deletingDoc : false}
                        onClick={() => {
                            setClickedRow(parseInt(row?.original?.id));
                            deleteDoc({ fileName: row?.original?.designation })
                        }}
                    >
                        Delete
                    </Button>
                ),
                canBeFiltered: false,
            },
        ],
        [t, deletingDoc]
    );

    useEffect(() => {
        if (documentsData) {
            setTableData(documentsData?.payload?.map((item, index) => {
                return {
                    id: index,
                    designation: item?.fileName,
                    format: item?.format,
                    lastUpdate: `${addZeroInSingleDigit(item?.lastUpdateDate[2])}.${addZeroInSingleDigit(item?.lastUpdateDate[1])}.${item?.lastUpdateDate[0]}`,
                };
            }))
        }
    }, [documentsData]);

    useEffect(() => {
        if (isDeleteError) {
            failureToast('Failed to delete');
        }
        if (isDeleteSuccess) {
            successToast('Successfully deleted');
        }
    }, [isDeleteError, isDeleteSuccess]);

    return (
        <div className="rest-of-six-col-xs">
            <div className="row-xs">
                <div className="one-col-xs text-center">
                    <h3>View Documents</h3>
                </div>
                <div className="one-col-xs">
                    <SimpleTable
                        columns={columns}
                        data={tableData}
                        isFetchError={isDocumentsError}
                        isLoadingData={isFetchingDocuments}
                        loadingSvgFixed={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default AdminDocumentView;