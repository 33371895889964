import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchArticlesQuery } from "../../../../services/articles";
import { baseUrl } from "../../../../config";
import {
  setOrderCartItems,
  changeQtyOrderCartItems,
  removeSelectedCartItem,
} from "../../../../features/orderCart/orderCartSlice";
import Table from "../../../../components/Table";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { modalTypes } from "../../../../components/Modal/ModalTypes";
import { getPriceQueryForTable } from "../../../../utils/getQueryStringForTable";
import DownloadButtonPopUpModal from "../../../../components/DownloadButtonPopUpModal";

function ViewPrices({
  horBannerAd = null,
  verBannerAd = null,
  hasSideTable = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.clientAuth.authToken);
  const cartItems = useSelector((state) => state.orderCart.cartItems);

  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [tableQuery, setTableQuery] = useState("");
  const [showTableHint, setShowTableHint] = useState(true);

  const [openBasket, setOpenBasket] = useState(false);
  const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState("");
  const [yesToRemove, setYesToRemove] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(""); // "CI", "PL", "CPL"

  const handleDownload = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const {
    data: articlesData,
    isError: isArticlesError,
    isFetching: isFetchingArticles,
  } = useSearchArticlesQuery(tableQuery, {
    skip: !tableQuery,
  });

  const handleAddedItems = ({ ean, price, minimumOrderQuantity }) => {
    const newItem = {
      ean: ean,
      minStock: minimumOrderQuantity,
      quantity: minimumOrderQuantity,
      price: price,
    };
    if (cartItems?.length !== 0) {
      const foundItem = cartItems?.find((item) => item?.ean === newItem?.ean);
      if (!foundItem) {
        dispatch(setOrderCartItems([...cartItems, newItem]));
      }
    } else {
      dispatch(setOrderCartItems([...cartItems, newItem]));
    }
  };

  /* For Managing Cart State */
  const handleQty = (id, value) => {
    dispatch(changeQtyOrderCartItems({ id, value }));
  };

  const showRemoveItemModal = () => setOpenRemoveItemModal(true);

  useEffect(() => {
    if (itemToRemove && yesToRemove) {
      dispatch(removeSelectedCartItem(itemToRemove));
    }
  }, [itemToRemove, yesToRemove]);

  const handleItemToRemove = (id) => {
    setItemToRemove(id);
  };

  const handleYesToRemove = (isYes) => {
    setYesToRemove(isYes);
  };
  /* For Managing Cart State */

  const columns = useMemo(
    () => [
      {
        id: "sku",
        Header: `${t("viewPricesPage.sku")}`,
        accessor: "sku",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "ean",
        Header: `${t("viewPricesPage.ean")}`,
        accessor: "ean",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "han",
        Header: `${t("viewPricesPage.han")}`,
        accessor: "han",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "availableStock",
        Header: `${t("viewPricesPage.availableStock")}`,
        accessor: "availableStock",
        Cell: ({ cell: { value } }) => value || "0",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "minimumOrderQuantity",
        Header: `${t("viewPricesPage.minimumOrderQuantity")}`,
        accessor: "minimumOrderQuantity",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "price",
        Header: `${t("viewPricesPage.price")}`,
        accessor: "price",
        Cell: ({ cell: { value } }) => value || "-",
        canBeSorted: true,
        canBeFiltered: false,
      },
      {
        id: "search",
        Header: `${t("viewPricesPage.search")}`,
        accessor: "search",
        Cell: ({ row }) => (
          <a
            href="#"
            className="underline"
            // onClick={() => setOpenModal(true)}
            onClick={() => {
              handleAddedItems({
                ean: row?.original?.ean,
                price: row?.original?.price,
                minimumOrderQuantity: row?.original?.minimumOrderQuantity,
              });
              // setOpenModal(true);
              setOpenBasket(true);
            }}
          >
            {t("viewPricesPage.enterOrder")}
          </a>
        ),
        canBeSorted: false,
        canBeFiltered: true,
        setShowTableHint: setShowTableHint,
      },
    ],
    [t, cartItems]
  );

  const getData = ({ filters }) => {
    const query = getPriceQueryForTable(filters);
    setTableQuery(query);
  };

  useEffect(() => {
    setPageCount(8);
    setTotalData(24);
    if (articlesData) {
      setTableData(
        articlesData?.payload?.articles?.map((data) => {
          return {
            id: data?.articleId,
            sku: data?.articleId,
            ean: data?.ean,
            han: data?.supplierArticleId,
            availableStock: data?.availableQty,
            minimumOrderQuantity: data?.minimumPurchaseQty,
            price: data?.price + " €",
          };
        })
      );
    }
  }, [articlesData]);

  return (
    <section>
      {openBasket ? (
        <Modal
          modalTitle={t("viewPricesPage.basket.currentBasket")}
          onCancelClick={() => {
            setOpenBasket(false);
          }}
          hasMoreBtn={false}
          modalWidth="w-[60%]"
          modalHeight="h-[55%]"
          modalType={modalTypes?.type4?.name}
          cartData={cartItems}
          changeItemQty={handleQty}
          showRemoveItemModal={showRemoveItemModal}
          handleItemToRemove={handleItemToRemove}
        />
      ) : null}
      {openRemoveItemModal ? (
        <Modal
          modalTitle={`${t("modal.removePrompt")}`}
          onCancelClick={() => {
            setOpenRemoveItemModal(false);
          }}
          modalWidth="w-[40%]"
          modalHeight="h-[25%]"
          modalType={modalTypes?.type5?.name}
          handleItemToRemove={handleItemToRemove}
          handleYesToRemove={handleYesToRemove}
          hasMoreBtn={false}
          noOutsideClickClose={true}
        />
      ) : null}
      <div className="page-view">
        <div className="row-xs">
          <div className="rest-of-six-col-xs">
            <div className="page-ad-space-horizontal">
              <div className="page-ad">
                {horBannerAd ? <img src={horBannerAd} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="page-header">
          <h1>{t("viewPricesPage.prices")}</h1>
        </div>
        <div className="page-content-no-bg">
          <div className="row-xs">
            <div className="rest-of-six-col-xs">
              <Table
                columns={columns}
                data={tableData}
                fetchData={getData}
                isFetchError={isArticlesError}
                isLoadingData={isFetchingArticles}
                pageCount={pageCount}
                totalData={totalData}
                hasPagination={false}
                hasSorting={false}
                noTableData={
                  articlesData?.payload?.articles?.length === undefined
                }
                tableNote={t("viewPricesPage.tableNote")}
                hasArrowHint={showTableHint}
                hintMessage={t("viewPricesPage.searchHint")}
                tableName="Category/s"
              />
              <div className="btn-container w-full relative pr-5">
                <Button
                  type="button"
                  variant="secondary"
                  className="w-[155px] leading-4 py-1"
                  onClick={() => handleDownload("CI")}
                >
                  <DownloadButtonPopUpModal
                    showModal={showModal && modalType === "CI"}
                    setShowModal={setShowModal}
                    handleDownload={() => handleDownload("CI")}
                    handleCancel={handleCancel}
                    downloadLink={`${baseUrl}/articles/stocks/download`}
                    authToken={authToken}
                    successMessage={t(
                      "viewDocumentsPage.toastNotifications.success"
                    )}
                    failureMessage={t(
                      "viewDocumentsPage.toastNotifications.failure"
                    )}
                  />

                  <span className="w-full">
                    {t("viewPricesPage.currentInventory")}
                  </span>
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  className="w-[155px] leading-4 py-1"
                  onClick={() => handleDownload("CPL")}
                >
                  <DownloadButtonPopUpModal
                    showModal={showModal && modalType === "CPL"}
                    setShowModal={setShowModal}
                    handleDownload={() => handleDownload("CPL")}
                    handleCancel={handleCancel}
                    downloadLink={`${baseUrl}/articles/prices/current/download`}
                    authToken={authToken}
                    successMessage={t(
                      "viewDocumentsPage.toastNotifications.success"
                    )}
                    failureMessage={t(
                      "viewDocumentsPage.toastNotifications.failure"
                    )}
                  />
                  <span className="w-full">
                    {t("viewPricesPage.currentPriceList")}
                  </span>
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  className="w-[155px] leading-4 py-1"
                  onClick={() => handleDownload("PL")}
                >
                  <DownloadButtonPopUpModal
                    showModal={showModal && modalType === "PL"}
                    setShowModal={setShowModal}
                    handleDownload={() => handleDownload("PL")}
                    handleCancel={handleCancel}
                    downloadLink={`${baseUrl}/articles/prices/upcoming/download`}
                    authToken={authToken}
                    successMessage={t(
                      "viewDocumentsPage.toastNotifications.success"
                    )}
                    failureMessage={t(
                      "viewDocumentsPage.toastNotifications.failure"
                    )}
                  />
                  <span className="w-full">
                    {t("viewPricesPage.downloadNewPriceList")}
                  </span>
                </Button>
              </div>
            </div>
            <div className="six-col-xs">
              <div className="page-ad-space-vertical">
                <div
                  className={`${
                    hasSideTable ? "page-ad page-ad-mt" : "page-ad"
                  }`}
                >
                  {verBannerAd ? <img src={verBannerAd} /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ViewPrices;
