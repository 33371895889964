import * as Yup from 'yup';

export const addOrdersSchema = Yup.object().shape({
    customerOrderReference: Yup.string().required("Required").min(1, 'Too short'),
    deliveryAddress: Yup.object().shape({
        // name1: Yup.string().required("Required"),
        // name: Yup.string(),
        companyName: Yup.string(),
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        // salutation: Yup.string(),
        streetName: Yup.string().required("Required"),
        addressSupplement: Yup.string(),
        houseNumber: Yup.string().required("Required"),
        city: Yup.string().required("Required"),
        zipCode: Yup.string().required("Required"),
        countryCode: Yup.string().required("Required"),
        // phoneNumber: "0123456",
        // email: "some.guy@example.com"
    }),
});