export const modalTypes = {
	type1: {
		name: "TablesAndDetailsTwoCol",
	},
	type2: {
		name: "ContactFormsTwoCol",
	},
	type3: {
		name: "CSVUpload",
	},
	type4: {
		name: "CurrentBasket",
	},
	type5: {
		name: "RemoveItemPrompt"
	}
};