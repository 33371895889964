import { NavLink } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";

export const successToast = (message) => toast.success(message);

export const successToastWithLink = (message, linkHref, linkTitle) => {
    toast.success(t => (
        <>
            <span>
                {message}
            </span>
            <NavLink to={linkHref}>
                <a className="ml-2 text-gray-500 underline hover:text-primary-light">{linkTitle}</a>
            </NavLink>
        </>
    ));
};

export const failureToast = (message) => toast.error(message);

function Toast() {
    return (
        <Toaster
            position="top-right"
            toastOptions={{
                // Options for all toasts
                duration: 5000,
                style: {
                    borderRadius: '0'
                },
                // Options for Success Toasts
                success: {
                    style: {
                        color: 'green',
                    }
                },
                // Options for Success Toasts
                error: {
                    style: {
                        color: 'red',
                    }
                }
            }}
        />
    );
}

export default Toast;
