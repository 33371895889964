import { useState, useEffect, useRef, useMemo } from "react";
import { Formik, Form, } from "formik";
import { adminAddNewsfeedSchema } from "../../../../validations/admin/newsfeed/addNewsfeed";
import { useAddNewsfeedMutation, useGetNewsfeedQuery, useDeleteNewsfeedMutation } from "../../../../services/admin/newsfeed";
import Table from "../../../../components/Table";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import { successToast, failureToast } from "../../../../components/Toast";
import { addZeroInSingleDigit } from "../../../../utils/addZeroInSingleDigit";

function AdminNewsfeedAdd() {
    const [clickedRow, setClickedRow] = useState("");
    const [tableData, setTableData] = useState([]);

    const formikRef = useRef(null);

    // Newsfeed Get Api
    // const { data: newsfeedData, isError: isNewsfeedError, isFetching: isFetchingNewsfeed } = useGetDashboardQuery();
    const { data: newsfeedData, isError: isNewsfeedError, isFetching: isFetchingNewsfeed } = useGetNewsfeedQuery();

    // Newsfeed Add Api
    const [addNewsfeed, {
        isLoading: addingNewsfeed,
        isError: isNewsfeedAddError,
        isSuccess: isNewsfeedAddSuccess
    }] = useAddNewsfeedMutation();

    // Newsfeed Delete Api
    const [deleteNewsfeed, {
        isLoading: deletingNewsfeed,
        isError: isNewsfeedDeleteError,
        isSuccess: isNewsfeedDeleteSuccess
    }] = useDeleteNewsfeedMutation();

    // Newsfeed Form Logic
    const initialValues = {
        description: ""
    };

    // Newsfeed Add Toasts
    useEffect(() => {
        if (isNewsfeedAddError)
            failureToast("Failed to add newsfeed");
        if (isNewsfeedAddSuccess) {
            successToast("Successfully added newsfeed");
            formikRef?.current?.resetForm();
        }
    }, [isNewsfeedAddError, isNewsfeedAddSuccess]);

    // Newsfeed Delete Toasts
    useEffect(() => {
        if (isNewsfeedDeleteError)
            failureToast("Failed to delete newsfeed");
        if (isNewsfeedDeleteSuccess) {
            successToast("Successfully deleted newsfeed");
        }
    }, [isNewsfeedDeleteError, isNewsfeedDeleteSuccess]);

    const handleSubmit = (values) => {
        addNewsfeed(values);
    };

    // Newsfeed Table Logic
    const columns = useMemo(
        () => [
            {
                id: "id",
                Header: "Newsfeed ID",
                accessor: "id",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "description",
                Header: "Description",
                accessor: "description",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: "date",
                Header: "Created At",
                accessor: "date",
                Cell: ({ cell: { value } }) => value || "-",
                canBeSorted: false,
                canBeFiltered: false,
            },
            {
                id: 'delete',
                Header: "Delete",
                accessor: 'delete',
                Cell: ({ row }) => (
                    <Button
                        variant="white"
                        // loading={deletingNewsfeed}
                        loading={clickedRow === row?.original?.id ? deletingNewsfeed : false}
                        onClick={() => {
                            setClickedRow(parseInt(row?.original?.id));
                            deleteNewsfeed({ id: row?.original?.id });
                        }}
                    >
                        Delete
                    </Button>
                ),
                canBeFiltered: false,
            },
        ],
        [deletingNewsfeed]
    );

    useEffect(() => {
        if (newsfeedData) {
            setTableData(newsfeedData?.payload?.newsFeeds?.map((data) => {
                return {
                    id: data?.id,
                    description: data?.description,
                    date: `${addZeroInSingleDigit(data?.date[2])}.${addZeroInSingleDigit(data?.date[1])}.${data?.date[0]}`,
                }
            }));
        }
    }, [newsfeedData]);

    return (
        <div className="row-xs">
            <div className="rest-of-three-col-xs">
                <Table
                    columns={columns}
                    data={tableData}
                    isFetchError={isNewsfeedError}
                    isLoadingData={isFetchingNewsfeed}
                    hasPagination={false}
                    hasSorting={false}
                    hasEllipis={true}
                />
            </div>
            <div className="three-col-xs">
                <div className="form-container">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={adminAddNewsfeedSchema}
                        onSubmit={(values) => {
                            handleSubmit(values);
                        }}
                        innerRef={formikRef}
                    >
                        {() => (
                            <Form>
                                <div className="flex justify-center">
                                    <div className="row-xs w-[25vw]">
                                        <div className="one-col-xs text-center">
                                            <h3>Add Newsfeed</h3>
                                        </div>
                                        <div className="one-col-xs">
                                            <Input
                                                name="description"
                                                isTextArea={true}
                                                rows="10"
                                                placeholder="Description"
                                            />
                                        </div>
                                        <div className="btn-container w-full relative">
                                            <Button
                                                type="submit"
                                                loading={addingNewsfeed}
                                                className="!m-0"
                                            >
                                                Add Newsfeed
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default AdminNewsfeedAdd;