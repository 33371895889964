const AUTH_TOKEN = "authTokenSP";
const AUTH_TOKEN_EXPIRY_TIME_IN_HOUR = 12;
const ADMIN_AUTH_TOKEN = "authTokenAdminSP";
const ADMIN_AUTH_TOKEN_EXPIRY_TIME_IN_HOUR = 1;

// For Client
export const setAuthToken = (token) => {
	const now = new Date();

	const item = {
		value: token,
		expiry: now.getTime() + AUTH_TOKEN_EXPIRY_TIME_IN_HOUR * 60 * 60 * 1000,
	};

	localStorage.setItem(AUTH_TOKEN, JSON.stringify(item));
};

export const getAuthToken = () => {
	const itemStr = localStorage.getItem(AUTH_TOKEN);
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(AUTH_TOKEN);
		return null;
	}
	return item.value;
};

export const removeAuthToken = () => {
	localStorage.removeItem(AUTH_TOKEN);
};


// For Admin
export const setAdminAuthToken = (token) => {
	const now = new Date();

	const item = {
		value: token,
		expiry: now.getTime() + ADMIN_AUTH_TOKEN_EXPIRY_TIME_IN_HOUR * 60 * 60 * 1000,
	};

	localStorage.setItem(ADMIN_AUTH_TOKEN, JSON.stringify(item));
};

export const getAdminAuthToken = () => {
	const itemStr = localStorage.getItem(ADMIN_AUTH_TOKEN);
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(ADMIN_AUTH_TOKEN);
		return null;
	}
	return item.value;
};

export const removeAdminAuthToken = () => {
	localStorage.removeItem(ADMIN_AUTH_TOKEN);
};