import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientId: null,
}

export const clientUserSlice = createSlice({
  name: 'clientUser',
  initialState,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    removeClientId: (state) => {
      state.clientId = null;
    },
  },
})

export const { setClientId, removeClientId } = clientUserSlice.actions;

export default clientUserSlice.reducer;