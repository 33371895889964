import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../../../config";

export const usersByAdminApi = createApi({
    reducerPath: "usersByAdminApi",
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().adminAuth.authToken;
            if (token) {
                headers.set("Authorization", `Basic ${token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['AdminUsers'],

    endpoints: (builder) => ({
        addUser: builder.mutation({
            query: (data) => {
                return {
                    url: `/users/`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUsers'],
        }),

        getUsers: builder.query({
            query: () => {
                return {
                    url: `/users`,
                    method: "GET",
                };
            },
            providesTags: ['AdminUsers'],
        }),

        updateUser: builder.mutation({
            query: (data) => {
                return {
                    url: `/users/`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUsers'],
        }),

        addUserDefaultAddress: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `/users/${id}/address`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUsers'],
        }),

        getUserAddress: builder.query({
            query: (id) => {
                return {
                    url: `/users/${id}/address`,
                    method: "GET",
                };
            },
            providesTags: ['AdminUsers'],
        }),

    }),
});

export const {
    useAddUserMutation,
    useGetUsersQuery,
    useUpdateUserMutation,
    useAddUserDefaultAddressMutation,
    useGetUserAddressQuery,
} = usersByAdminApi;