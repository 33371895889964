import { useField } from 'formik';
import { useState } from 'react';
import PropTypes from 'prop-types';

const PasswordInput = ({
    name,
    fieldErrMessage = 'field-err-message',
    className = "",
    ...props
}) => {
    const [field, meta] = useField(name);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };
    return (
        <>
            <div className="w-full relative">
                {passwordShown ? (
                    <div className="eyeIcon cursor-pointer absolute right-2 top-[6px] ">
                        <svg
                            onClick={() => {
                                setPasswordShown(false);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                        </svg>
                    </div>
                ) : (
                    <div className="eyeIcon cursor-pointer absolute right-2 top-[6px]">
                        <svg
                            onClick={() => {
                                setPasswordShown(true);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                            />
                        </svg>
                    </div>
                )}
                <input
                    {...field}
                    {...props}
                    className={`custom-placeholder ${className}`}
                    type={passwordShown ? 'text' : 'password'}
                />
                {meta.touched && meta.error ? (
                    <div className={fieldErrMessage}>{meta.error}</div>
                ) : null
                }
                <i onClick={togglePasswordVisiblity}></i>{' '}
            </div>{' '}
        </>
    );
};

PasswordInput.propTypes = {
    name: PropTypes.string.isRequired,
    fieldErrMessage: PropTypes.string,
};

export default PasswordInput;