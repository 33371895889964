import DatePicker from 'react-datepicker';
import { useField, useFormikContext } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useRef } from 'react';

function TimePickerInput({
    name,
    required = true,
    className = "",
    fieldErrMessageStyle = 'field-err-message',
    placeholder = 'Enter Time',
    ...props
}) {
    const timepickerRef = useRef(null);
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    useEffect(() => {
        if (timepickerRef.current){
            document.getElementsByClassName("custom-timepicker-container")[0].setAttribute("autocomplete", "off");
        }
    }, [timepickerRef.current]);

    return (
        <div>
            <DatePicker
                {...field}
                {...props}
                className={`custom-placeholder custom-timepicker-container ${className}`}
                calendarClassName="custom-timepicker"
                selected={(field.value && new Date(field.value)) || null}
                onChange={(val) => {
                    setFieldValue(name, val);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                closeOnScroll
                placeholderText={placeholder}
                ref={timepickerRef}
            />
            {meta.touched && meta.error ? (
                <div className={fieldErrMessageStyle}>{meta.error}</div>
            ) : null}
        </div>
    );
}

export default TimePickerInput;