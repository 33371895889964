import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeQtyOrderCartItems, removeSelectedCartItem, removeOrderCartItems } from "../../features/orderCart/orderCartSlice";
import { navToHome, navToOrderView, navToOrderAdd, navToPrices, navToDocuments, navToUserAccount, navToLogin } from "../../navigations";
import Modal from "../Modal";
import { modalTypes } from "../Modal/ModalTypes";
import { removeClientToken } from "../../features/auth/clientAuthSlice";
import { removeOrderEntry } from "../../features/orderEntry/orderEntrySlice";
import { removeAuthToken } from "../../storage/localStorage";

function Sidebar({ open = true }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const cartItems = useSelector((state) => state.orderCart.cartItems);

    const [openModal, setOpenModal] = useState(false);
    const [openBasket, setOpenBasket] = useState(false);
    const [openRemoveItemModal, setOpenRemoveItemModal] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);

    const [itemToRemove, setItemToRemove] = useState('');
    const [yesToRemove, setYesToRemove] = useState(false);

    const logout = () => {
        removeAuthToken();
        // On logging out, we dispatch the removeClientToken state action which will remove the token from both localStorage and global state.
        dispatch(removeClientToken());
        // dispatch(removeOrderCartItems());
        dispatch(removeOrderEntry());
        // navigate("/login");
        navigate(`${navToLogin()}`,
            { replace: true }
        );
    };

    /* For Managing Cart State */
    const handleQty = (id, value) => {
        dispatch(changeQtyOrderCartItems({ id, value }));
    };

    const showRemoveItemModal = () => setOpenRemoveItemModal(true);

    useEffect(() => {
        if (itemToRemove && yesToRemove) {
            dispatch(removeSelectedCartItem(itemToRemove));
            setYesToRemove(false);
        }
    }, [itemToRemove, yesToRemove]);

    const handleItemToRemove = (id) => {
        setItemToRemove(id);
    };

    const handleYesToRemove = (isYes) => {
        setYesToRemove(isYes);
    };
    /* For Managing Cart State */

    return (
        <div className={open ? "sidebar" : "sidebar sidebar-close"}>
            <aside>
                <div className="logo">
                    <img className="main-logo" src={process.env.PUBLIC_URL + "/images/mainLogo.png"} />
                </div>
                <nav>
                    <ul className="nav-items">
                        <li>
                            <NavLink
                                to={navToHome()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                {t('nav.homepage')}
                            </NavLink>
                        </li>
                        <li>
                            {/* 
                                Using relative links (i.e. not "/orders" but "orders") as when parent route might change later then,
                                we don't have to manually update child link hrefs. 
                            */}
                            <NavLink
                                to={navToOrderView()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                                end
                            >
                                {t('nav.orders')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToOrderAdd()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                            >
                                {t('nav.orderEntry')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToPrices()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                            >
                                {t('nav.prices')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={navToDocuments()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                            >
                                {t('nav.documents')}
                            </NavLink>
                        </li>
                        {cartItems?.length ?
                            <li>
                                <a
                                    href="#"
                                    className="flex !overflow-visible max-w-full"
                                    onClick={() => setOpenBasket(!openBasket)}
                                >
                                    <span className="inline-block flex-1 overflow-hidden text-ellipsis">{t('nav.currentBasket')}</span>
                                    <div className="flex items-center pl-4 pb-1 relative rounded-icon">
                                        <img src={process.env.PUBLIC_URL + "/images/basket.png"} width="12" height="11" />
                                    </div>
                                </a>

                            </li> :
                            null
                        }
                    </ul>
                    <div className="ad-space">
                        <img src={process.env.PUBLIC_URL + "/images/hella-small.png"} />
                    </div>
                    <ul className="action-nav-items">
                        <li className="relative !p-0" onClick={() => setOpenDropdown(!openDropdown)}>
                            <div className={`!flex-col !items-start pb-2${openDropdown ? " open-dropdown" : ""}`}>
                                <div className="flex items-center justify-start">
                                    <img src={i18n?.language === "de" ?
                                        process.env.PUBLIC_URL + "/images/germanFlag.png" :
                                        process.env.PUBLIC_URL + "/images/englishFlag.png"
                                    }
                                        width="18"
                                        height="18"
                                    />
                                    <a
                                        href="#"
                                        className="lang lang-selected"
                                        onClick={() => i18n?.language === "de" ? i18n.changeLanguage('de') : i18n.changeLanguage('en')}
                                    >
                                        {i18n?.language === "de" ? "Deutsch" : "English"}
                                    </a>
                                </div>
                                <div className={`${openDropdown ? "" : "hidden "}flex absolute top-[32px]`}>
                                    <img src={i18n?.language === "de" ?
                                        process.env.PUBLIC_URL + "/images/englishFlag.png" :
                                        process.env.PUBLIC_URL + "/images/germanFlag.png"
                                    }
                                        width="18"
                                        height="18"
                                    />
                                    <a
                                        href="#"
                                        className="lang"
                                        onClick={() => i18n?.language === "de" ? i18n.changeLanguage('en') : i18n.changeLanguage('de')}
                                    >
                                        {i18n?.language === "de" ? "English" : "Deutsch"}
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li className="relative">
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                />
                            </svg> */}
                            <img src={process.env.PUBLIC_URL + "/images/contact.png"} width="18" height="18" />
                            <a
                                href="#"
                                // className={({ isActive }) =>
                                //     isActive ? "active" : ""
                                // }
                                onClick={() => setOpenModal(!openModal)}
                            >
                                {t('nav.contact')}
                            </a>
                        </li>
                        <li>
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                            </svg> */}
                            <img src={process.env.PUBLIC_URL + "/images/userAccount.png"} width="18" height="18" />
                            <NavLink
                                to={navToUserAccount()}
                                className={({ isActive }) =>
                                    isActive ? "active" : ""
                                }
                            >
                                {t('nav.userAccount')}
                            </NavLink>
                        </li>
                        <li>
                            {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                            </svg> */}
                            <img src={process.env.PUBLIC_URL + "/images/logout.png"} width="18" height="18" />
                            <a href="#" onClick={() => logout()}>{t('nav.logout')}</a>
                        </li>
                    </ul>
                    <ul className="misc-nav-items">
                        <li>
                            <a href="http://www.canis-lupus.digital/impressum.html" target="_blank" rel="noreferrer">
                                {t('nav.imprint')}
                            </a>
                        </li>
                        <li>
                            <a href="http://www.canis-lupus.digital/datenschutz.html" target="_blank" rel="noreferrer">
                                {t('nav.privacy')}
                            </a>
                        </li>
                    </ul>
                </nav>
            </aside>
            {openModal ? (
                <Modal
                    modalTitle={`${t('viewContactPage.contact')}`}
                    onCancelClick={() => {
                        setOpenModal(false);
                    }}
                    modalType={modalTypes?.type2?.name}
                    hasMoreBtn={false}
                />
            ) : null}
            {openBasket ? (
                <Modal
                    modalTitle={t('viewPricesPage.basket.currentBasket')}
                    onCancelClick={() => {
                        setOpenBasket(false);
                    }}
                    hasMoreBtn={false}
                    modalWidth="w-[60%]"
                    modalHeight="h-[55%]"
                    modalType={modalTypes?.type4?.name}
                    cartData={cartItems}
                    changeItemQty={handleQty}
                    showRemoveItemModal={showRemoveItemModal}
                    handleItemToRemove={handleItemToRemove}
                />
            ) : null}
            {openRemoveItemModal ? (
                <Modal
                    modalTitle={`${t('modal.removePrompt')}`}
                    onCancelClick={() => {
                        setOpenRemoveItemModal(false);
                    }}
                    modalWidth="w-[40%]"
                    modalHeight="h-[25%]"
                    modalType={modalTypes?.type5?.name}
                    handleItemToRemove={handleItemToRemove}
                    handleYesToRemove={handleYesToRemove}
                    hasMoreBtn={false}
                    noOutsideClickClose={true}
                />
            ) : null}
        </div>
    );
}

export default Sidebar;