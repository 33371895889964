import PropTypes from "prop-types";

const DragAndDrop = ({
	widthClass = "w-1/2",
	heightClass = "h-[240px]",
	text = "Drop file here to upload or browse your computer",
	onClick=() => {},
	file
}) => {
	
	return (
		<div className={`border border-gray-400 ${widthClass} ${heightClass} flex flex-col justify-center items-center`}>
			<button onClick={onClick}>
			{file?.name ? <p>{file?.name}</p>:<svg
				width="25"
				height="25"
				xmlns="http://www.w3.org/2000/svg"
				fillRule="evenodd"
				>

				<path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z" />
			</svg>}
			</button>
			{!file?.name &&<p className="w-2/4 pt-3 text-center">{text}</p>}
		</div>
	);
};

DragAndDrop.propTypes = {
	widthClass: PropTypes.string,
	heightClass: PropTypes.string,
	text: PropTypes.string,
};

export default DragAndDrop;