import { useTranslation } from "react-i18next";

export const ProgressBar = ({ progress }) => {
  const { t } = useTranslation();
  return (
    <div className="relative pt-1">
      <div className="flex h-2 overflow-hidden bg-gray-200 rounded">
        <div
          style={{ width: `${progress}%` }}
          className="flex flex-col justify-center bg-green-500 shadow-none whitespace-nowrap"
        ></div>
      </div>
      <div className="text-xs text-gray-600 mt-1 flex justify-end">
        {progress}% {t("DownloadButtonPopUpModal.completed")}
      </div>
    </div>
  );
};
