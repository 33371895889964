import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { removeOrderCartItems } from "../../../../features/orderCart/orderCartSlice";
import { useGetAccountDetailsQuery } from "../../../../services/accountDetails";
import { useAddOrderMutation } from "../../../../services/orders";
import { removeOrderEntry } from "../../../../features/orderEntry/orderEntrySlice";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import { successToast, failureToast } from "../../../../components/Toast";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { modalTypes } from "../../../../components/Modal/ModalTypes";
import { navToPrices, navToOrderView } from "../../../../navigations";
import { capitalizeFirstLetter } from "../../../../utils/capitalize";
import DisableButton from "../../../../components/Modal/button";

const initialPayload = {
  customerOrderReference: "",
  customerDeliveryNoteNumber: null,
  shipperAddress: {
    name1: "",
    name2: "",
    streetName: "",
    addressSupplement: "2",
    houseNumber: "",
    city: "",
    zipCode: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
  },
  deliveryAddress: {
    // name1: "",
    // name: "",
    companyName: "",
    firstName: "",
    lastName: "",
    // salutation: "",
    streetName: "",
    addressSupplement: "",
    houseNumber: "",
    city: "",
    zipCode: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
  },
  invoiceAddress: null,
  invoiceNumber: null,
  shipmentType: "STANDARD",
  items: [],
  // items: [
  //     {
  //         position: 1,
  //         articleId: "4082300671384",
  //         requested: 12,
  //         articleUnit: "UNIT"
  //     },
  //     {
  //         position: 2,
  //         articleId: "4082300671382",
  //         requested: 6,
  //         articleUnit: "UNIT"
  //     }
  // ],
};

function AddOrder({
  horBannerAd = null,
  verBannerAd = null,
  hasSideTable = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientId = useSelector((state) => state.clientUser.clientId);
  const cartItems = useSelector((state) => state.orderCart.cartItems);

  const [step, setStep] = useState(1);

  const [openModal, setOpenModal] = useState(false);

  const [payload, setPayload] = useState(initialPayload);

  const {
    data: accountDetailsData,
    isError: isAccountDetailsError,
    refetch: refetchAccDetailsQuery,
  } = useGetAccountDetailsQuery(clientId, {
    skip: !clientId,
  });

  const [
    addOrder,
    {
      isLoading: addingOrder,
      error: orderAddError,
      isSuccess: orderAddSuccess,
    },
  ] = useAddOrderMutation();

  const handlePayload = (updatedPayload) => {
    setPayload(updatedPayload);
  };

  const handleChangeStep = (nextStep) => {
    setStep(nextStep);
  };

  const getItemsReady = () => {
    const newPayloadWithItems = cartItems?.map((item, index) => {
      return {
        position: index,
        articleId: item?.ean,
        requested: parseInt(item?.quantity),
        articleUnit: "UNIT",
      };
    });
    return newPayloadWithItems;
  };

  const getPayloadReady = (payload) => {
    const { deliveryAddress, ...restPayload } = payload;

    // deliveryAddress.name = (deliveryAddress?.salutation ? deliveryAddress?.salutation + " " : "") + deliveryAddress?.firstName + " " + deliveryAddress?.name;
    // deliveryAddress.name = deliveryAddress?.firstName + " " + deliveryAddress?.name;

    // const { firstName, salutation, ...rest } = deliveryAddress;
    // const { firstName, ...rest } = deliveryAddress;

    // restPayload.deliveryAddress = rest;
    restPayload.deliveryAddress = deliveryAddress;

    // Also set the cartItems state
    restPayload.items = getItemsReady();
    return restPayload;
  };

  const handleOrderAdd = (values) => {
    const readyPayload = getPayloadReady(values);
    addOrder(readyPayload);
  };

  useEffect(() => {
    if (isAccountDetailsError) {
      failureToast(
        `${t("addOrdersPage.toastNotifications.failureLoadingAccountDetails")}`
      );
    }
    if (accountDetailsData?.payload !== null) {
      let newShipAddr;
      // check the condition for warehouse
      if (accountDetailsData?.payload?.warehouseAddress !== null) {
        newShipAddr = {
          name1: accountDetailsData?.payload?.warehouseAddress?.name1,
          name2: accountDetailsData?.payload?.warehouseAddress?.name2,
          streetName: accountDetailsData?.payload?.warehouseAddress?.streetName,
          addressSupplement:
            accountDetailsData?.payload?.warehouseAddress?.addressSupplement,
          houseNumber: accountDetailsData?.payload?.warehouseAddress?.houseNumber,
          city: accountDetailsData?.payload?.warehouseAddress?.city,
          zipCode: accountDetailsData?.payload?.warehouseAddress?.zipCode,
          countryCode: accountDetailsData?.payload?.warehouseAddress?.countryCode,
          phoneNumber: accountDetailsData?.payload?.warehouseAddress?.phoneNumber,
          email: accountDetailsData?.payload?.warehouseAddress?.email,
        }
      }

      const tempDelAddr = payload?.deliveryAddress;
      const newDelAddr = {
        ...tempDelAddr,
        phoneNumber: accountDetailsData?.payload?.accountAddress?.phoneNumber,
        email: accountDetailsData?.payload?.accountAddress?.email,
      };
      setPayload({
        ...payload,
        shipperAddress: newShipAddr,
        deliveryAddress: newDelAddr,
      });
    }
    else{
      setPayload({
        ...payload,
        shipperAddress: null,
      });
    }
  }, [accountDetailsData, isAccountDetailsError]);

  useEffect(() => {
    if (orderAddError) {
      if (orderAddError?.data?.payload?.errors[0]?.message) {
        failureToast(
          capitalizeFirstLetter(
            orderAddError?.data?.payload?.errors[0]?.message
          )
        );
      } else {
        failureToast(
          `${t("addOrdersPage.toastNotifications.failureAddingOrder")}`
        );
      }
    }
    if (orderAddSuccess) {
      successToast(
        `${t("addOrdersPage.toastNotifications.successAddingOrder")}`
      );
      setPayload(initialPayload);
      dispatch(removeOrderCartItems());
      // Clear Global Order Entry State
      dispatch(removeOrderEntry());
      refetchAccDetailsQuery();
      setStep(1);
      navigate(`${navToOrderView()}`);
    }
  }, [orderAddError, orderAddSuccess]);

  return (
    <section>
      {openModal ? (
        <Modal
          // onClick={alert('hello')}
          modalTitle="CSV Upload"
          onCancelClick={() => {
            setOpenModal(false);
          }}
          hasMoreBtn={false}
          headingCss="text-[18px]"
          centeredHeading={true}
          modalType={modalTypes?.type3?.name}
        />
      ) : null}
      <div className="page-view">
        <div className="row-xs">
          <div className="rest-of-six-col-xs">
            <div className="page-ad-space-horizontal">
              <div className="page-ad">
                {horBannerAd ? <img src={horBannerAd} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="page-content-no-bg">
          <div className="row-xs">
            <div className="rest-of-six-col-xs">
              <div className="page-header">
                <h1>{t("addOrdersPage.orderEntry")}</h1>
                <Button variant="secondary" onClick={() => setOpenModal(true)}>
                  {t("addOrdersPage.csvUpload")}
                </Button>
              </div>
              <div className="row-xs page-content-bg">
                {step === 1 ? (
                  <Step1 />
                ) : step === 2 ? (
                  <Step2
                    changeStep={handleChangeStep}
                    payload={payload}
                    handlePayload={handlePayload}
                  // addressType={addressType}
                  // handleAddressType={handleAddressType}
                  />
                ) : step === 3 ? (
                  <Step3 payload={payload} changeStep={handleChangeStep} />
                ) : null}

                <div className="six-col-xs pl-4 pr-0">
                  <div className="h-full flex flex-col justify-center">
                    <ul className="steps-list">
                      <li
                        className={
                          step === 1 ? "active" : ""
                        } /* onClick={() => setStep(1)} */
                      >
                        <span className="step">
                          <span className="number">1</span>
                          <span className="text">
                            {t("addOrdersPage.orderItem")}
                          </span>
                        </span>
                      </li>
                      <li
                        className={
                          step === 2 ? "active" : ""
                        } /* onClick={() => setStep(2)} */
                      >
                        <span className="step">
                          <span className="number">2</span>
                          <span className="text">
                            {t("addOrdersPage.shippingAddress")}
                          </span>
                        </span>
                      </li>
                      <li
                        className={
                          step === 3 ? "active" : ""
                        } /* onClick={() => setStep(3)} */
                      >
                        <span className="step">
                          <span className="number">3</span>
                          <span className="text">
                            {t("addOrdersPage.overview")}
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="btn-container w-full relative pr-5">
                  {step === 1 ? (
                    <>
                      {cartItems?.length ? (
                        <Button
                          type="button"
                          variant="white"
                          onClick={() => {
                            navigate(`${navToPrices()}`);
                          }}
                          disabled={DisableButton(cartItems)}

                        >
                          {t("addOrdersPage.addMoreItems")}
                        </Button>
                      ) : null}
                      <Button
                        type="button"
                        variant="white"
                        onClick={() => navigate(-1)}
                        disabled={DisableButton(cartItems)}
                      >
                        {t("addOrdersPage.return")}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => setStep(2)}
                        disabled={DisableButton(cartItems) || !cartItems?.length && true}
                      >
                        {t("addOrdersPage.continue")}
                      </Button>
                    </>
                  ) : step === 2 ? null : step === 3 ? (
                    <>
                      <Button
                        type="button"
                        variant="white"
                        onClick={() => setStep(2)}
                        disabled={DisableButton(cartItems)}
                      >
                        {t("addOrdersPage.return")}
                      </Button>

                      {/* <Button
                                                        type="button"
                                                        variant="white"
                                                        onClick={() => navigate(`${navToPrices()}`)}
                                                    >
                                                        {t('addOrdersPage.enterAnotherOrder')}
                                                    </Button> */}
                      <Button
                        type="button"
                        loading={addingOrder}
                        onClick={() => {
                          handleOrderAdd(payload);
                        }}
                        disabled={DisableButton(cartItems)}
                      >
                        {t("addOrdersPage.send")}
                      </Button>
                      <Button
                        type="button"
                        variant="link"
                        className="!absolute right-0"
                      >
                        {t("addOrdersPage.abort")}
                      </Button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="six-col-xs">
              <div className="page-ad-space-vertical pt-8">
                <div
                  className={`${hasSideTable ? "page-ad page-ad-mt" : "page-ad"
                    }`}
                >
                  {verBannerAd ? <img src={verBannerAd} /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddOrder;
