import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrdersQuery, useGetOrderDetailQuery } from "../../../../services/orders";
import Table from "../../../../components/Table";
import Modal from "../../../../components/Modal";
import { modalTypes } from "../../../../components/Modal/ModalTypes";
import { SelectColumnFilter, DateColumnFilter } from "../../../../components/Table/Filter";
import { getOrdersQueryForTable } from "../../../../utils/getQueryStringForTable";
import { addZeroInSingleDigit } from "../../../../utils/addZeroInSingleDigit";
let noIs=1;
function ViewOrder({ horBannerAd = null, verBannerAd = null, hasSideTable = false }) {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const pageSize = 15;
    const [totalData, setTotalData] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [tableQuery, setTableQuery] = useState('');
    const [orderDetailId, setOrderDetailId] = useState('');
    const [selectedOrderNum, setSelectedOrderNum] = useState('');

    const { data: ordersData, isError: isOrdersError, isFetching: isFetchingOrders } = useGetOrdersQuery(tableQuery, {
        skip: !tableQuery?.length,
    });

    const { data: orderDetailData, isError: isOrderDetailError, isFetching: isFetchingOrderDetail } = useGetOrderDetailQuery(orderDetailId, {
        skip: !orderDetailId?.length,
    });
    
    if(ordersData?.payload?.totalHits%15>0){
         noIs=Math.floor((ordersData?.payload?.totalHits/15)+1)
    }
    if(ordersData?.payload?.totalHits%15===0){
         noIs=Math.floor((ordersData?.payload?.totalHits/15))
    }
// console.log('this is data for table',ordersData)
    const statusInSmallCase = (status) => {
        const newStatus = status === "OUTSTANDING" ?
            `${t("viewOrdersPage.outstanding")}` :
            status === "ACCEPTED" ?
                `${t("viewOrdersPage.accepted")}` :
                status === "ACCEPTED_PARTIAL" ?
                    `${t("viewOrdersPage.acceptedPartial")}` :
                    status === "DECLINED" ?
                        `${t("viewOrdersPage.declined")}` :
                        status === "IN_TRANSPORT" ?
                            `${t("viewOrdersPage.inTransport")}` :
                            status === "IN_TRANSPORT_PARTIAL" ?
                                `${t("viewOrdersPage.inTransportPartial")}` :
                                status === "DELIVERED" ?
                                    `${t("viewOrdersPage.delivered")}` :
                                    status === "CANCELLED" ?
                                        `${t("viewOrdersPage.cancelled")}` :
                                        status === "DELIVERED_PARTIAL" ?
                                            `${t("viewOrdersPage.deliveredPartial")}` :
                                            status === "RETURNED" ?
                                                `${t("viewOrdersPage.returned")}` :
                                                "";
        return newStatus;
    };

    const columns = useMemo(
        () => {
            return [
                {
                    id: "orderedDate",
                    Header: `${t('viewOrdersPage.orderDate')}`,
                    accessor: "orderDate",
                    Cell: ({ cell: { value } }) => value || "-",
                    canBeSorted: false,
                    canBeFiltered: true,
                    Filter: DateColumnFilter,
                },
                // The id is used for generating sortString for API.
                {
                    id: "CUSTOMER_ORDER_REFERENCE",
                    Header: `${t('viewOrdersPage.externalOrderNumber')}`,
                    accessor: "externalOrderNumber",
                    Cell: ({ cell: { value } }) => value || "-",
                    canBeSorted: true,
                    canBeFiltered: false,
                },
                {
                    id: "ID",
                    Header: `${t('viewOrdersPage.orderNumber')}`,
                    accessor: "orderNumber",
                    Cell: ({ cell: { value } }) => value || "-",
                    canBeSorted: true,
                    canBeFiltered: false,
                },
                {
                    id: "INVOICE_NUMBER",
                    Header: `${t('viewOrdersPage.billNumber')}`,
                    accessor: "billNumber",
                    Cell: ({ cell: { value } }) => value || "-",
                    canBeSorted: true,
                    canBeFiltered: false,
                },
                {
                    id: "status",
                    Header: `${t('viewOrdersPage.status')}`,
                    accessor: "status",
                    Cell: ({ cell: { value }, row }) => {
                        if (row?.canExpand) {
                            if (!row?.isExpanded)
                                return `${t("viewOrdersPage.splitOrder")}`;
                            else
                                // return value || "-";
                                return statusInSmallCase(value) || "-";
                        } else {
                            // return value || "-";
                            return statusInSmallCase(value) || "-";
                        }
                    },
                    canBeSorted: false,
                    canBeFiltered: true,
                    Filter: SelectColumnFilter,
                    possibleFilters: [
                        {
                            label: 'Status',
                            value: '',
                        },
                        {
                            label: `${t('viewOrdersPage.outstanding')}`,
                            value: 'OUTSTANDING',
                        },
                        {
                            label: `${t('viewOrdersPage.accepted')}`,
                            value: 'ACCEPTED',
                        },
                        {
                            label: `${t('viewOrdersPage.acceptedPartial')}`,
                            value: 'ACCEPTED_PARTIAL',
                        },
                        {
                            label: `${t('viewOrdersPage.declined')}`,
                            value: 'DECLINED',
                        },
                        {
                            label: `${t('viewOrdersPage.inTransport')}`,
                            value: 'IN_TRANSPORT',
                        },
                        {
                            label: `${t('viewOrdersPage.inTransportPartial')}`,
                            value: 'IN_TRANSPORT_PARTIAL',
                        },
                        {
                            label: `${t('viewOrdersPage.deliveredPartial')}`,
                            value: 'DELIVERED_PARTIAL',
                        },
                        {
                            label: `${t('viewOrdersPage.delivered')}`,
                            value: 'DELIVERED',
                        },
                        {
                            label: `${t('viewOrdersPage.cancelled')}`,
                            value: 'CANCELLED',
                        },
                    ],
                },
                {
                    id: "search",
                    Header: `${t('viewOrdersPage.search')}`,
                    accessor: "search",
                    canExpand: true,
                    Cell: ({ row }) => (
                        <div className="relative flex justify-center">
                            <div className="w-fit relative">
                                {!(row?.canExpand) ?
                                    <a
                                        href="#"
                                        className={`underline lowercase`}
                                        onClick={() => {
                                            setOrderDetailId(row?.original?.id);
                                            
                                            setSelectedOrderNum(row?.original?.orderNumber);
                                            setOpenModal(!openModal);
                                        }}
                                    >
                                        {t("viewOrdersPage.more")}
                                    </a> :
                                    null
                                }
                                {row.canExpand ?
                                    row.isExpanded ?
                                        <svg
                                            {...row.getToggleRowExpandedProps({ title: undefined })}
                                            className="absolute top-[-6px] right-[-6px]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="9"
                                            viewBox="0 0 17.965 14.971"
                                        >
                                            <path id="Polygon_18" data-name="Polygon 18" d="M8.982,0l8.982,14.971H0Z" transform="translate(17.965 14.971) rotate(180)" fill="#312828" />
                                        </svg> :
                                        <svg
                                            {...row.getToggleRowExpandedProps({ title: undefined })}
                                            className="absolute top-[-6px] right-[-6px]"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="9"
                                            height="10"
                                            viewBox="0 0 14.971 17.965"
                                        >
                                            <path id="Polygon_21" data-name="Polygon 21" d="M8.982,0l8.982,14.971H0Z" transform="translate(0 17.965) rotate(-90)" fill="#312828" />
                                        </svg> :
                                    null
                                }
                            </div>

                        </div >
                    ),
                    canBeSorted: false,
                    canBeFiltered: true,
                },
            ]
        },
        [t]
    );

    const getData = ({ filters, sortBy, pageIndex }) => {
        const query = getOrdersQueryForTable(filters, sortBy, pageIndex);
        setTableQuery(query);
    };

    useEffect(() => {
        if (ordersData) {
            setTableData(ordersData?.payload?.orders?.map(data => {
                const d = new Date(data?.creationDate * 1000);
                // const orderDateString = `${addZeroInSingleDigit(d?.toLocaleDateString('de-DE')?.split("/")[1])
                //     }.${addZeroInSingleDigit(d?.toLocaleDateString('de-DE')?.split("/")[0])
                //     }.${d?.toLocaleDateString('de-DE')?.split("/")[2]}`;

                const orderDateString = `${addZeroInSingleDigit(d?.getDate())
                    }.${addZeroInSingleDigit(d?.getMonth() + 1)
                    }.${d?.getFullYear()}`;

                if (data?.fulfillments?.length > 1) {
                    return {
                        id: data?.id,
                        orderDate: orderDateString,
                        externalOrderNumber: data?.customerOrderReference,
                        orderNumber: data?.fulfillments[0]?.internalOrderNumber,
                        billNumber: data?.invoiceNumber,
                        status: data?.status,
                        // Adding subrows will automatically make that row expandable
                        subRows: data?.fulfillments?.map((item, index, arr) => {
                            // If it's the last item, make isLastRow true.
                            if ((arr.length - 1) === index) {
                                return {
                                    id: data?.id,
                                    orderDate: orderDateString,
                                    externalOrderNumber: data?.customerOrderReference,
                                    orderNumber: item?.id,
                                    billNumber: data?.invoiceNumber,
                                    status: item?.status,
                                    isLastRow: true,
                                };
                            } else {
                                return {
                                    id: data?.id,
                                    orderDate: orderDateString,
                                    externalOrderNumber: data?.customerOrderReference,
                                    orderNumber: item?.id,
                                    billNumber: data?.invoiceNumber,
                                    status: item?.status,
                                    isLastRow: false,
                                };
                            }
                        }),
                    }
                } else {
                    return {
                        id: data?.id,
                        orderDate: orderDateString,
                        externalOrderNumber: data?.customerOrderReference,
                        orderNumber: data?.fulfillments[0]?.internalOrderNumber,
                        billNumber: data?.invoiceNumber,
                        status: data?.status,
                    }
                }

            }));
            setTotalData(ordersData?.payload?.totalHits);
            setPageCount(Math.ceil(ordersData?.payload?.totalHits / pageSize));
            setPageCount(noIs);
        }
    }, [ordersData]);

    return (
        <section>
            {openModal ? (
                <Modal
                    modalTitle={`${t('viewOrderDetailPage.details')} ${orderDetailData?.payload?.customerOrderReference || ""}`}
                    onCancelClick={() => {
                        // setPageSize(defaultPageSize);
                        // Also change the value of select element in row size to defaultPageSize
                        // rowSizeSelectEl.current.value = defaultPageSize;
                        setOpenModal(false);
                    }}
                    modalType={modalTypes?.type1?.name}
                    orderDetails={orderDetailData?.payload}
                    isOrderDetailError={isOrderDetailError}
                    isFetchingOrderDetail={isFetchingOrderDetail}
                    selectedOrderNum={selectedOrderNum}
                />
            ) : null}
            <div className="page-view">
                <div className="row-xs">
                    <div className="rest-of-six-col-xs">
                        <div className="page-ad-space-horizontal">
                            <div className="page-ad">
                                {horBannerAd ?
                                    <img src={horBannerAd} /> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-header flex flex-col">
                    <h1>{t("viewOrdersPage.orders")}</h1>
                    <h4 className="h4-m">{t('viewOrdersPage.last30Days')}</h4>
                </div>
                <div className="page-content-no-bg">
                    <div className="row-xs">
                        <div className="rest-of-six-col-xs">
                            <Table
                                columns={columns}
                                data={tableData}
                                fetchData={getData}
                                isFetchError={isOrdersError}
                                isLoadingData={isFetchingOrders}
                                pageCount={pageCount}
                                defaultPageSize={pageSize}
                                totalData={totalData}
                                hasExport={true}
                                tableName="Category/s"
                            />
                        </div>
                        <div className="six-col-xs">
                            <div className="page-ad-space-vertical">
                                <div className={`${hasSideTable ? "page-ad page-ad-mt" : "page-ad"}`}>
                                    {verBannerAd ?
                                        <img src={verBannerAd} /> :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ViewOrder;